import type { SystemStyleObject } from '@chakra-ui/react';
import { AG_GRID_CLASSNAME, agGridVariables } from '../agGrid';

const globalStyles: SystemStyleObject = {
  'html, body, #__next': {
    width: '100%',
    height: '100%',
    fontSize: '16px',
    overflow: 'hidden',
  },
  '@media print': {
    'html, body, #__next': {
      height: 'auto',
      overflow: 'visible',
    },
  },
  '*, *::before, &::after': {
    borderColor: 'borders.base',
  },
  "input[type='search']": {
    '&[placeholder], &::placeholder': {
      textOverflow: 'ellipsis',
    },
    '&::-webkit-search-decoration': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-search-results-button': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-search-results-decoration': {
      WebkitAppearance: 'none',
    },
    '&::-webkit-search-cancel-button': {
      WebkitAppearance: 'none',
      cursor: 'pointer',
      height: '0.875em',
      width: '0.875em',
      backgroundImage: "url('/assets/clear-input-icon.svg')",
    },
  },

  // Some of these styles are used for virtualized rendering,
  // and won't work if defined inline with the rest of the custom style overrides
  [`.${AG_GRID_CLASSNAME}`]: agGridVariables,

  // AG Grid Drag handle
  // This needs to be in global styles because it's rendered in a portal, outside of the ag-grid root
  '.ag-dnd-ghost': {
    '--ag-icon-size': '18px',
    shadow: 'md',
    fontWeight: 600,
    color: 'gray.600',
    px: 2,
  },

  // Hide intercom on mobile
  '.intercom-lightweight-app': {
    display: { base: 'none', md: 'block' },
  },
};

export default globalStyles;
