import { defineStyleConfig } from '@chakra-ui/react';

export const formLabelTheme = defineStyleConfig({
  baseStyle: {
    marginBottom: 1,
    _disabled: {
      opacity: 0.7,
    },
  },
});
