import { useEffect } from 'react';
import type { RefObject } from 'react';
import { useCallbackRef } from '@chakra-ui/react';

const getOwnerDocument = (node?: Element | null): Document => {
  return node?.ownerDocument ?? document;
};

// They use any here for the original hook
const isValidEvent = (
  event: Event,
  ref: React.RefObject<HTMLElement>,
): boolean => {
  const target = event.target as HTMLElement;

  if (target) {
    const doc = getOwnerDocument(target);
    if (!doc.contains(target)) {
      return false;
    }
  }

  return !ref.current?.contains(target);
};

/**
 * Inpired by `useOutsideClick` from Chakra UI:
 *
 * @see {@link https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/use-outside-click/src/index.ts}
 */
const useOutsideFocus = ({
  ref,
  handler,
}: {
  ref: RefObject<HTMLElement>;
  handler: (e: FocusEvent) => void;
}) => {
  const savedHandler = useCallbackRef(handler);

  useEffect(() => {
    const onDocumentFocus = (event: FocusEvent) => {
      if (!!handler && isValidEvent(event, ref)) {
        savedHandler(event);
      }
    };

    const doc = getOwnerDocument(ref.current);
    doc.addEventListener('focusin', onDocumentFocus, true);

    return () => {
      doc.removeEventListener('focusin', onDocumentFocus, true);
    };
  }, [handler, ref, savedHandler]);
};

export default useOutsideFocus;
