import {
  type ToastProviderProps,
  type UseToastOptions,
} from '@chakra-ui/react';
import { ToastAlert } from './ToastAlert';

const defaultOptions: UseToastOptions = {
  containerStyle: { mb: 4 },
  duration: 5000,
  isClosable: true,
  position: 'bottom',
  render: ToastAlert,
};

const toastOptions: ToastProviderProps = {
  defaultOptions,
};

export default toastOptions;
