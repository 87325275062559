// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/season_package.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Int32Value, proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, ListShowsFilter, Money, SalesChannel, SeasonPackageStatus, TicketDeliveryMethod } from "../../../common/v1/common_pb.js";
import { Showtime } from "./showtime_pb.js";
import { TicketType } from "./ticket_type_pb.js";
import { PriceList } from "./price_list_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackage
 */
export const SeasonPackage = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackage",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "image_asset", kind: "message", T: Asset },
    { no: 8, name: "min_ticket_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "max_ticket_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "is_max_one_ticket_per_show", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "should_force_same_seats_per_venue", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "showtimes", kind: "message", T: Showtime, repeated: true },
    { no: 13, name: "ticket_types", kind: "message", T: TicketType, repeated: true },
    { no: 14, name: "sales_channels", kind: "enum", T: proto3.getEnumType(SalesChannel), repeated: true },
    { no: 15, name: "sales_starts_at", kind: "message", T: Timestamp },
    { no: 16, name: "sales_ends_at", kind: "message", T: Timestamp },
    { no: 17, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
    { no: 18, name: "is_order_confirmation_email_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "order_confirmation_email_content_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "order_confirmation_email_content_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "sort_order", kind: "message", T: Int32Value },
    { no: 22, name: "stat", kind: "message", T: SeasonPackageStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageStat
 */
export const SeasonPackageStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageStat",
  () => [
    { no: 1, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(SeasonPackageStatus) },
    { no: 4, name: "is_visible_online", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "net_packages_sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "net_package_sales", kind: "message", T: Money },
    { no: 7, name: "net_package_buyers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "summary_price_list", kind: "message", T: PriceList },
    { no: 9, name: "min_package_price", kind: "message", T: Money },
    { no: 10, name: "max_package_price", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeasonPackagesRequest
 */
export const ListSeasonPackagesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeasonPackagesRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListShowsFilter) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeasonPackagesResponse
 */
export const ListSeasonPackagesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeasonPackagesResponse",
  () => [
    { no: 1, name: "season_packages", kind: "message", T: SeasonPackage, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateSeasonPackageRequest
 */
export const CreateSeasonPackageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateSeasonPackageRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateSeasonPackageResponse
 */
export const CreateSeasonPackageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateSeasonPackageResponse",
  () => [
    { no: 1, name: "season_package", kind: "message", T: SeasonPackage },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageRequest
 */
export const GetSeasonPackageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeasonPackageResponse
 */
export const GetSeasonPackageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeasonPackageResponse",
  () => [
    { no: 1, name: "season_package", kind: "message", T: SeasonPackage },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSeasonPackageRequest
 */
export const SaveSeasonPackageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSeasonPackageRequest",
  () => [
    { no: 1, name: "season_package", kind: "message", T: SeasonPackage },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSeasonPackageResponse
 */
export const SaveSeasonPackageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSeasonPackageResponse",
  () => [
    { no: 1, name: "season_package", kind: "message", T: SeasonPackage },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveSeasonPackageRequest
 */
export const ArchiveSeasonPackageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveSeasonPackageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveSeasonPackageResponse
 */
export const ArchiveSeasonPackageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveSeasonPackageResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageIDAndName
 */
export const SeasonPackageIDAndName = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageIDAndName",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeasonPackageIDsAndNamesRequest
 */
export const ListSeasonPackageIDsAndNamesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeasonPackageIDsAndNamesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSeasonPackageIDsAndNamesResponse
 */
export const ListSeasonPackageIDsAndNamesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSeasonPackageIDsAndNamesResponse",
  () => [
    { no: 1, name: "season_package_ids_and_names", kind: "message", T: SeasonPackageIDAndName, repeated: true },
  ],
);

