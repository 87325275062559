// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/form_question.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { FormQuestionType } from "../../../common/v1/common_pb.js";

/**
 * Question messages
 *
 * @generated from message cuebox.services.consumer.v1.FormQuestionOption
 */
export const FormQuestionOption = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.FormQuestionOption",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.FormQuestion
 */
export const FormQuestion = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.FormQuestion",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(FormQuestionType) },
    { no: 3, name: "label_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "label_plain_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "options", kind: "message", T: FormQuestionOption, repeated: true },
    { no: 6, name: "is_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "sort_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.FormQuestionGroup
 */
export const FormQuestionGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.FormQuestionGroup",
  () => [
    { no: 1, name: "item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "questions", kind: "message", T: FormQuestion, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CheckoutForm
 */
export const CheckoutForm = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CheckoutForm",
  () => [
    { no: 1, name: "order_question_group", kind: "message", T: FormQuestionGroup },
    { no: 2, name: "item_question_groups", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionGroup} },
  ],
);

/**
 * Response messages
 *
 * @generated from message cuebox.services.consumer.v1.FormQuestionAnswer
 */
export const FormQuestionAnswer = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.FormQuestionAnswer",
  () => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_type", kind: "enum", T: proto3.getEnumType(FormQuestionType) },
    { no: 3, name: "text_response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "selected_option_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "selected_option_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "is_checked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.FormQuestionAnswerGroup
 */
export const FormQuestionAnswerGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.FormQuestionAnswerGroup",
  () => [
    { no: 1, name: "item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "answers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionAnswer} },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CheckoutFormSubmission
 */
export const CheckoutFormSubmission = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CheckoutFormSubmission",
  () => [
    { no: 1, name: "order_answer_group", kind: "message", T: FormQuestionAnswerGroup },
    { no: 2, name: "item_answer_groups", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionAnswerGroup} },
  ],
);

