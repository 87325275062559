import { Inter } from 'next/font/google';

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

const fonts = {
  heading: `${inter.style.fontFamily}, Roboto, sans-serif`,
  body: `${inter.style.fontFamily}, Roboto, sans-serif`,
};

export default fonts;
