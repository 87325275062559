import type { DVCVariable, DVCVariableValue } from '@devcycle/react-client-sdk';
import {
  useVariable as originalUseVariable,
  useVariableValue as originalUseVariableValue,
} from '@devcycle/react-client-sdk';

export type CustomData = {
  orgId?: string;
};

export type UseVariableValue = <K extends string & keyof DVCVariableTypes>(
  key: K,
  defaultValue: DVCVariableTypes[K],
) => DVCVariableTypes[K];

export const useVariableValue = originalUseVariableValue as UseVariableValue;

export type UseVariable = <
  K extends string & keyof DVCVariableTypes,
  T extends DVCVariableValue & DVCVariableTypes[K],
>(
  key: K,
  defaultValue: DVCVariableTypes[K],
) => DVCVariable<T>;

export const useVariable = originalUseVariable as UseVariable;

export type DVCVariableTypes = {
  /**
   * key: is-form-question-response-report-enabled
   * created by: Chris Sandvik
   * created on: 2024-11-26
   */
  'is-form-question-response-report-enabled': boolean;
  /**
   * key: edit-item-price-box-office
   * created by: Hanna Lee
   * created on: 2024-11-21
   */
  'edit-item-price-box-office': boolean;
  /**
   * key: is-consumer-checkout-form-enabled
   * created by: Chris Sandvik
   * created on: 2024-11-20
   */
  'is-consumer-checkout-form-enabled': boolean;
  /**
   * key: form-question-config
   * created by: Chris Sandvik
   * created on: 2024-11-14
   */
  'form-question-config': boolean;
  /**
   * key: is-bulk-showtime-creation-enabled
   * created by: Chris Sandvik
   * created on: 2024-11-05
   */
  'is-bulk-showtime-creation-enabled': boolean;
  /**
   * key: per-customer-per-product-discount-limit
   * created by: Scott Huang
   * created on: 2024-10-24
   */
  'per-customer-per-product-discount-limit': boolean;
  /**
   * key: is-showtime-timezone-enabled
   * created by: Chris Sandvik
   * created on: 2024-10-23
   */
  'is-showtime-timezone-enabled': boolean;
  /**
   * key: is-bcc-on-order-confirmation-enabled
   * created by: Chris Sandvik
   * created on: 2024-10-22
   */
  'is-bcc-on-order-confirmation-enabled': boolean;
  /**
   * key: box-office-sales-availability
   * created by: Scott Huang
   * created on: 2024-10-16
   */
  'box-office-sales-availability': boolean;
  /**
   * key: is-optimistic-check-in-enabled
   * created by: Chris Sandvik
   * created on: 2024-10-11
   */
  'is-optimistic-check-in-enabled': boolean;
  /**
   * key: is-check-in-list-polling-disabled
   * created by: Chris Sandvik
   * created on: 2024-10-11
   */
  'is-check-in-list-polling-disabled': boolean;
  /**
   * key: is-check-in-refetch-on-success-disabled
   * created by: Chris Sandvik
   * created on: 2024-10-11
   */
  'is-check-in-refetch-on-success-disabled': boolean;
  /**
   * key: is-new-qr-scanner-enabled
   * created by: Chris Sandvik
   * created on: 2024-10-09
   */
  'is-new-qr-scanner-enabled': boolean;
  /**
   * key: is-mobile-wallet-enabled
   * created by: Chris Sandvik
   * created on: 2024-10-03
   */
  'is-mobile-wallet-enabled': boolean;
  /**
   * key: campaign-accept-donor-recognition-name
   * created by: Scott Huang
   * created on: 2024-09-25
   */
  'campaign-accept-donor-recognition-name': boolean;
  /**
   * key: add-recognition-name
   * created by: Scott Huang
   * created on: 2024-09-25
   */
  'add-recognition-name': boolean;
  /**
   * key: is-custom-reports-enabled
   * created by: Chris Sandvik
   * created on: 2024-09-24
   */
  'is-custom-reports-enabled': boolean;
  /**
   * key: are-new-saved-queries-enabled
   * created by: Chris Sandvik
   * created on: 2024-09-19
   */
  'are-new-saved-queries-enabled': boolean;
  /**
   * key: held-tickets-report
   * description: List of Held Tickets Report
   * created by: Scott Huang
   * created on: 2024-09-17
   */
  'held-tickets-report': boolean;
  /**
   * key: ticket-buyer-attendance-number
   * created by: Scott Huang
   * created on: 2024-09-17
   */
  'ticket-buyer-attendance-number': boolean;
  /**
   * key: is-ag-grid-pivot-enabled
   * created by: Chris Sandvik
   * created on: 2024-09-16
   */
  'is-ag-grid-pivot-enabled': boolean;
  /**
   * key: is-show-genre-selector-enabled
   * created by: Chris Sandvik
   * created on: 2024-09-06
   */
  'is-show-genre-selector-enabled': boolean;
  /**
   * key: are-communication-campaigns-enabled
   * created by: Chris Sandvik
   * created on: 2024-08-15
   */
  'are-communication-campaigns-enabled': boolean;
  /**
   * key: is-auto-merge-duplicates-enabled
   * created by: Chris Sandvik
   * created on: 2024-07-08
   */
  'is-auto-merge-duplicates-enabled': boolean;
  /**
   * key: is-showtime-venue-layout-enabled
   * created by: Chris Sandvik
   * created on: 2024-04-03
   */
  'is-showtime-venue-layout-enabled': boolean;
  /**
   * key: is-season-package-renewal-enabled
   * created by: Chris Sandvik
   * created on: 2024-03-19
   */
  'is-season-package-renewal-enabled': boolean;
  /**
   * key: is-consumer-showtime-date-filter-enabled
   * created by: Chris Sandvik
   * created on: 2024-01-24
   */
  'is-consumer-showtime-date-filter-enabled': boolean;
  /**
   * key: discount-membership-benefits
   * created by: Unknown User
   * created on: 2023-11-27
   */
  'discount-membership-benefits': boolean;
  /**
   * key: should-show-sybunt-report
   * created by: Chris Sandvik
   * created on: 2023-07-31
   */
  'should-show-sybunt-report': boolean;
  /**
   * key: individual-profile-lifecycle-stage
   * created by: Unknown User
   * created on: 2023-07-17
   */
  'individual-profile-lifecycle-stage': boolean;
  /**
   * key: app-homepage-enabled
   * created by: Unknown User
   * created on: 2023-07-11
   */
  'app-homepage-enabled': boolean;
  /**
   * key: attach-files-to-new-donation
   * created by: Unknown User
   * created on: 2023-07-11
   */
  'attach-files-to-new-donation': boolean;
  /**
   * key: timeline-email-items
   * created by: Unknown User
   * created on: 2023-07-07
   */
  'timeline-email-items': boolean;
};

/**
 * key: is-form-question-response-report-enabled
 * created by: Chris Sandvik
 * created on: 2024-11-26
 */

export const IS_FORM_QUESTION_RESPONSE_REPORT_ENABLED =
  'is-form-question-response-report-enabled' as const;

/**
 * key: edit-item-price-box-office
 * created by: Hanna Lee
 * created on: 2024-11-21
 */

export const EDIT_ITEM_PRICE_BOX_OFFICE = 'edit-item-price-box-office' as const;

/**
 * key: is-consumer-checkout-form-enabled
 * created by: Chris Sandvik
 * created on: 2024-11-20
 */

export const IS_CONSUMER_CHECKOUT_FORM_ENABLED =
  'is-consumer-checkout-form-enabled' as const;

/**
 * key: form-question-config
 * created by: Chris Sandvik
 * created on: 2024-11-14
 */

export const FORM_QUESTION_CONFIG = 'form-question-config' as const;

/**
 * key: is-bulk-showtime-creation-enabled
 * created by: Chris Sandvik
 * created on: 2024-11-05
 */

export const IS_BULK_SHOWTIME_CREATION_ENABLED =
  'is-bulk-showtime-creation-enabled' as const;

/**
 * key: per-customer-per-product-discount-limit
 * created by: Scott Huang
 * created on: 2024-10-24
 */

export const PER_CUSTOMER_PER_PRODUCT_DISCOUNT_LIMIT =
  'per-customer-per-product-discount-limit' as const;

/**
 * key: is-showtime-timezone-enabled
 * created by: Chris Sandvik
 * created on: 2024-10-23
 */

export const IS_SHOWTIME_TIMEZONE_ENABLED =
  'is-showtime-timezone-enabled' as const;

/**
 * key: is-bcc-on-order-confirmation-enabled
 * created by: Chris Sandvik
 * created on: 2024-10-22
 */

export const IS_BCC_ON_ORDER_CONFIRMATION_ENABLED =
  'is-bcc-on-order-confirmation-enabled' as const;

/**
 * key: box-office-sales-availability
 * created by: Scott Huang
 * created on: 2024-10-16
 */

export const BOX_OFFICE_SALES_AVAILABILITY =
  'box-office-sales-availability' as const;

/**
 * key: is-optimistic-check-in-enabled
 * created by: Chris Sandvik
 * created on: 2024-10-11
 */

export const IS_OPTIMISTIC_CHECK_IN_ENABLED =
  'is-optimistic-check-in-enabled' as const;

/**
 * key: is-check-in-list-polling-disabled
 * created by: Chris Sandvik
 * created on: 2024-10-11
 */

export const IS_CHECK_IN_LIST_POLLING_DISABLED =
  'is-check-in-list-polling-disabled' as const;

/**
 * key: is-check-in-refetch-on-success-disabled
 * created by: Chris Sandvik
 * created on: 2024-10-11
 */

export const IS_CHECK_IN_REFETCH_ON_SUCCESS_DISABLED =
  'is-check-in-refetch-on-success-disabled' as const;

/**
 * key: is-new-qr-scanner-enabled
 * created by: Chris Sandvik
 * created on: 2024-10-09
 */

export const IS_NEW_QR_SCANNER_ENABLED = 'is-new-qr-scanner-enabled' as const;

/**
 * key: is-mobile-wallet-enabled
 * created by: Chris Sandvik
 * created on: 2024-10-03
 */

export const IS_MOBILE_WALLET_ENABLED = 'is-mobile-wallet-enabled' as const;

/**
 * key: campaign-accept-donor-recognition-name
 * created by: Scott Huang
 * created on: 2024-09-25
 */

export const CAMPAIGN_ACCEPT_DONOR_RECOGNITION_NAME =
  'campaign-accept-donor-recognition-name' as const;

/**
 * key: add-recognition-name
 * created by: Scott Huang
 * created on: 2024-09-25
 */

export const ADD_RECOGNITION_NAME = 'add-recognition-name' as const;

/**
 * key: is-custom-reports-enabled
 * created by: Chris Sandvik
 * created on: 2024-09-24
 */

export const IS_CUSTOM_REPORTS_ENABLED = 'is-custom-reports-enabled' as const;

/**
 * key: are-new-saved-queries-enabled
 * created by: Chris Sandvik
 * created on: 2024-09-19
 */

export const ARE_NEW_SAVED_QUERIES_ENABLED =
  'are-new-saved-queries-enabled' as const;

/**
 * key: held-tickets-report
 * description: List of Held Tickets Report
 * created by: Scott Huang
 * created on: 2024-09-17
 */

export const HELD_TICKETS_REPORT = 'held-tickets-report' as const;

/**
 * key: ticket-buyer-attendance-number
 * created by: Scott Huang
 * created on: 2024-09-17
 */

export const TICKET_BUYER_ATTENDANCE_NUMBER =
  'ticket-buyer-attendance-number' as const;

/**
 * key: is-ag-grid-pivot-enabled
 * created by: Chris Sandvik
 * created on: 2024-09-16
 */

export const IS_AG_GRID_PIVOT_ENABLED = 'is-ag-grid-pivot-enabled' as const;

/**
 * key: is-show-genre-selector-enabled
 * created by: Chris Sandvik
 * created on: 2024-09-06
 */

export const IS_SHOW_GENRE_SELECTOR_ENABLED =
  'is-show-genre-selector-enabled' as const;

/**
 * key: are-communication-campaigns-enabled
 * created by: Chris Sandvik
 * created on: 2024-08-15
 */

export const ARE_COMMUNICATION_CAMPAIGNS_ENABLED =
  'are-communication-campaigns-enabled' as const;

/**
 * key: is-auto-merge-duplicates-enabled
 * created by: Chris Sandvik
 * created on: 2024-07-08
 */

export const IS_AUTO_MERGE_DUPLICATES_ENABLED =
  'is-auto-merge-duplicates-enabled' as const;

/**
 * key: is-showtime-venue-layout-enabled
 * created by: Chris Sandvik
 * created on: 2024-04-03
 */

export const IS_SHOWTIME_VENUE_LAYOUT_ENABLED =
  'is-showtime-venue-layout-enabled' as const;

/**
 * key: is-season-package-renewal-enabled
 * created by: Chris Sandvik
 * created on: 2024-03-19
 */

export const IS_SEASON_PACKAGE_RENEWAL_ENABLED =
  'is-season-package-renewal-enabled' as const;

/**
 * key: is-consumer-showtime-date-filter-enabled
 * created by: Chris Sandvik
 * created on: 2024-01-24
 */

export const IS_CONSUMER_SHOWTIME_DATE_FILTER_ENABLED =
  'is-consumer-showtime-date-filter-enabled' as const;

/**
 * key: discount-membership-benefits
 * created by: Unknown User
 * created on: 2023-11-27
 */

export const DISCOUNT_MEMBERSHIP_BENEFITS =
  'discount-membership-benefits' as const;

/**
 * key: should-show-sybunt-report
 * created by: Chris Sandvik
 * created on: 2023-07-31
 */

export const SHOULD_SHOW_SYBUNT_REPORT = 'should-show-sybunt-report' as const;

/**
 * key: individual-profile-lifecycle-stage
 * created by: Unknown User
 * created on: 2023-07-17
 */

export const INDIVIDUAL_PROFILE_LIFECYCLE_STAGE =
  'individual-profile-lifecycle-stage' as const;

/**
 * key: app-homepage-enabled
 * created by: Unknown User
 * created on: 2023-07-11
 */

export const APP_HOMEPAGE_ENABLED = 'app-homepage-enabled' as const;

/**
 * key: attach-files-to-new-donation
 * created by: Unknown User
 * created on: 2023-07-11
 */

export const ATTACH_FILES_TO_NEW_DONATION =
  'attach-files-to-new-donation' as const;

/**
 * key: timeline-email-items
 * created by: Unknown User
 * created on: 2023-07-07
 */

export const TIMELINE_EMAIL_ITEMS = 'timeline-email-items' as const;
