import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const $fs = cssVar('avatar-font-size');

const getSize = (themeSize: string) => {
  return definePartsStyle({
    container: {
      // Because we only use single letter avatars, we want to increase the font size
      // to fill up more of the avatar
      [$fs.variable]: `calc(${themeSize} / 2)`,
    },
    excessLabel: {
      [$fs.variable]: `calc(${themeSize} / 2)`,
    },
  });
};

const sizes = {
  '2xs': getSize('1rem'),
  xs: getSize('1.5rem'),
  sm: getSize('2rem'),
  md: getSize('3rem'),
  lg: getSize('4rem'),
  xl: getSize('6rem'),
  '2xl': getSize('8rem'),
};

/**
 * These styles are forked from the official Avatar component theme.
 *
 * @see {@link https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/avatar.ts}
 */
export const avatarTheme = defineMultiStyleConfig({
  sizes,
});
