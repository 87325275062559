// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/merchandise.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Int32Value, proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, Money, RevenueType, SalesChannel } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Merchandise
 */
export const Merchandise = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Merchandise",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "general_ledger_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image_asset", kind: "message", T: Asset },
    { no: 7, name: "sort_order", kind: "message", T: Int32Value },
    { no: 8, name: "order_confirmation_email_content_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "order_confirmation_email_content_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 11, name: "sales_channels", kind: "enum", T: proto3.getEnumType(SalesChannel), repeated: true },
    { no: 12, name: "is_on_sale", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "variant_groups", kind: "message", T: VariantGroup, repeated: true },
    { no: 14, name: "merchandise_skus", kind: "message", T: MerchandiseSku, repeated: true },
    { no: 15, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "min_merchandise_sku_price", kind: "message", T: Money },
    { no: 17, name: "max_merchandise_sku_price", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MerchandiseSku
 */
export const MerchandiseSku = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MerchandiseSku",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 3, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "merchandise_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "should_track_inventory", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "inventory_delta", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "inventory_count_read_only", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "price", kind: "message", T: Money },
    { no: 10, name: "image_asset", kind: "message", T: Asset },
    { no: 11, name: "does_require_fulfillment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "variant_group_values", kind: "message", T: MerchandiseSkuVariantValue, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.VariantGroup
 */
export const VariantGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.VariantGroup",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "values", kind: "message", T: VariantValue, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.VariantValue
 */
export const VariantValue = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.VariantValue",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MerchandiseStat
 */
export const MerchandiseStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MerchandiseStat",
  () => [
    { no: 1, name: "merchandise_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "inventory_count", kind: "message", T: Int32Value },
    { no: 3, name: "net_sales", kind: "message", T: Money },
    { no: 4, name: "net_sold_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MerchandiseSkuVariantValue
 */
export const MerchandiseSkuVariantValue = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MerchandiseSkuVariantValue",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 3, name: "variant_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "variant_value_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MerchandiseIDAndName
 */
export const MerchandiseIDAndName = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MerchandiseIDAndName",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMerchandiseRequest
 */
export const GetMerchandiseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMerchandiseRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMerchandiseResponse
 */
export const GetMerchandiseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMerchandiseResponse",
  () => [
    { no: 1, name: "merchandise", kind: "message", T: Merchandise },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMerchandisesRequest
 */
export const ListMerchandisesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMerchandisesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMerchandisesResponse
 */
export const ListMerchandisesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMerchandisesResponse",
  () => [
    { no: 1, name: "merchandises", kind: "message", T: Merchandise, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMerchandiseIDsAndNamesRequest
 */
export const ListMerchandiseIDsAndNamesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMerchandiseIDsAndNamesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMerchandiseIDsAndNamesResponse
 */
export const ListMerchandiseIDsAndNamesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMerchandiseIDsAndNamesResponse",
  () => [
    { no: 1, name: "merchandise_ids_and_names", kind: "message", T: MerchandiseIDAndName, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMerchandiseStatRequest
 */
export const GetMerchandiseStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMerchandiseStatRequest",
  () => [
    { no: 1, name: "merchandise_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMerchandiseStatResponse
 */
export const GetMerchandiseStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMerchandiseStatResponse",
  () => [
    { no: 1, name: "merchandise_stat", kind: "message", T: MerchandiseStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMerchandiseStatsRequest
 */
export const ListMerchandiseStatsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMerchandiseStatsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMerchandiseStatsResponse
 */
export const ListMerchandiseStatsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMerchandiseStatsResponse",
  () => [
    { no: 1, name: "merchandise_stats", kind: "message", T: MerchandiseStat, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateMerchandiseRequest
 */
export const CreateMerchandiseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateMerchandiseRequest",
  () => [
    { no: 1, name: "merchandise", kind: "message", T: Merchandise },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateMerchandiseResponse
 */
export const CreateMerchandiseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateMerchandiseResponse",
  () => [
    { no: 1, name: "merchandise", kind: "message", T: Merchandise },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveMerchandiseRequest
 */
export const SaveMerchandiseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveMerchandiseRequest",
  () => [
    { no: 1, name: "merchandise", kind: "message", T: Merchandise },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveMerchandiseResponse
 */
export const SaveMerchandiseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveMerchandiseResponse",
  () => [
    { no: 1, name: "merchandise", kind: "message", T: Merchandise },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveMerchandiseRequest
 */
export const ArchiveMerchandiseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveMerchandiseRequest",
  () => [
    { no: 1, name: "merchandise_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveMerchandiseResponse
 */
export const ArchiveMerchandiseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveMerchandiseResponse",
  [],
);

