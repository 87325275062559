// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/show.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3, Timestamp } from "@bufbuild/protobuf";
import { Address, Asset, ListShowsFilter, Money, SeatingModel, ShowLocationType, ShowStatus, ShowtimeLabel, ShowtimeStatus, TagType, TicketDeliveryMethod } from "../../../common/v1/common_pb.js";
import { PriceListView } from "./price_list_view_pb.js";

/**
 * Simplified version of the PriceList message to use on the consumer side.
 * Instead of understanding full ticket types, price categories, and having
 * a matrix structure, this is a simple list of options to display.
 *
 * @generated from message cuebox.services.consumer.v1.PriceList
 */
export const PriceList = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.PriceList",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 3, name: "options", kind: "message", T: PriceListOption, repeated: true },
    { no: 4, name: "min_ticket_price", kind: "message", T: Money },
    { no: 5, name: "max_ticket_price", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.PriceListOption
 */
export const PriceListOption = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.PriceListOption",
  () => [
    { no: 1, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_type_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "price_level_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "price_level_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "seatsio_price_category_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "price_level_color_hex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "amount", kind: "message", T: Money },
    { no: 8, name: "is_pay_what_you_want", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "pay_what_you_want_recommended_amount", kind: "message", T: Money },
    { no: 10, name: "available_ticket_quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "ticket_type_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.InPersonLocation
 */
export const InPersonLocation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.InPersonLocation",
  () => [
    { no: 1, name: "venue_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "venue_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 4, name: "venue_address", kind: "message", T: Address },
    { no: 5, name: "venue_image", kind: "message", T: Asset },
  ],
);

/**
 * We could add stuff in here in the future but it's empty for now.
 *
 * @generated from message cuebox.services.consumer.v1.OnlineLocation
 */
export const OnlineLocation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.OnlineLocation",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.Tag
 */
export const Tag = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Tag",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(TagType) },
  ],
);

/**
 * Show represents all of the data we need to display on the consumer
 * ticketing experience for a show.
 * We are purposefully not including some of the extraneous fields
 * that appear in the staff Show message.
 *
 * @generated from message cuebox.services.consumer.v1.Show
 */
export const Show = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Show",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "subtitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "image", kind: "message", T: Asset },
    { no: 9, name: "status", kind: "enum", T: proto3.getEnumType(ShowStatus) },
    { no: 10, name: "first_showtime_date", kind: "message", T: Timestamp },
    { no: 11, name: "first_showtime_timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "last_showtime_date", kind: "message", T: Timestamp },
    { no: 13, name: "last_showtime_timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "duration", kind: "message", T: Duration },
    { no: 15, name: "min_ticket_price", kind: "message", T: Money },
    { no: 16, name: "max_ticket_price", kind: "message", T: Money },
    { no: 17, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
    { no: 18, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 19, name: "showtime_locations", kind: "message", T: ShowtimeLocation, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ShowtimeLocation
 */
export const ShowtimeLocation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ShowtimeLocation",
  () => [
    { no: 1, name: "location_type", kind: "enum", T: proto3.getEnumType(ShowLocationType) },
    { no: 2, name: "in_person_location", kind: "message", T: InPersonLocation, oneof: "location" },
    { no: 3, name: "online_location", kind: "message", T: OnlineLocation, oneof: "location" },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.Showtime
 */
export const Showtime = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Showtime",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(ShowtimeStatus) },
    { no: 7, name: "starts_at", kind: "message", T: Timestamp },
    { no: 8, name: "ends_at", kind: "message", T: Timestamp },
    { no: 9, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "seatsio_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "price_list", kind: "message", T: PriceList },
    { no: 13, name: "price_list_view", kind: "message", T: PriceListView },
    { no: 14, name: "labels", kind: "enum", T: proto3.getEnumType(ShowtimeLabel), repeated: true },
    { no: 15, name: "available_ticket_quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "sales_starts_at", kind: "message", T: Timestamp },
    { no: 18, name: "sales_ends_at", kind: "message", T: Timestamp },
    { no: 19, name: "location", kind: "message", T: ShowtimeLocation },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListShowsRequest
 */
export const ListShowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListShowsRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListShowsFilter) },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListShowsResponse
 */
export const ListShowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListShowsResponse",
  () => [
    { no: 1, name: "shows", kind: "message", T: Show, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetShowRequest
 */
export const GetShowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetShowRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetShowResponse
 */
export const GetShowResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetShowResponse",
  () => [
    { no: 1, name: "show", kind: "message", T: Show },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListShowtimesRequest
 */
export const ListShowtimesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListShowtimesRequest",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListShowtimesResponse
 */
export const ListShowtimesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListShowtimesResponse",
  () => [
    { no: 1, name: "showtimes", kind: "message", T: Showtime, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetShowtimeRequest
 */
export const GetShowtimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetShowtimeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetShowtimeResponse
 */
export const GetShowtimeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetShowtimeResponse",
  () => [
    { no: 1, name: "showtime", kind: "message", T: Showtime },
  ],
);

