import { type ReactNode, useCallback, useMemo } from 'react';
import { Box, Button, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { useUser } from '@clerk/nextjs';
import { Organization } from '@pb/cuebox/services/consumer/v1/organization_pb';
import ErrorBoundary, { type ErrorPayload } from './components/ErrorBoundary';
import {
  ErrorTrackingContext,
  type ErrorTrackingContextApi,
  useErrorTrackingApi,
} from './hooks/useErrorTracking';

export const FallbackState = () => (
  <Center width="100%" height="100%" bg="backgrounds.base">
    <Stack
      width="fit-content"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Heading size="sm">Something went wrong</Heading>
      <Text>{`We couldn't load this page`}</Text>
      <Box pt={1}>
        <Button onClick={() => window.location.reload()}>Try Again</Button>
      </Box>
    </Stack>
  </Center>
);

interface ErrorTrackingProviderProps {
  children: ReactNode;
  orgJson?: string;
}

const ErrorTrackingProvider = ({
  children,
  orgJson,
}: ErrorTrackingProviderProps) => {
  const { user } = useUser();
  const isCueboxStaffMember =
    user?.primaryEmailAddress?.emailAddress
      .toLowerCase()
      .includes('getcuebox.com') || false;

  // The consumer org is passed down from the server render in string form and is used to set the org context in DD.
  const consumerOrg = useMemo<Organization | undefined>(() => {
    if (!orgJson) {
      return undefined;
    }

    return Organization.fromJsonString(orgJson);
  }, [orgJson]);

  const { trackError, trackWarning, trackInfo } = useErrorTrackingApi({
    isCueboxStaffMember,
    consumerOrg,
  });

  const handleBoundaryError = useCallback(
    ({ error, info }: ErrorPayload) => {
      trackError(error, info);
    },
    [trackError],
  );

  const errorContext = useMemo<ErrorTrackingContextApi>(
    () => ({
      trackError,
      trackInfo,
      trackWarning,
    }),
    [trackError, trackInfo, trackWarning],
  );

  return (
    <ErrorTrackingContext.Provider value={errorContext}>
      <ErrorBoundary fallback={FallbackState} onError={handleBoundaryError}>
        {children}
      </ErrorBoundary>
    </ErrorTrackingContext.Provider>
  );
};

export default ErrorTrackingProvider;
