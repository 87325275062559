export const shadows = {
  cuebox: '0px 1px 4px rgba(37, 41, 46, 0.08);',
  'cuebox-light': '0px 1px 2px 0px #25292e0f',
  selected: '0px 0px 0px 1px #3693ea',
  'selected-darker': '0px 0px 0px 1px var(--chakra-colors-primary-darker)',
  'selected-consumer': '0px 0px 0px 1px #365dea',
  inline: 'inset 0 0 0 2px rgba(66, 153, 225, 0.6)',
  'inline-error': 'inset 0 0 0 2px var(--chakra-colors-error)',
  error: '0px 0px 0px 1px var(--chakra-colors-error)',
};
