// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/form_question.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { FormQuestionPlacement, FormQuestionScope, FormQuestionType } from "../../../common/v1/common_pb.js";
import { ProductIDAndType } from "./product_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.FormQuestionOption
 */
export const FormQuestionOption = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionOption",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FormQuestion
 */
export const FormQuestion = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestion",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(FormQuestionType) },
    { no: 5, name: "label_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "label_plain_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "scope", kind: "enum", T: proto3.getEnumType(FormQuestionScope) },
    { no: 10, name: "placement", kind: "enum", T: proto3.getEnumType(FormQuestionPlacement) },
    { no: 11, name: "sort_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "options", kind: "message", T: FormQuestionOption, repeated: true },
    { no: 13, name: "product_id_and_types", kind: "message", T: ProductIDAndType, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFormQuestionRequest
 */
export const CreateFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFormQuestionRequest",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(FormQuestionType) },
    { no: 2, name: "label_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "label_plain_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "is_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "placement", kind: "enum", T: proto3.getEnumType(FormQuestionPlacement) },
    { no: 7, name: "scope", kind: "enum", T: proto3.getEnumType(FormQuestionScope) },
    { no: 8, name: "sort_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "options", kind: "message", T: FormQuestionOption, repeated: true },
    { no: 10, name: "product_id_and_types", kind: "message", T: ProductIDAndType, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFormQuestionResponse
 */
export const CreateFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFormQuestionResponse",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveFormQuestionRequest
 */
export const SaveFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveFormQuestionRequest",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveFormQuestionResponse
 */
export const SaveFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveFormQuestionResponse",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionsRequest
 */
export const ListFormQuestionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionsRequest",
  () => [
    { no: 1, name: "product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFormQuestionsResponse
 */
export const ListFormQuestionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFormQuestionsResponse",
  () => [
    { no: 1, name: "form_questions", kind: "message", T: FormQuestion, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFormQuestionRequest
 */
export const GetFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFormQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFormQuestionResponse
 */
export const GetFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFormQuestionResponse",
  () => [
    { no: 1, name: "form_question", kind: "message", T: FormQuestion },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveFormQuestionRequest
 */
export const ArchiveFormQuestionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveFormQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveFormQuestionResponse
 */
export const ArchiveFormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveFormQuestionResponse",
  [],
);

/**
 * Checkout form messages
 *
 * @generated from message cuebox.services.staff.v1.FormQuestionGroup
 */
export const FormQuestionGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionGroup",
  () => [
    { no: 1, name: "item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "questions", kind: "message", T: FormQuestion, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckoutForm
 */
export const CheckoutForm = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckoutForm",
  () => [
    { no: 1, name: "order_question_group", kind: "message", T: FormQuestionGroup },
    { no: 2, name: "item_question_groups", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionGroup} },
  ],
);

/**
 * Response messages
 *
 * @generated from message cuebox.services.staff.v1.FormQuestionAnswer
 */
export const FormQuestionAnswer = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionAnswer",
  () => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_type", kind: "enum", T: proto3.getEnumType(FormQuestionType) },
    { no: 3, name: "text_response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "selected_option_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "selected_option_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "is_checked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FormQuestionAnswerGroup
 */
export const FormQuestionAnswerGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionAnswerGroup",
  () => [
    { no: 1, name: "item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "answers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionAnswer} },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckoutFormSubmission
 */
export const CheckoutFormSubmission = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckoutFormSubmission",
  () => [
    { no: 1, name: "order_answer_group", kind: "message", T: FormQuestionAnswerGroup },
    { no: 2, name: "item_answer_groups", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionAnswerGroup} },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FormQuestionResponse
 */
export const FormQuestionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "submitted_at", kind: "message", T: Timestamp },
    { no: 5, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "answer", kind: "message", T: FormQuestionAnswer },
    { no: 7, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FormQuestionResponseGroup
 */
export const FormQuestionResponseGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FormQuestionResponseGroup",
  () => [
    { no: 1, name: "item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "responses", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionResponse} },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.OrderFormResponses
 */
export const OrderFormResponses = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.OrderFormResponses",
  () => [
    { no: 1, name: "order_response_group", kind: "message", T: FormQuestionResponseGroup },
    { no: 2, name: "item_response_groups", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: FormQuestionResponseGroup} },
  ],
);

/**
 * GetOrderFormAndResponses
 *
 * @generated from message cuebox.services.staff.v1.GetOrderFormAndResponsesRequest
 */
export const GetOrderFormAndResponsesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetOrderFormAndResponsesRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetOrderFormAndResponsesResponse
 */
export const GetOrderFormAndResponsesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetOrderFormAndResponsesResponse",
  () => [
    { no: 1, name: "form", kind: "message", T: CheckoutForm },
    { no: 2, name: "responses", kind: "message", T: OrderFormResponses },
  ],
);

/**
 * CreateOrSaveFormQuestionResponse
 *
 * @generated from message cuebox.services.staff.v1.CreateOrSaveFormQuestionResponseRequest
 */
export const CreateOrSaveFormQuestionResponseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateOrSaveFormQuestionResponseRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "answer", kind: "message", T: FormQuestionAnswer },
    { no: 3, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateOrSaveFormQuestionResponseResponse
 */
export const CreateOrSaveFormQuestionResponseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateOrSaveFormQuestionResponseResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: FormQuestionResponse },
  ],
);

