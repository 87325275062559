export const UNDEFINED_VALUE_PLACEHOLDER = '-';

export const DOLLAR_SIGN = '$';

export const SUPPORT_EMAIL = 'hello@getcuebox.com';

export const TERMS_OF_SERVICE_URL =
  'https://legal.getcuebox.com/terms-of-service.pdf';

export const PRIVACY_POLICY_URL =
  'https://legal.getcuebox.com/privacy-policy.pdf';

export const TERMS_OF_USE_URL = 'https://legal.getcuebox.com/terms-of-use.pdf';

export const NEW_ID = 'NEW';
