// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/subscription.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AcknowledgmentStatus, BenefitPeriodType, ConstituentType, Money, PhoneNumber, RevenueType, SalesChannel, SubscriptionFrequency, SubscriptionStatus } from "../../../common/v1/common_pb.js";
import { ConsumerProfile } from "./consumer_profile_pb.js";
import { BillingInfo } from "./order_pb.js";
import { Membership } from "./membership_pb.js";
import { ContributedRevenueAttributes } from "./contributed_revenue_attributes_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Subscription
 */
export const Subscription = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Subscription",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 5, name: "consumer_profile", kind: "message", T: ConsumerProfile },
    { no: 6, name: "billing_info", kind: "message", T: BillingInfo },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(SubscriptionStatus) },
    { no: 8, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 9, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 10, name: "recurring_amount", kind: "message", T: Money },
    { no: 11, name: "started_at", kind: "message", T: Timestamp },
    { no: 12, name: "canceled_at", kind: "message", T: Timestamp },
    { no: 13, name: "next_payment_at", kind: "message", T: Timestamp },
    { no: 14, name: "tilled_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "finix_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "stat", kind: "message", T: SubscriptionStat },
    { no: 101, name: "donation_subscription", kind: "message", T: DonationSubscription, oneof: "subscription" },
    { no: 102, name: "membership_subscription", kind: "message", T: MembershipSubscription, oneof: "subscription" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SubscriptionStat
 */
export const SubscriptionStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SubscriptionStat",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "net_payments_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "net_paid_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipSubscription
 */
export const MembershipSubscription = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipSubscription",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "membership", kind: "message", T: Membership },
    { no: 5, name: "contributed_revenue_attributes", kind: "message", T: ContributedRevenueAttributes },
    { no: 6, name: "snapshot_benefit_period_type", kind: "enum", T: proto3.getEnumType(BenefitPeriodType) },
    { no: 7, name: "snapshot_benefit_period_duration_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "snapshot_benefit_period_expires_at", kind: "message", T: Timestamp },
    { no: 9, name: "max_successful_payments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "stat", kind: "message", T: MembershipSubscriptionStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipSubscriptionStat
 */
export const MembershipSubscriptionStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipSubscriptionStat",
  () => [
    { no: 1, name: "membership_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current_period_starts_at", kind: "message", T: Timestamp },
    { no: 3, name: "current_period_ends_at", kind: "message", T: Timestamp },
    { no: 4, name: "ticket_benefit_current_period_used_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "ticket_benefit_current_period_remaining_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationSubscription
 */
export const DonationSubscription = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationSubscription",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "contributed_revenue_attributes", kind: "message", T: ContributedRevenueAttributes },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SubscriptionTableRow
 */
export const SubscriptionTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SubscriptionTableRow",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "started_at", kind: "message", T: Timestamp },
    { no: 3, name: "canceled_at", kind: "message", T: Timestamp },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(SubscriptionStatus) },
    { no: 5, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "constituent_type", kind: "enum", T: proto3.getEnumType(ConstituentType) },
    { no: 7, name: "consumer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "consumer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "consumer_phone_number", kind: "message", T: PhoneNumber },
    { no: 11, name: "billing_info", kind: "message", T: BillingInfo },
    { no: 12, name: "item_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 14, name: "recurring_amount", kind: "message", T: Money },
    { no: 15, name: "next_payment_at", kind: "message", T: Timestamp },
    { no: 16, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 17, name: "current_benefit_period_starts_at", kind: "message", T: Timestamp },
    { no: 18, name: "current_benefit_period_ends_at", kind: "message", T: Timestamp },
    { no: 19, name: "contributed_revenue_attributes", kind: "message", T: ContributedRevenueAttributes },
    { no: 20, name: "applied_discount_code", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 21, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 22, name: "subscription_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "ticket_benefit_current_period_used_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 24, name: "ticket_benefit_current_period_remaining_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSubscriptionRequest
 */
export const GetSubscriptionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSubscriptionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSubscriptionResponse
 */
export const GetSubscriptionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSubscriptionResponse",
  () => [
    { no: 1, name: "subscription", kind: "message", T: Subscription },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSubscriptionTableRowsRequest
 */
export const ListSubscriptionTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSubscriptionTableRowsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSubscriptionTableRowsResponse
 */
export const ListSubscriptionTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSubscriptionTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: SubscriptionTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSubscriptionRequest
 */
export const SaveSubscriptionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSubscriptionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSubscriptionResponse
 */
export const SaveSubscriptionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSubscriptionResponse",
  () => [
    { no: 1, name: "subscription", kind: "message", T: Subscription },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelSubscriptionRequest
 */
export const CancelSubscriptionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelSubscriptionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelSubscriptionResponse
 */
export const CancelSubscriptionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelSubscriptionResponse",
  () => [
    { no: 1, name: "subscription", kind: "message", T: Subscription },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddSubscriptionPaymentMethodRequest
 */
export const AddSubscriptionPaymentMethodRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddSubscriptionPaymentMethodRequest",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tilled_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "finix_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "finix_billing_postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddSubscriptionPaymentMethodResponse
 */
export const AddSubscriptionPaymentMethodResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddSubscriptionPaymentMethodResponse",
  () => [
    { no: 1, name: "subscription", kind: "message", T: Subscription },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateSubscriptionPaymentMethodRequest
 */
export const UpdateSubscriptionPaymentMethodRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateSubscriptionPaymentMethodRequest",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tilled_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "finix_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "finix_billing_postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateSubscriptionPaymentMethodResponse
 */
export const UpdateSubscriptionPaymentMethodResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateSubscriptionPaymentMethodResponse",
  () => [
    { no: 1, name: "subscription", kind: "message", T: Subscription },
  ],
);

