import { useMemo } from 'react';
import { merge } from 'lodash-es';
import { extendTheme } from '@chakra-ui/react';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { components } from './components';
import fonts from './fonts';
import globalStyles from './global';
import { type SemanticTokens, semanticTokens } from './semanticTokens';
import { shadows } from './shadows';
import { sizes, space } from './space';

export type { SemanticTokens };

const getDefaultThemeConfig = (props: {
  additionalSemanticTokens?: SemanticTokens;
}) => ({
  breakpoints,
  colors,
  components,
  semanticTokens: merge(semanticTokens, props?.additionalSemanticTokens),
  shadows,
  sizes,
  fonts,
  space,
  styles: {
    global: globalStyles,
  },
});

export type ThemeOverrides = {
  semanticTokens?: SemanticTokens;
};

export const useChakraTheme = (themeOverrides?: ThemeOverrides) =>
  useMemo(
    () =>
      extendTheme(
        getDefaultThemeConfig({
          additionalSemanticTokens: themeOverrides?.semanticTokens,
        }),
      ),
    [themeOverrides?.semanticTokens],
  );
