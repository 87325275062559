import type { GetToken } from '@clerk/types';
import type { Interceptor } from '@connectrpc/connect';

type AuthInterceptorOptions = {
  getToken: GetToken;
};

const auth =
  (options: AuthInterceptorOptions): Interceptor =>
  (next) =>
  async (req) => {
    const { getToken } = options;
    const token = await getToken();

    if (token) {
      req.header.set('Authorization', `Bearer ${token}`);
    }

    return next(req);
  };

export default auth;
