// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/pledge.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ConsumerProfile } from "./consumer_profile_pb.js";
import { AcknowledgmentStatus, ListPledgesFilter, Money, PledgeStatus } from "../../../common/v1/common_pb.js";
import { Campaign } from "./campaign_pb.js";
import { Fund } from "./fund_pb.js";
import { Appeal } from "./appeal_pb.js";
import { DonationItem } from "./order_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Pledge
 */
export const Pledge = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Pledge",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "pledged_at", kind: "message", T: Timestamp },
    { no: 6, name: "donor", kind: "message", T: ConsumerProfile },
    { no: 7, name: "installments", kind: "message", T: PledgeInstallment, repeated: true },
    { no: 8, name: "write_off_amount", kind: "message", T: Money },
    { no: 9, name: "campaign", kind: "message", T: Campaign },
    { no: 10, name: "fund", kind: "message", T: Fund },
    { no: 11, name: "appeal", kind: "message", T: Appeal },
    { no: 12, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
    { no: 13, name: "acknowledged_at", kind: "message", T: Timestamp },
    { no: 14, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "stat", kind: "message", T: PledgeStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PledgeInstallment
 */
export const PledgeInstallment = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PledgeInstallment",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "pledge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "expected_at", kind: "message", T: Timestamp },
    { no: 7, name: "expected_amount", kind: "message", T: Money },
    { no: 8, name: "donation", kind: "message", T: DonationItem },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PledgeStat
 */
export const PledgeStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PledgeStat",
  () => [
    { no: 1, name: "pledge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(PledgeStatus) },
    { no: 3, name: "total_amount", kind: "message", T: Money },
    { no: 4, name: "paid_amount", kind: "message", T: Money },
    { no: 5, name: "outstanding_amount", kind: "message", T: Money },
    { no: 6, name: "upcoming_installment", kind: "message", T: PledgeInstallment },
    { no: 7, name: "is_outstanding", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PledgeTableRow
 */
export const PledgeTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PledgeTableRow",
  () => [
    { no: 1, name: "pledge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pledged_at", kind: "message", T: Timestamp },
    { no: 3, name: "donor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "donor_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "total_amount", kind: "message", T: Money },
    { no: 6, name: "paid_amount", kind: "message", T: Money },
    { no: 7, name: "outstanding_amount", kind: "message", T: Money },
    { no: 8, name: "pledge_status", kind: "enum", T: proto3.getEnumType(PledgeStatus) },
    { no: 9, name: "upcoming_installment_date", kind: "message", T: Timestamp },
    { no: 10, name: "upcoming_installment_amount", kind: "message", T: Money },
    { no: 11, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "fund_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "appeal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
    { no: 15, name: "pledge_created_at", kind: "message", T: Timestamp },
    { no: 16, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreatePledgeRequest
 */
export const CreatePledgeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreatePledgeRequest",
  () => [
    { no: 1, name: "pledged_at", kind: "message", T: Timestamp },
    { no: 2, name: "donor_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_amount", kind: "message", T: Money },
    { no: 4, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
    { no: 8, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "installments", kind: "message", T: PledgeInstallment, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreatePledgeResponse
 */
export const CreatePledgeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreatePledgeResponse",
  () => [
    { no: 1, name: "pledge", kind: "message", T: Pledge },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPledgeTableRowsRequest
 */
export const ListPledgeTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPledgeTableRowsRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListPledgesFilter) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPledgeTableRowsResponse
 */
export const ListPledgeTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPledgeTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: PledgeTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavePledgeRequest
 */
export const SavePledgeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavePledgeRequest",
  () => [
    { no: 1, name: "pledge", kind: "message", T: Pledge },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavePledgeResponse
 */
export const SavePledgeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavePledgeResponse",
  () => [
    { no: 1, name: "pledge", kind: "message", T: Pledge },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetPledgeRequest
 */
export const GetPledgeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetPledgeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetPledgeResponse
 */
export const GetPledgeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetPledgeResponse",
  () => [
    { no: 1, name: "pledge", kind: "message", T: Pledge },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPledgesRequest
 */
export const ListPledgesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPledgesRequest",
  () => [
    { no: 1, name: "donor_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPledgesResponse
 */
export const ListPledgesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPledgesResponse",
  () => [
    { no: 1, name: "pledges", kind: "message", T: Pledge, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.WriteOffPledgeRequest
 */
export const WriteOffPledgeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.WriteOffPledgeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.WriteOffPledgeResponse
 */
export const WriteOffPledgeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.WriteOffPledgeResponse",
  () => [
    { no: 1, name: "pledge", kind: "message", T: Pledge },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchivePledgeRequest
 */
export const ArchivePledgeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchivePledgeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchivePledgeResponse
 */
export const ArchivePledgeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchivePledgeResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.AddPledgeInstallmentRequest
 */
export const AddPledgeInstallmentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddPledgeInstallmentRequest",
  () => [
    { no: 1, name: "pledge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expected_at", kind: "message", T: Timestamp },
    { no: 3, name: "expected_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddPledgeInstallmentResponse
 */
export const AddPledgeInstallmentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddPledgeInstallmentResponse",
  () => [
    { no: 1, name: "installment", kind: "message", T: PledgeInstallment },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavePledgeInstallmentRequest
 */
export const SavePledgeInstallmentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavePledgeInstallmentRequest",
  () => [
    { no: 1, name: "installment", kind: "message", T: PledgeInstallment },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavePledgeInstallmentResponse
 */
export const SavePledgeInstallmentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavePledgeInstallmentResponse",
  () => [
    { no: 1, name: "installment", kind: "message", T: PledgeInstallment },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchivePledgeInstallmentRequest
 */
export const ArchivePledgeInstallmentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchivePledgeInstallmentRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchivePledgeInstallmentResponse
 */
export const ArchivePledgeInstallmentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchivePledgeInstallmentResponse",
  [],
);

