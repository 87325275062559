// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/membership_benefit.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.Benefit
 */
export const Benefit = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Benefit",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "ticket_benefit", kind: "message", T: TicketBenefit, oneof: "item" },
    { no: 102, name: "discount_benefit", kind: "message", T: DiscountBenefit, oneof: "item" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketBenefit
 */
export const TicketBenefit = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketBenefit",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "membership_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "max_tickets", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "max_tickets_per_show", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "is_applied_to_all_shows", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DiscountBenefit
 */
export const DiscountBenefit = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DiscountBenefit",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "membership_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "discount_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetBenefitRequest
 */
export const GetBenefitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetBenefitRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetBenefitResponse
 */
export const GetBenefitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetBenefitResponse",
  () => [
    { no: 1, name: "benefit", kind: "message", T: Benefit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBenefitsRequest
 */
export const ListBenefitsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBenefitsRequest",
  () => [
    { no: 1, name: "membership_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBenefitsResponse
 */
export const ListBenefitsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBenefitsResponse",
  () => [
    { no: 1, name: "benefits", kind: "message", T: Benefit, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateTicketBenefitRequest
 */
export const CreateTicketBenefitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateTicketBenefitRequest",
  () => [
    { no: 1, name: "membership_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "max_tickets", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "max_tickets_per_show", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "is_applied_to_all_shows", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateTicketBenefitResponse
 */
export const CreateTicketBenefitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateTicketBenefitResponse",
  () => [
    { no: 1, name: "ticket_benefit", kind: "message", T: TicketBenefit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveTicketBenefitRequest
 */
export const SaveTicketBenefitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveTicketBenefitRequest",
  () => [
    { no: 1, name: "ticket_benefit", kind: "message", T: TicketBenefit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveTicketBenefitResponse
 */
export const SaveTicketBenefitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveTicketBenefitResponse",
  () => [
    { no: 1, name: "ticket_benefit", kind: "message", T: TicketBenefit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDiscountBenefitRequest
 */
export const CreateDiscountBenefitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDiscountBenefitRequest",
  () => [
    { no: 1, name: "membership_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "discount_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDiscountBenefitResponse
 */
export const CreateDiscountBenefitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDiscountBenefitResponse",
  () => [
    { no: 1, name: "discount_benefit", kind: "message", T: DiscountBenefit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDiscountBenefitRequest
 */
export const SaveDiscountBenefitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDiscountBenefitRequest",
  () => [
    { no: 1, name: "discount_benefit", kind: "message", T: DiscountBenefit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDiscountBenefitResponse
 */
export const SaveDiscountBenefitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDiscountBenefitResponse",
  () => [
    { no: 1, name: "discount_benefit", kind: "message", T: DiscountBenefit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveBenefitRequest
 */
export const ArchiveBenefitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveBenefitRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveBenefitResponse
 */
export const ArchiveBenefitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveBenefitResponse",
  [],
);

