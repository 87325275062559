// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/saved_query.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { TimeRange } from "../../../common/v1/common_pb.js";
import { ConstituentTableFilters } from "./consumer_profile_pb.js";
import { OrderTableFilters } from "./order_table_pb.js";
import { ContributedRevenueTableFilters } from "./contributed_revenue_attributes_pb.js";
import { FormQuestionAndAnswerTableFilters, TransactionTableFilters } from "./analytics_pb.js";
import { StaffMember } from "./staff_member_pb.js";

/**
 * @generated from enum cuebox.services.staff.v1.SavedQueryVersion
 */
export const SavedQueryVersion = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.SavedQueryVersion",
  [
    {no: 0, name: "SAVED_QUERY_VERSION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SAVED_QUERY_VERSION_V1", localName: "V1"},
    {no: 2, name: "SAVED_QUERY_VERSION_V2", localName: "V2"},
  ],
);

/**
 * @generated from enum cuebox.services.staff.v1.ConstituentsTableMode
 */
export const ConstituentsTableMode = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.ConstituentsTableMode",
  [
    {no: 0, name: "CONSTITUENTS_TABLE_MODE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CONSTITUENTS_TABLE_MODE_INDIVIDUAL", localName: "INDIVIDUAL"},
    {no: 2, name: "CONSTITUENTS_TABLE_MODE_HOUSEHOLD", localName: "HOUSEHOLD"},
  ],
);

/**
 * @generated from enum cuebox.services.staff.v1.SavedQueryBaseTable
 */
export const SavedQueryBaseTable = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.SavedQueryBaseTable",
  [
    {no: 0, name: "SAVED_QUERY_BASE_TABLE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SAVED_QUERY_BASE_TABLE_CONSTITUENT", localName: "CONSTITUENT"},
    {no: 2, name: "SAVED_QUERY_BASE_TABLE_ORDER", localName: "ORDER"},
    {no: 3, name: "SAVED_QUERY_BASE_TABLE_CONTRIBUTED_REVENUE", localName: "CONTRIBUTED_REVENUE"},
    {no: 4, name: "SAVED_QUERY_BASE_TABLE_TRANSACTIONS_OVER_TIME", localName: "TRANSACTIONS_OVER_TIME"},
    {no: 5, name: "SAVED_QUERY_BASE_TABLE_QUESTIONS_AND_ANSWERS", localName: "QUESTIONS_AND_ANSWERS"},
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AgGridState
 */
export const AgGridState = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AgGridState",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AgGridFilterState
 */
export const AgGridFilterState = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AgGridFilterState",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AgGridColumnState
 */
export const AgGridColumnState = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AgGridColumnState",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FilterState
 */
export const FilterState = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FilterState",
  () => [
    { no: 1, name: "donation_time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavedQueryRequestFilters
 */
export const SavedQueryRequestFilters = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavedQueryRequestFilters",
  () => [
    { no: 100, name: "constituent", kind: "message", T: ConstituentTableFilters, oneof: "request_filters" },
    { no: 101, name: "order", kind: "message", T: OrderTableFilters, oneof: "request_filters" },
    { no: 102, name: "contributed_revenue", kind: "message", T: ContributedRevenueTableFilters, oneof: "request_filters" },
    { no: 103, name: "transactions_over_time", kind: "message", T: TransactionTableFilters, oneof: "request_filters" },
    { no: 104, name: "questions_and_answers", kind: "message", T: FormQuestionAndAnswerTableFilters, oneof: "request_filters" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavedQuery
 */
export const SavedQuery = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavedQuery",
  () => [
    { no: 1, name: "base_table", kind: "enum", T: proto3.getEnumType(SavedQueryBaseTable) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 6, name: "version", kind: "enum", T: proto3.getEnumType(SavedQueryVersion) },
    { no: 7, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "ag_grid_state", kind: "message", T: AgGridState },
    { no: 10, name: "owner", kind: "message", T: StaffMember },
    { no: 11, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "request_filters", kind: "message", T: SavedQueryRequestFilters },
    { no: 13, name: "is_custom_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateSavedQueryRequest
 */
export const CreateSavedQueryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateSavedQueryRequest",
  () => [
    { no: 1, name: "version", kind: "enum", T: proto3.getEnumType(SavedQueryVersion) },
    { no: 2, name: "base_table", kind: "enum", T: proto3.getEnumType(SavedQueryBaseTable) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ag_grid_state", kind: "message", T: AgGridState },
    { no: 6, name: "request_filters", kind: "message", T: SavedQueryRequestFilters },
    { no: 7, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_custom_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateSavedQueryResponse
 */
export const CreateSavedQueryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateSavedQueryResponse",
  () => [
    { no: 1, name: "saved_query", kind: "message", T: SavedQuery },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSavedQueriesRequest
 */
export const ListSavedQueriesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSavedQueriesRequest",
  () => [
    { no: 1, name: "base_tables", kind: "enum", T: proto3.getEnumType(SavedQueryBaseTable), repeated: true },
    { no: 2, name: "saved_query_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "should_only_include_custom_reports", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSavedQueriesResponse
 */
export const ListSavedQueriesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSavedQueriesResponse",
  () => [
    { no: 1, name: "saved_queries", kind: "message", T: SavedQuery, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSavedQueryRequest
 */
export const GetSavedQueryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSavedQueryRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSavedQueryResponse
 */
export const GetSavedQueryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSavedQueryResponse",
  () => [
    { no: 1, name: "saved_query", kind: "message", T: SavedQuery },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSavedQueryRequest
 */
export const SaveSavedQueryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSavedQueryRequest",
  () => [
    { no: 1, name: "saved_query", kind: "message", T: SavedQuery },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSavedQueryResponse
 */
export const SaveSavedQueryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSavedQueryResponse",
  () => [
    { no: 1, name: "saved_query", kind: "message", T: SavedQuery },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveSavedQueryRequest
 */
export const ArchiveSavedQueryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveSavedQueryRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveSavedQueryResponse
 */
export const ArchiveSavedQueryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveSavedQueryResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateSavedQuerySharingRequest
 */
export const UpdateSavedQuerySharingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateSavedQuerySharingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateSavedQuerySharingResponse
 */
export const UpdateSavedQuerySharingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateSavedQuerySharingResponse",
  () => [
    { no: 1, name: "saved_query", kind: "message", T: SavedQuery },
  ],
);

