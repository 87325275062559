import type { HTMLAttributes } from 'react';

interface AriaAttributesOpts {
  listboxId?: string;
  optionIdPrefix?: string;
}

interface PlacesAriaAttributes {
  inputAttributes: HTMLAttributes<HTMLInputElement>;
  listboxAttributes: HTMLAttributes<HTMLElement>;
  getOptionAttributes: (index: number) => HTMLAttributes<HTMLElement>;
}

const getAriaAttributes = (
  isExpanded: boolean,
  currIndex: number | null | undefined,
  opts: AriaAttributesOpts = {},
): PlacesAriaAttributes => {
  const {
    listboxId = 'google-places-autocomplete-listbox',
    optionIdPrefix = 'google-places-autocomplete-option',
  } = opts;

  const hasCurrIndex = typeof currIndex === 'number';

  const inputAttributes: HTMLAttributes<HTMLInputElement> = {
    role: 'combobox',
    'aria-haspopup': 'listbox',
    'aria-autocomplete': 'list',
    'aria-expanded': isExpanded,
    'aria-controls': listboxId,
    'aria-activedescendant': hasCurrIndex
      ? `${optionIdPrefix}-${currIndex}`
      : undefined,
  };

  const listboxAttributes: HTMLAttributes<HTMLElement> = {
    id: listboxId,
    role: 'listbox',
  };

  const getOptionAttributes = (index: number) => ({
    id: `${optionIdPrefix}-${index}`,
    role: 'option',
    'aria-selected': index === currIndex,
  });

  return {
    inputAttributes,
    listboxAttributes,
    getOptionAttributes,
  };
};

export default getAriaAttributes;
