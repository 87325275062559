// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/user.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.GetUserDetailsRequest
 */
export const GetUserDetailsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetUserDetailsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.GetUserDetailsResponse
 */
export const GetUserDetailsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetUserDetailsResponse",
  () => [
    { no: 1, name: "cuebox_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

