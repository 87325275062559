import { type ReactNode, createContext } from 'react';
import type { ConsumerServiceContextApi } from '../hooks/useConsumerService';
import useConsumerService from '../hooks/useConsumerService';

export const ConsumerServiceContext = createContext<ConsumerServiceContextApi>(
  {} as ConsumerServiceContextApi,
);

interface ConsumerServiceProviderProps {
  children: ReactNode;
}

const ConsumerServiceProvider = ({
  children,
}: ConsumerServiceProviderProps) => {
  const contextApi = useConsumerService();

  return (
    <ConsumerServiceContext.Provider value={contextApi}>
      {children}
    </ConsumerServiceContext.Provider>
  );
};

export default ConsumerServiceProvider;
