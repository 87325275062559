// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/hardware_device.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { HardwareDeviceStatus, HardwareDeviceType } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.HardwareDevice
 */
export const HardwareDevice = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.HardwareDevice",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "serial_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(HardwareDeviceType) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.HardwareDeviceStat
 */
export const HardwareDeviceStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.HardwareDeviceStat",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(HardwareDeviceStatus) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListHardwareDevicesRequest
 */
export const ListHardwareDevicesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListHardwareDevicesRequest",
  () => [
    { no: 1, name: "types", kind: "enum", T: proto3.getEnumType(HardwareDeviceType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListHardwareDevicesResponse
 */
export const ListHardwareDevicesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListHardwareDevicesResponse",
  () => [
    { no: 1, name: "devices", kind: "message", T: HardwareDevice, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListHardwareDeviceStatsRequest
 */
export const ListHardwareDeviceStatsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListHardwareDeviceStatsRequest",
  () => [
    { no: 1, name: "types", kind: "enum", T: proto3.getEnumType(HardwareDeviceType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListHardwareDeviceStatsResponse
 */
export const ListHardwareDeviceStatsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListHardwareDeviceStatsResponse",
  () => [
    { no: 1, name: "stats", kind: "message", T: HardwareDeviceStat, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateHardwareDeviceDetailsRequest
 */
export const UpdateHardwareDeviceDetailsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateHardwareDeviceDetailsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(HardwareDeviceType) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateHardwareDeviceDetailsResponse
 */
export const UpdateHardwareDeviceDetailsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateHardwareDeviceDetailsResponse",
  () => [
    { no: 1, name: "device", kind: "message", T: HardwareDevice },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelCardReaderCheckoutRequest
 */
export const CancelCardReaderCheckoutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelCardReaderCheckoutRequest",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(HardwareDeviceType) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelCardReaderCheckoutResponse
 */
export const CancelCardReaderCheckoutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelCardReaderCheckoutResponse",
  [],
);

