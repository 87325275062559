import {
  DD_ENV,
  DD_LOGS_CLIENT_TOKEN,
  DD_RUM_APPLICATION_ID,
  DD_RUM_CLIENT_TOKEN,
  GIT_COMMIT_HASH,
  IS_DD_RUM_RECORDING_ENABLED,
} from '@config/env';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const CUEBOX_APP_SERVICE = 'cuebox-app';
const DATADOG_SITE = 'datadoghq.com';

const version = GIT_COMMIT_HASH || 'local';

const ERROR_IGNORE_PATTERNS = [
  // This is a known issue with Next.js where the initial props loading is cancelled
  // when a user navigates away from a page before it finishes loading.
  // https://github.com/vercel/next.js/discussions/21073
  /Loading initial props cancelled/i,
  // These are some other Next.js errors that can commonly happen due to navigation.
  /Abort fetching component for route/i,
  /Route did not complete loading/i,

  // This is what we throw intentionally in the `useBeforeUnload` hook to prevent navigation.
  // We don't want to log this as an error.
  /PREVENTED_NAVIGATION/,

  // This is seemingly caused by Microsoft Outlook "Safe Links" feature and doesn't appear to cause any problems.
  // https://github.com/getsentry/sentry-javascript/issues/3440
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
  /Object Not Found Matching Id:\n+, MethodName:\w+, ParamCount:\n+/i,

  // This is a known issue with WebKit (Safari) and fetch requests that are aborted, which log errors in the user's console.
  // https://bugs.webkit.org/show_bug.cgi?id=215771
  /Fetch is aborted/i,

  // DevCycle throws a bunch of errors due to network requests that are not actionable.
  // They have many different types of errors, which are tedious to target individually,
  // but none of them appear to be causing any functional problems.
  /DevCycle/i,

  // Google analytics errors are not actionable, and are commonly thrown by ad blockers.
  /google-analytics/i,

  // This is a recent issue with Meta Pixel that isn't causing any problems.
  // It's unclear why they're attempting to hit a localhost URL, but it's probably an issue on their end.
  // https://developers.facebook.com/community/threads/317050484803752/
  /XHR error GET http:\/\/localhost:12387/i,

  // An error that appears to be thrown by in-app browsers with no known cause.
  // It does not affect performance though.
  // https://developers.facebook.com/community/threads/320013549791141/
  // https://stackoverflow.com/q/72720387/11020515
  /Can't find variable: _AutofillCallbackHandler/i,
];

const URL_IGNORE_PATTERNS = [/google-analytics/i, /devcycle/i];

// Note: this must be triggered in `_app.tsx` to kick off logging.
export const initDatadogLogging = () => {
  // Reference: https://docs.datadoghq.com/logs/log_collection/javascript/#npm
  datadogLogs.init({
    clientToken: DD_LOGS_CLIENT_TOKEN,
    env: DD_ENV,
    forwardConsoleLogs: ['error', 'warn', 'log'],
    forwardErrorsToLogs: true,
    service: CUEBOX_APP_SERVICE,
    sessionSampleRate: 100,
    site: DATADOG_SITE,
    beforeSend: (event) => {
      if (event.type === 'error') {
        if (
          ERROR_IGNORE_PATTERNS.some((pattern) => {
            return (
              pattern.test(event.message) ||
              (event.error?.message && pattern.test(event.error.message))
            );
          })
        ) {
          return false;
        }

        if (
          URL_IGNORE_PATTERNS.some(
            (pattern) => event.http?.url && pattern.test(event.http.url),
          )
        ) {
          return false;
        }
      }
      return true;
    },
    version,
  });

  // These config values were taken from the docs here:
  // https://www.npmjs.com/package/@datadog/browser-rum
  // and our specific applicationId and clientToken were
  // taken from the Datadog dashboard.
  datadogRum.init({
    applicationId: DD_RUM_APPLICATION_ID,
    clientToken: DD_RUM_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env: DD_ENV,
    service: CUEBOX_APP_SERVICE,
    sessionReplaySampleRate: 100, // TODO: we will probably reduce this sample rate if cost becomes an issue.
    sessionSampleRate: 100,
    site: DATADOG_SITE,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    allowedTracingUrls: [
      'http://localhost:8080',
      'https://api.getcuebox.com',
      'https://api.stg.getcuebox.com',
      'https://api.demo.getcuebox.com',
    ],
    beforeSend: (event) => {
      if (event.type === 'error') {
        if (
          ERROR_IGNORE_PATTERNS.some((pattern) =>
            pattern.test(event.error.message),
          )
        ) {
          return false;
        }

        if (
          URL_IGNORE_PATTERNS.some(
            (pattern) =>
              event.error.resource?.url &&
              pattern.test(event.error.resource.url),
          )
        ) {
          return false;
        }
      }
      return true;
    },
    traceContextInjection: 'all',
    version,
  });

  // Selectively enable session replay recording.
  // Toggling this separately is helpful for local dev situations or other environments where we explicitly do _not_ want a replay session.
  // In the future this may be expanded to use more smart checks like isCueboxStaffMember, but for now this works OK.
  if (IS_DD_RUM_RECORDING_ENABLED) {
    datadogRum.startSessionReplayRecording();
  }
};
