// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/season_package.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Int32Value, proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, ListShowsFilter, Money, SeasonPackageStatus, TicketDeliveryMethod } from "../../../common/v1/common_pb.js";
import { Showtime } from "./show_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.SeasonPackage
 */
export const SeasonPackage = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeasonPackage",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "image_asset", kind: "message", T: Asset },
    { no: 8, name: "min_ticket_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "max_ticket_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "is_max_one_ticket_per_show", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "showtimes", kind: "message", T: Showtime, repeated: true },
    { no: 12, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
    { no: 13, name: "sort_order", kind: "message", T: Int32Value },
    { no: 14, name: "stat", kind: "message", T: SeasonPackageStat },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SeasonPackageStat
 */
export const SeasonPackageStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeasonPackageStat",
  () => [
    { no: 1, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(SeasonPackageStatus) },
    { no: 3, name: "min_package_price", kind: "message", T: Money },
    { no: 4, name: "max_package_price", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListSeasonPackagesRequest
 */
export const ListSeasonPackagesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListSeasonPackagesRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListShowsFilter) },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListSeasonPackagesResponse
 */
export const ListSeasonPackagesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListSeasonPackagesResponse",
  () => [
    { no: 1, name: "season_packages", kind: "message", T: SeasonPackage, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetSeasonPackageRequest
 */
export const GetSeasonPackageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetSeasonPackageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetSeasonPackageResponse
 */
export const GetSeasonPackageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetSeasonPackageResponse",
  () => [
    { no: 1, name: "season_package", kind: "message", T: SeasonPackage },
  ],
);

