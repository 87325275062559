import { formAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleHelperText = defineStyle({
  marginTop: 1.5,
});

const baseStyle = definePartsStyle({
  helperText: baseStyleHelperText,
});

export const formTheme = defineMultiStyleConfig({
  baseStyle,
});
