// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/soft_credit.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ConsumerProfile } from "./consumer_profile_pb.js";
import { Money } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.SoftCredit
 */
export const SoftCredit = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SoftCredit",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 6, name: "donation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "recipient", kind: "message", T: ConsumerProfile },
    { no: 8, name: "donor", kind: "message", T: ConsumerProfile },
    { no: 9, name: "amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SoftCreditParams
 */
export const SoftCreditParams = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SoftCreditParams",
  () => [
    { no: 1, name: "soft_credit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recipient_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSoftCreditsRequest
 */
export const ListSoftCreditsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSoftCreditsRequest",
  () => [
    { no: 1, name: "donation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListSoftCreditsResponse
 */
export const ListSoftCreditsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListSoftCreditsResponse",
  () => [
    { no: 1, name: "soft_credits", kind: "message", T: SoftCredit, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSoftCreditRequest
 */
export const GetSoftCreditRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSoftCreditRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSoftCreditResponse
 */
export const GetSoftCreditResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSoftCreditResponse",
  () => [
    { no: 1, name: "soft_credit", kind: "message", T: SoftCredit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSoftCreditsRequest
 */
export const SaveSoftCreditsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSoftCreditsRequest",
  () => [
    { no: 1, name: "donation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "donor_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "soft_credit_params", kind: "message", T: SoftCreditParams, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSoftCreditsResponse
 */
export const SaveSoftCreditsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSoftCreditsResponse",
  () => [
    { no: 1, name: "soft_credits", kind: "message", T: SoftCredit, repeated: true },
  ],
);

