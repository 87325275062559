// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/membership.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Int32Value, proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, BenefitPeriodType, ListMembershipFilter, MembershipStatus, Money, RevenueType, SubscriptionFrequency, SubscriptionStatus } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.Membership
 */
export const Membership = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Membership",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "image_asset", kind: "message", T: Asset },
    { no: 8, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 9, name: "total_amount", kind: "message", T: Money },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(MembershipStatus) },
    { no: 11, name: "sort_order", kind: "message", T: Int32Value },
    { no: 12, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListMembershipsRequest
 */
export const ListMembershipsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListMembershipsRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListMembershipFilter) },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListMembershipsResponse
 */
export const ListMembershipsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListMembershipsResponse",
  () => [
    { no: 1, name: "memberships", kind: "message", T: Membership, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetMembershipRequest
 */
export const GetMembershipRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetMembershipRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetMembershipResponse
 */
export const GetMembershipResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetMembershipResponse",
  () => [
    { no: 1, name: "membership", kind: "message", T: Membership },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MembershipSubscription
 */
export const MembershipSubscription = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MembershipSubscription",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(SubscriptionStatus) },
    { no: 5, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 6, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 7, name: "recurring_amount", kind: "message", T: Money },
    { no: 8, name: "started_at", kind: "message", T: Timestamp },
    { no: 10, name: "membership", kind: "message", T: Membership },
    { no: 11, name: "stat", kind: "message", T: MembershipSubscriptionStat },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MembershipSubscriptionStat
 */
export const MembershipSubscriptionStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MembershipSubscriptionStat",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current_period_starts_at", kind: "message", T: Timestamp },
    { no: 3, name: "current_period_ends_at", kind: "message", T: Timestamp },
    { no: 4, name: "snapshot_benefit_period_type", kind: "enum", T: proto3.getEnumType(BenefitPeriodType) },
    { no: 5, name: "snapshot_benefit_period_duration_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "snapshot_benefit_period_expires_at", kind: "message", T: Timestamp },
    { no: 7, name: "net_payments_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "net_paid_amount", kind: "message", T: Money },
    { no: 9, name: "next_payment_at", kind: "message", T: Timestamp },
    { no: 10, name: "ticket_benefit_current_period_used_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "ticket_benefit_current_period_remaining_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListMembershipSubscriptionsRequest
 */
export const ListMembershipSubscriptionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListMembershipSubscriptionsRequest",
  () => [
    { no: 1, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListMembershipSubscriptionsResponse
 */
export const ListMembershipSubscriptionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListMembershipSubscriptionsResponse",
  () => [
    { no: 1, name: "memberships", kind: "message", T: MembershipSubscription, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetMembershipSubscriptionRequest
 */
export const GetMembershipSubscriptionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetMembershipSubscriptionRequest",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetMembershipSubscriptionResponse
 */
export const GetMembershipSubscriptionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetMembershipSubscriptionResponse",
  () => [
    { no: 1, name: "membership", kind: "message", T: MembershipSubscription },
  ],
);

