// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/discount.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AmountType, DiscountApplyMode, DiscountStatus, Money, ProductType } from "../../../common/v1/common_pb.js";
import { ProductIDAndType } from "./product_pb.js";
import { Membership } from "./membership_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Discount
 */
export const Discount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Discount",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "discount_amount_type", kind: "enum", T: proto3.getEnumType(AmountType) },
    { no: 8, name: "discount_fixed_amount", kind: "message", T: Money },
    { no: 9, name: "discount_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "applied_to_product_types", kind: "enum", T: proto3.getEnumType(ProductType), repeated: true },
    { no: 13, name: "product_id_and_types", kind: "message", T: ProductIDAndType, repeated: true },
    { no: 14, name: "discount_apply_mode", kind: "enum", T: proto3.getEnumType(DiscountApplyMode) },
    { no: 15, name: "internal_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "is_max_usage_count_per_customer_per_show_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "max_usage_count_per_customer_per_show", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "is_max_usage_count_per_consumer_per_product_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "max_usage_count_per_consumer_per_product", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "is_max_order_usage_count_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "max_order_usage_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 22, name: "is_max_usage_count_per_consumer_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "max_usage_count_per_consumer", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 24, name: "availability_starts_at", kind: "message", T: Timestamp },
    { no: 25, name: "availability_ends_at", kind: "message", T: Timestamp },
    { no: 26, name: "stat", kind: "message", T: DiscountStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DiscountStat
 */
export const DiscountStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DiscountStat",
  () => [
    { no: 1, name: "discount_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "orders_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(DiscountStatus) },
    { no: 4, name: "eligible_memberships", kind: "message", T: Membership, repeated: true },
    { no: 5, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "show_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDiscountsRequest
 */
export const ListDiscountsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDiscountsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDiscountsResponse
 */
export const ListDiscountsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDiscountsResponse",
  () => [
    { no: 1, name: "discounts", kind: "message", T: Discount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDiscountRequest
 */
export const CreateDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDiscountRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "discount_amount_type", kind: "enum", T: proto3.getEnumType(AmountType) },
    { no: 4, name: "discount_fixed_amount", kind: "message", T: Money },
    { no: 5, name: "discount_percentage", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "applied_to_product_types", kind: "enum", T: proto3.getEnumType(ProductType), repeated: true },
    { no: 9, name: "product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "discount_apply_mode", kind: "enum", T: proto3.getEnumType(DiscountApplyMode) },
    { no: 11, name: "internal_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "is_max_usage_count_per_customer_per_show_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "max_usage_count_per_customer_per_show", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "is_max_usage_count_per_consumer_per_product_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "max_usage_count_per_consumer_per_product", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "is_max_order_usage_count_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "max_order_usage_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "is_max_usage_count_per_consumer_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "max_usage_count_per_consumer", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "availability_starts_at", kind: "message", T: Timestamp },
    { no: 21, name: "availability_ends_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDiscountResponse
 */
export const CreateDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDiscountResponse",
  () => [
    { no: 1, name: "discount", kind: "message", T: Discount },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDiscountRequest
 */
export const GetDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDiscountRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDiscountResponse
 */
export const GetDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDiscountResponse",
  () => [
    { no: 1, name: "discount", kind: "message", T: Discount },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDiscountRequest
 */
export const SaveDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDiscountRequest",
  () => [
    { no: 1, name: "discount", kind: "message", T: Discount },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDiscountResponse
 */
export const SaveDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDiscountResponse",
  () => [
    { no: 1, name: "discount", kind: "message", T: Discount },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveDiscountRequest
 */
export const ArchiveDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveDiscountRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveDiscountResponse
 */
export const ArchiveDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveDiscountResponse",
  [],
);

