import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
});

const variantCard = definePartsStyle({
  thead: {
    bg: 'backgrounds.lighter',
  },
  th: {
    color: 'gray.600',
    borderBottomWidth: '1px',
    borderColor: 'borders.base',
    paddingX: 3,
    paddingY: 2.5,
    ...numericStyles,
  },
  td: {
    borderBottomWidth: '1px',
    borderColor: 'borders.base',
    paddingX: 3,
    paddingY: 2.5,
    height: 12,
    fontSize: { base: 'sm', md: 'md' },
    ...numericStyles,
  },
  tr: {
    _last: {
      td: {
        borderBottomWidth: 0,
      },
    },
  },
});

const baseStyle = definePartsStyle({
  th: {
    fontWeight: 600,
  },
});

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    card: variantCard,
  },
});
