import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const switchVariantCuebox = definePartsStyle({
  track: {
    _checked: {
      bg: 'primary.darker',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  variants: {
    cuebox: switchVariantCuebox,
  },
  defaultProps: {
    variant: 'cuebox',
  },
});
