// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/show.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, EmailType, ListShowsFilter, Money, ShowOnlineVisibility, ShowStatus, ShowtimeRelativeInterval, TicketDeliveryMethod } from "../../../common/v1/common_pb.js";
import { Tag } from "./tag_pb.js";
import { TicketSalesStat } from "./ticket_sales_stat_pb.js";
import { ShowtimeLocation } from "./showtime_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.ShowEmail
 */
export const ShowEmail = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShowEmail",
  () => [
    { no: 1, name: "is_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "json_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "html_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Show
 */
export const Show = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Show",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "subtitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "image", kind: "message", T: Asset },
    { no: 10, name: "online_ticket_sales_start_at", kind: "message", T: Timestamp },
    { no: 11, name: "online_ticket_sales_end_relative_to", kind: "enum", T: proto3.getEnumType(ShowtimeRelativeInterval) },
    { no: 12, name: "online_ticket_sales_end_offset", kind: "message", T: Duration },
    { no: 13, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
    { no: 14, name: "order_confirmation_email", kind: "message", T: ShowEmail },
    { no: 15, name: "pre_show_email", kind: "message", T: ShowEmail },
    { no: 16, name: "post_show_email", kind: "message", T: ShowEmail },
    { no: 17, name: "online_visibility", kind: "enum", T: proto3.getEnumType(ShowOnlineVisibility) },
    { no: 18, name: "gross_sales_goal_amount", kind: "message", T: Money },
    { no: 19, name: "tickets_sold_goal_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "add_on_product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 21, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 22, name: "box_office_ticket_sales_start_at", kind: "message", T: Timestamp },
    { no: 23, name: "box_office_ticket_sales_end_relative_to", kind: "enum", T: proto3.getEnumType(ShowtimeRelativeInterval) },
    { no: 24, name: "box_office_ticket_sales_end_offset", kind: "message", T: Duration },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ShowStat
 */
export const ShowStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShowStat",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_ticket_sales_stat", kind: "message", T: TicketSalesStat },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(ShowStatus) },
    { no: 4, name: "first_showtime_date", kind: "message", T: Timestamp },
    { no: 5, name: "first_showtime_timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_showtime_date", kind: "message", T: Timestamp },
    { no: 7, name: "last_showtime_timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "duration", kind: "message", T: Duration },
    { no: 9, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "min_ticket_price", kind: "message", T: Money },
    { no: 11, name: "max_ticket_price", kind: "message", T: Money },
    { no: 12, name: "box_office_status", kind: "enum", T: proto3.getEnumType(ShowStatus) },
    { no: 13, name: "showtime_locations", kind: "message", T: ShowtimeLocation, repeated: true },
    { no: 14, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ShowDetail
 */
export const ShowDetail = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShowDetail",
  () => [
    { no: 1, name: "show", kind: "message", T: Show },
    { no: 2, name: "stat", kind: "message", T: ShowStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ShowIDAndName
 */
export const ShowIDAndName = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShowIDAndName",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowsRequest
 */
export const ListShowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowsRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListShowsFilter) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowsResponse
 */
export const ListShowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowsResponse",
  () => [
    { no: 1, name: "show_details", kind: "message", T: ShowDetail, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowIDsAndNamesRequest
 */
export const ListShowIDsAndNamesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowIDsAndNamesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowIDsAndNamesResponse
 */
export const ListShowIDsAndNamesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowIDsAndNamesResponse",
  () => [
    { no: 1, name: "show_ids_and_names", kind: "message", T: ShowIDAndName, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetShowRequest
 */
export const GetShowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetShowRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetShowResponse
 */
export const GetShowResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetShowResponse",
  () => [
    { no: 1, name: "show_detail", kind: "message", T: ShowDetail },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateShowRequest
 */
export const CreateShowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateShowRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateShowResponse
 */
export const CreateShowResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateShowResponse",
  () => [
    { no: 1, name: "show_detail", kind: "message", T: ShowDetail },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveShowRequest
 */
export const SaveShowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveShowRequest",
  () => [
    { no: 1, name: "show", kind: "message", T: Show },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveShowResponse
 */
export const SaveShowResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveShowResponse",
  () => [
    { no: 1, name: "show_detail", kind: "message", T: ShowDetail },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveShowRequest
 */
export const ArchiveShowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveShowRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveShowResponse
 */
export const ArchiveShowResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveShowResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelShowRequest
 */
export const CancelShowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelShowRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelShowResponse
 */
export const CancelShowResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelShowResponse",
  () => [
    { no: 1, name: "did_cancel", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "did_not_cancel_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SendPrePostShowEmailPreviewRequest
 */
export const SendPrePostShowEmailPreviewRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SendPrePostShowEmailPreviewRequest",
  () => [
    { no: 1, name: "email_type", kind: "enum", T: proto3.getEnumType(EmailType) },
    { no: 2, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "html_email_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SendPrePostShowEmailPreviewResponse
 */
export const SendPrePostShowEmailPreviewResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SendPrePostShowEmailPreviewResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.SendAttendeeEmailRequest
 */
export const SendAttendeeEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SendAttendeeEmailRequest",
  () => [
    { no: 1, name: "is_preview", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "preview_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "additional_email_addresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "email_subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "html_email_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SendAttendeeEmailResponse
 */
export const SendAttendeeEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SendAttendeeEmailResponse",
  [],
);

