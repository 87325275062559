import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const toolipBaseStyle = defineStyle({
  px: 3,
  py: 2,
  borderRadius: 'md',
  maxW: 56,
});

export const tooltipTheme = defineStyleConfig({
  baseStyle: toolipBaseStyle,
});
