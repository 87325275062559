import type { Message } from '@bufbuild/protobuf';
import { CueboxStaffService } from '@pb/cuebox/services/staff/v1/staff_connect';
import { type StaffServiceContextApi } from '../hooks/useStaffService';
import { StaffServiceContext } from '../providers/StaffServiceProvider';
import createConnectFetcher, {
  type ConnectFetcherArgs,
} from '../wrappers/createConnectFetcher';
import createConnectTrigger, {
  type ConnectTriggerArgs,
} from '../wrappers/createConnectTrigger';

export const createStaffFetcher = <
  Params extends Message<Params>,
  FRes extends Message<FRes>,
>(
  args: ConnectFetcherArgs<typeof CueboxStaffService, Params, FRes>,
) =>
  createConnectFetcher<
    typeof CueboxStaffService,
    StaffServiceContextApi,
    Params,
    FRes
  >(args, {
    context: StaffServiceContext,
    service: CueboxStaffService,
  });

export const createStaffTrigger = <
  Params extends Message<Params>,
  FRes extends Message<FRes>,
>(
  args: ConnectTriggerArgs<typeof CueboxStaffService, Params, FRes>,
) =>
  createConnectTrigger<
    typeof CueboxStaffService,
    StaffServiceContextApi,
    Params,
    FRes
  >(args, {
    context: StaffServiceContext,
    service: CueboxStaffService,
  });
