// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/campaign.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Money } from "../../../common/v1/common_pb.js";

/**
 * @generated from enum cuebox.services.staff.v1.CampaignStatus
 */
export const CampaignStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.CampaignStatus",
  [
    {no: 0, name: "CAMPAIGN_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CAMPAIGN_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "CAMPAIGN_STATUS_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CampaignStat
 */
export const CampaignStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CampaignStat",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_raised_amount", kind: "message", T: Money },
    { no: 3, name: "outstanding_pledge_amount", kind: "message", T: Money },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(CampaignStatus) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Campaign
 */
export const Campaign = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Campaign",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "goal_amount", kind: "message", T: Money },
    { no: 7, name: "end_date", kind: "message", T: Timestamp },
    { no: 8, name: "status", kind: "enum", T: proto3.getEnumType(CampaignStatus) },
    { no: 9, name: "custom_email_content_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "custom_email_content_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "is_donation_recognition_name_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCampaignRequest
 */
export const CreateCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCampaignRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "goal_amount", kind: "message", T: Money },
    { no: 4, name: "end_date", kind: "message", T: Timestamp },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(CampaignStatus) },
    { no: 6, name: "custom_email_content_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "custom_email_content_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "is_donation_recognition_name_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCampaignResponse
 */
export const CreateCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCampaignResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: Campaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCampaignsRequest
 */
export const ListCampaignsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCampaignsRequest",
  () => [
    { no: 1, name: "statuses", kind: "enum", T: proto3.getEnumType(CampaignStatus), repeated: true },
    { no: 2, name: "should_include_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCampaignsResponse
 */
export const ListCampaignsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCampaignsResponse",
  () => [
    { no: 1, name: "campaigns", kind: "message", T: Campaign, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCampaignRequest
 */
export const GetCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCampaignRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCampaignResponse
 */
export const GetCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCampaignResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: Campaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCampaignRequest
 */
export const SaveCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCampaignRequest",
  () => [
    { no: 1, name: "campaign", kind: "message", T: Campaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCampaignResponse
 */
export const SaveCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCampaignResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: Campaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCampaignRequest
 */
export const ArchiveCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCampaignRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCampaignResponse
 */
export const ArchiveCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCampaignResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCampaignStatsRequest
 */
export const ListCampaignStatsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCampaignStatsRequest",
  () => [
    { no: 1, name: "statuses", kind: "enum", T: proto3.getEnumType(CampaignStatus), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCampaignStatsResponse
 */
export const ListCampaignStatsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCampaignStatsResponse",
  () => [
    { no: 1, name: "stats", kind: "message", T: CampaignStat, repeated: true },
  ],
);

