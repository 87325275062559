// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/merchandise.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Int32Value, proto3 } from "@bufbuild/protobuf";
import { Asset, Money, RevenueType, SalesChannel } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.Merchandise
 */
export const Merchandise = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Merchandise",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_asset", kind: "message", T: Asset },
    { no: 5, name: "sort_order", kind: "message", T: Int32Value },
    { no: 6, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 7, name: "sales_channels", kind: "enum", T: proto3.getEnumType(SalesChannel), repeated: true },
    { no: 8, name: "is_on_sale", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "variant_groups", kind: "message", T: VariantGroup, repeated: true },
    { no: 10, name: "merchandise_skus", kind: "message", T: MerchandiseSku, repeated: true },
    { no: 11, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "min_merchandise_sku_price", kind: "message", T: Money },
    { no: 13, name: "max_merchandise_sku_price", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MerchandiseSku
 */
export const MerchandiseSku = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MerchandiseSku",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "merchandise_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "should_track_inventory", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "inventory_count_read_only", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "price", kind: "message", T: Money },
    { no: 8, name: "image_asset", kind: "message", T: Asset },
    { no: 9, name: "does_require_fulfillment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "variant_group_values", kind: "message", T: MerchandiseSkuVariantValue, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.VariantGroup
 */
export const VariantGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.VariantGroup",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "values", kind: "message", T: VariantValue, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.VariantValue
 */
export const VariantValue = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.VariantValue",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MerchandiseSkuVariantValue
 */
export const MerchandiseSkuVariantValue = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MerchandiseSkuVariantValue",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "variant_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "variant_value_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListMerchandisesRequest
 */
export const ListMerchandisesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListMerchandisesRequest",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListMerchandisesResponse
 */
export const ListMerchandisesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListMerchandisesResponse",
  () => [
    { no: 1, name: "merchandises", kind: "message", T: Merchandise, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetMerchandiseRequest
 */
export const GetMerchandiseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetMerchandiseRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetMerchandiseResponse
 */
export const GetMerchandiseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetMerchandiseResponse",
  () => [
    { no: 1, name: "merchandise", kind: "message", T: Merchandise },
  ],
);

