import type {
  GetUserDetailsRequest,
  GetUserDetailsResponse,
} from '@pb/cuebox/services/staff/v1/user_pb';
import { createStaffFetcher } from '@utils/protobuf';

const { useFetcher: useUserDetails } = createStaffFetcher<
  GetUserDetailsRequest,
  GetUserDetailsResponse
>({
  method: (service) => service.methods.getUserDetails,
  fetcher: ({ client, params }) => {
    return client.getUserDetails(params);
  },
});

const useCueboxUser = (props: { shouldFetch: boolean }) => {
  const { shouldFetch } = props;
  const { data, error, isLoading, isValidating } = useUserDetails({
    shouldFetch,
    useDefaultErrorHandling: false,
  });

  return {
    error,
    isLoading,
    isValidating,
    user: data,
  };
};

export default useCueboxUser;
