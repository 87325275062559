// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/product.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { ProductType } from "../../../common/v1/common_pb.js";
import { Merchandise } from "./merchandise_pb.js";
import { Show, Showtime } from "./show_pb.js";
import { Membership } from "./membership_pb.js";
import { SeasonPackage } from "./season_package_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.Product
 */
export const Product = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Product",
  () => [
    { no: 1, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_type", kind: "enum", T: proto3.getEnumType(ProductType) },
    { no: 101, name: "merchandise", kind: "message", T: Merchandise, oneof: "product" },
    { no: 102, name: "show", kind: "message", T: Show, oneof: "product" },
    { no: 103, name: "membership", kind: "message", T: Membership, oneof: "product" },
    { no: 104, name: "season_package", kind: "message", T: SeasonPackage, oneof: "product" },
    { no: 105, name: "showtime", kind: "message", T: Showtime, oneof: "product" },
  ],
);

