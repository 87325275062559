import type { Context } from 'react';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  Message,
  MethodInfo,
  PartialMessage,
  ServiceType,
} from '@bufbuild/protobuf';
import type { PromiseClient, Transport } from '@connectrpc/connect';
import type { AppContextApi } from '@hooks/staff/useAppContext';

export const enum ApiHeaderKeys {
  ORG_ID = 'Cuebox-Org-Id',
}

export const enum ConsumerApiHeaderKeys {
  CONSUMER_API_KEY = 'Cuebox-Consumer-Api-Key',
}

export type Headers = Record<string, string>;

export type KeyPayload<Params extends Message<Params>> = {
  userId: string;
  headers: Record<string, unknown>;
  method: MethodInfo;
  params?: MessageProps<Params>;
  service: string;
};

export type DeserializedKey<
  Service extends ServiceType,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
> = {
  client: PromiseClient<Service>;
  headers: Headers;
  method: MethodInfo<Params, FRes>;
  params: MessageProps<Params>;
  service: string;
};

export type MessageProps<P extends Message<P>> = PartialMessage<P>;

export type GenerateKey<
  Service extends ServiceType,
  Params extends Message<Params>,
> = (service: Service, props: MessageProps<Params>) => MethodInfo | string;

export type FetcherOpts<Service extends ServiceType> = {
  client: PromiseClient<Service>;
  context?: AppContextApi;
};

export interface ServiceArgs<Service extends ServiceType, ContextApi> {
  context: Context<ContextApi>;
  service: Service;
}

export type FetcherOrMutatorCallback<
  Service extends ServiceType,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
> = (props: DeserializedKey<Service, Params, FRes>) => Promise<FRes>;

export type GetMethod<Service> = (service: Service) => MethodInfo;

export type RelevantMutationConfigProps<FRes> = Pick<
  SWRMutationConfiguration<FRes, Error>,
  'onSuccess' | 'onError'
>;

export type BaseContextApi<Service extends ServiceType> = {
  transport: Transport;
  client: PromiseClient<Service>;
};
