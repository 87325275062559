import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const checkboxVariantCard = definePartsStyle({
  container: defineStyle({
    borderWidth: '1px',
    borderRadius: 'lg',
    paddingX: 3.5,
    paddingY: 2.5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transitionDuration: 'normal',
    transitionProperty: 'border-color, box-shadow',
    _checked: {
      borderColor: 'primary.base',
      boxShadow: 'selected',
    },
  }),
  label: defineStyle({
    marginLeft: 3,
  }),
});

const checkboxVariantTag = definePartsStyle(({ colorScheme }) => ({
  container: {
    borderWidth: '1px',
    borderRadius: 'md',
    backgroundColor: 'white',
    borderColor: 'dark.400',
    color: 'dark.100',
    fontWeight: 'medium',
    paddingX: 2.5,
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 7,
    transitionDuration: 'normal',
    transitionProperty: 'border-color, box-shadow',
    cursor: 'pointer',
    _checked: {
      shadow: 'sm',
      borderColor: `${colorScheme}.100`,
      backgroundColor: `${colorScheme}.50`,
      color: `${colorScheme}.500`,
    },
  },
  label: defineStyle({
    marginLeft: 0,
    fontSize: 'sm',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
  }),
  control: defineStyle({
    display: 'none',
  }),
}));

export const checkboxTheme = defineMultiStyleConfig({
  variants: {
    card: checkboxVariantCard,
    tag: checkboxVariantTag,
  },
});
