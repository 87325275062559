// Consumer components use a 10 pixel scale instead of the standard Chakra em sizes.
// But only for desktop cards; many mobile views still use the defaults.
export const consumer = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10]
  .map((n) => [n, `${n * 10}px`])
  .reduce(
    (res, [key, value]) => ({
      ...res,
      [key]: value,
    }),
    {},
  );

export const space = {
  consumer,
};

export const sizes = {
  consumer,
};
