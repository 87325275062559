// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/donation_form.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, Money } from "../../../common/v1/common_pb.js";

/**
 * @generated from enum cuebox.services.staff.v1.DonationFormStatus
 */
export const DonationFormStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.DonationFormStatus",
  [
    {no: 0, name: "DONATION_FORM_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DONATION_FORM_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "DONATION_FORM_STATUS_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationForm
 */
export const DonationForm = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationForm",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "background_image", kind: "message", T: Asset },
    { no: 7, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "suggested_amounts", kind: "message", T: Money, repeated: true },
    { no: 9, name: "is_phone_number_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "is_tribute_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "is_donate_anonymously_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "is_progress_bar_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDonationFormRequest
 */
export const CreateDonationFormRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDonationFormRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "background_image", kind: "message", T: Asset },
    { no: 4, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "suggested_amounts", kind: "message", T: Money, repeated: true },
    { no: 6, name: "is_phone_number_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_tribute_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_donate_anonymously_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_progress_bar_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDonationFormResponse
 */
export const CreateDonationFormResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDonationFormResponse",
  () => [
    { no: 1, name: "donation_form", kind: "message", T: DonationForm },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDonationFormsRequest
 */
export const ListDonationFormsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDonationFormsRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDonationFormsResponse
 */
export const ListDonationFormsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDonationFormsResponse",
  () => [
    { no: 1, name: "donation_forms", kind: "message", T: DonationForm, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDonationFormRequest
 */
export const GetDonationFormRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonationFormRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDonationFormResponse
 */
export const GetDonationFormResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDonationFormResponse",
  () => [
    { no: 1, name: "donation_form", kind: "message", T: DonationForm },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDonationFormRequest
 */
export const SaveDonationFormRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDonationFormRequest",
  () => [
    { no: 1, name: "donation_form", kind: "message", T: DonationForm },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDonationFormResponse
 */
export const SaveDonationFormResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDonationFormResponse",
  () => [
    { no: 1, name: "donation_form", kind: "message", T: DonationForm },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveDonationFormRequest
 */
export const ArchiveDonationFormRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveDonationFormRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveDonationFormResponse
 */
export const ArchiveDonationFormResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveDonationFormResponse",
  [],
);

