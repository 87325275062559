import { CUEBOX_CONSUMER_API_KEY } from '@config/env';
import type { Interceptor } from '@connectrpc/connect';
import { ApiHeaderKeys, ConsumerApiHeaderKeys } from '../types';

export const setRequiredHeaders =
  ({ orgId }: { orgId: string }): Interceptor =>
  (next) =>
  (req) => {
    req.header.set(
      ConsumerApiHeaderKeys.CONSUMER_API_KEY,
      CUEBOX_CONSUMER_API_KEY,
    );
    req.header.set(ApiHeaderKeys.ORG_ID, orgId);

    return next(req);
  };
