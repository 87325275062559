// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/recent_subscription.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { SubscriptionFrequency } from "../../../common/v1/common_pb.js";

/**
 * @generated from enum cuebox.services.staff.v1.RecentSubscriptionItemType
 */
export const RecentSubscriptionItemType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.RecentSubscriptionItemType",
  [
    {no: 0, name: "RECENT_SUBSCRIPTION_ITEM_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "RECENT_SUBSCRIPTION_ITEM_TYPE_SEASON_PACKAGE", localName: "SEASON_PACKAGE"},
    {no: 2, name: "RECENT_SUBSCRIPTION_ITEM_TYPE_DONATION_SUBSCRIPTION", localName: "DONATION_SUBSCRIPTION"},
    {no: 3, name: "RECENT_SUBSCRIPTION_ITEM_TYPE_MEMBERSHIP_SUBSCRIPTION", localName: "MEMBERSHIP_SUBSCRIPTION"},
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RecentSeasonPackage
 */
export const RecentSeasonPackage = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RecentSeasonPackage",
  () => [
    { no: 1, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RecentDonationSubscription
 */
export const RecentDonationSubscription = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RecentDonationSubscription",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RecentMembershipSubscription
 */
export const RecentMembershipSubscription = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RecentMembershipSubscription",
  () => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "membership_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "membership_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RecentSubscriptionItem
 */
export const RecentSubscriptionItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RecentSubscriptionItem",
  () => [
    { no: 1, name: "recent_subscription_at", kind: "message", T: Timestamp },
    { no: 2, name: "recent_subscription_types", kind: "enum", T: proto3.getEnumType(RecentSubscriptionItemType), repeated: true },
    { no: 101, name: "season_package", kind: "message", T: RecentSeasonPackage, oneof: "item" },
    { no: 102, name: "donation_subscription", kind: "message", T: RecentDonationSubscription, oneof: "item" },
    { no: 103, name: "membership_subscription", kind: "message", T: RecentMembershipSubscription, oneof: "item" },
  ],
);

