// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/usage_limit.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.TicketTypeUsageLimit
 */
export const TicketTypeUsageLimit = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketTypeUsageLimit",
  () => [
    { no: 1, name: "membership_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_benefit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ticket_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_for_all_shows", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "max_count_per_show", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "total_max_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "applied_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "total_applied_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "applied_tickets", kind: "message", T: UsageLimitAppliedTicket, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UsageLimitAppliedTicket
 */
export const UsageLimitAppliedTicket = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UsageLimitAppliedTicket",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketTypeUsageLimitsRequest
 */
export const ListTicketTypeUsageLimitsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketTypeUsageLimitsRequest",
  () => [
    { no: 1, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketTypeUsageLimitsResponse
 */
export const ListTicketTypeUsageLimitsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketTypeUsageLimitsResponse",
  () => [
    { no: 1, name: "ticket_type_usage_limits", kind: "message", T: TicketTypeUsageLimit, repeated: true },
  ],
);

