// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/order.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { StaffMember } from "./staff_member_pb.js";
import { ConsumerProfile } from "./consumer_profile_pb.js";
import { AcknowledgmentStatus, Address, Asset, FulfillmentStatus, Money, OrderItemPaymentStatus, OrderItemType, OrderPaymentStatus, PaymentMethod, PaymentMethodType, PhoneNumber, RevenueType, SalesChannel, SubscriptionFrequency, TicketBookedStatus, TicketDeliveryMethod, TicketDesignTemplateGenerationMode, TicketPrintStatus, TransactionOrderItemAction, TransactionStatus, TransactionType, Tribute } from "../../../common/v1/common_pb.js";
import { ContributedRevenueAttributes } from "./contributed_revenue_attributes_pb.js";
import { Show } from "./show_pb.js";
import { Showtime } from "./showtime_pb.js";
import { TicketType } from "./ticket_type_pb.js";
import { PriceLevel } from "./price_level_pb.js";
import { Seat } from "./seat_pb.js";
import { Membership } from "./membership_pb.js";
import { Merchandise } from "./merchandise_pb.js";
import { SoftCreditParams } from "./soft_credit_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Order
 */
export const Order = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Order",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "created_by_staff_member", kind: "message", T: StaffMember },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "consumer_profile", kind: "message", T: ConsumerProfile },
    { no: 6, name: "external_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 8, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderPaymentStatus) },
    { no: 9, name: "payment_method", kind: "message", T: PaymentMethod },
    { no: 10, name: "billing_info", kind: "message", T: BillingInfo },
    { no: 11, name: "shipping_info", kind: "message", T: ShippingInfo },
    { no: 12, name: "order_items", kind: "message", T: OrderItem, repeated: true },
    { no: 13, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 14, name: "transaction_order_items", kind: "message", T: TransactionOrderItem, repeated: true },
    { no: 15, name: "subtotal", kind: "message", T: Money },
    { no: 16, name: "discount", kind: "message", T: Money },
    { no: 17, name: "fee", kind: "message", T: Money },
    { no: 18, name: "refund", kind: "message", T: Money },
    { no: 19, name: "net", kind: "message", T: Money },
    { no: 20, name: "total", kind: "message", T: Money },
    { no: 21, name: "ticket_subtotal", kind: "message", T: Money },
    { no: 22, name: "total_ticket_revenue", kind: "message", T: Money },
    { no: 23, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 24, name: "earned_subtotal", kind: "message", T: Money },
    { no: 25, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "stat", kind: "message", T: OrderStat },
    { no: 27, name: "applied_discounts", kind: "message", T: AppliedDiscount, repeated: true },
    { no: 28, name: "fulfillments", kind: "message", T: Fulfillment, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.OrderStat
 */
export const OrderStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.OrderStat",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_delivery_method", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod) },
    { no: 3, name: "order_item_types", kind: "enum", T: proto3.getEnumType(OrderItemType), repeated: true },
    { no: 4, name: "order_at", kind: "message", T: Timestamp },
    { no: 5, name: "fulfillment_status", kind: "enum", T: proto3.getEnumType(FulfillmentStatus) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AppliedDiscount
 */
export const AppliedDiscount = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AppliedDiscount",
  () => [
    { no: 1, name: "discount_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Transaction
 */
export const Transaction = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Transaction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "transaction_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(TransactionStatus) },
    { no: 6, name: "type", kind: "enum", T: proto3.getEnumType(TransactionType) },
    { no: 7, name: "payment_method", kind: "message", T: PaymentMethod },
    { no: 8, name: "billing_info", kind: "message", T: BillingInfo },
    { no: 9, name: "subtotal", kind: "message", T: Money },
    { no: 10, name: "discount", kind: "message", T: Money },
    { no: 11, name: "fee", kind: "message", T: Money },
    { no: 12, name: "refund", kind: "message", T: Money },
    { no: 13, name: "net", kind: "message", T: Money },
    { no: 14, name: "total", kind: "message", T: Money },
    { no: 15, name: "ticket_subtotal", kind: "message", T: Money },
    { no: 16, name: "total_ticket_revenue", kind: "message", T: Money },
    { no: 17, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 18, name: "earned_subtotal", kind: "message", T: Money },
    { no: 19, name: "applied_discounts", kind: "message", T: AppliedDiscount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TransactionStat
 */
export const TransactionStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TransactionStat",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.TransactionOrderItem
 */
export const TransactionOrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TransactionOrderItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "action", kind: "enum", T: proto3.getEnumType(TransactionOrderItemAction) },
    { no: 7, name: "subtotal", kind: "message", T: Money },
    { no: 8, name: "discount", kind: "message", T: Money },
    { no: 9, name: "fee", kind: "message", T: Money },
    { no: 10, name: "refund", kind: "message", T: Money },
    { no: 11, name: "net", kind: "message", T: Money },
    { no: 12, name: "total", kind: "message", T: Money },
    { no: 13, name: "ticket_subtotal", kind: "message", T: Money },
    { no: 14, name: "total_ticket_revenue", kind: "message", T: Money },
    { no: 15, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 16, name: "earned_subtotal", kind: "message", T: Money },
    { no: 17, name: "applied_discounts", kind: "message", T: AppliedDiscount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.OrderItem
 */
export const OrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.OrderItem",
  () => [
    { no: 101, name: "donation_item", kind: "message", T: DonationItem, oneof: "item" },
    { no: 102, name: "ticket_item", kind: "message", T: TicketItem, oneof: "item" },
    { no: 103, name: "season_package_item", kind: "message", T: SeasonPackageItem, oneof: "item" },
    { no: 104, name: "membership_item", kind: "message", T: MembershipItem, oneof: "item" },
    { no: 105, name: "merchandise_item", kind: "message", T: MerchandiseItem, oneof: "item" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DonationItem
 */
export const DonationItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DonationItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "donation_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "match_company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 8, name: "donation_at", kind: "message", T: Timestamp },
    { no: 9, name: "external_donation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 11, name: "subtotal", kind: "message", T: Money },
    { no: 12, name: "discount", kind: "message", T: Money },
    { no: 13, name: "fee", kind: "message", T: Money },
    { no: 14, name: "refund", kind: "message", T: Money },
    { no: 15, name: "net", kind: "message", T: Money },
    { no: 16, name: "total", kind: "message", T: Money },
    { no: 17, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 18, name: "earned_subtotal", kind: "message", T: Money },
    { no: 19, name: "pledge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "pledge_installment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "contributed_revenue_attributes", kind: "message", T: ContributedRevenueAttributes },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketItem
 */
export const TicketItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "show", kind: "message", T: Show },
    { no: 5, name: "showtime", kind: "message", T: Showtime },
    { no: 6, name: "ticket_type", kind: "message", T: TicketType },
    { no: 7, name: "price_level", kind: "message", T: PriceLevel },
    { no: 8, name: "ticket_delivery_method", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod) },
    { no: 9, name: "ticket_print_status", kind: "enum", T: proto3.getEnumType(TicketPrintStatus) },
    { no: 10, name: "booked_status", kind: "enum", T: proto3.getEnumType(TicketBookedStatus) },
    { no: 11, name: "subtotal", kind: "message", T: Money },
    { no: 12, name: "discount", kind: "message", T: Money },
    { no: 13, name: "fee", kind: "message", T: Money },
    { no: 14, name: "refund", kind: "message", T: Money },
    { no: 15, name: "net", kind: "message", T: Money },
    { no: 16, name: "total", kind: "message", T: Money },
    { no: 17, name: "ticket_subtotal", kind: "message", T: Money },
    { no: 19, name: "total_ticket_revenue", kind: "message", T: Money },
    { no: 20, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 21, name: "earned_subtotal", kind: "message", T: Money },
    { no: 22, name: "seat", kind: "message", T: Seat },
    { no: 23, name: "applied_discounts", kind: "message", T: AppliedDiscount, repeated: true },
    { no: 24, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 25, name: "season_package_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "checked_in_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageItem
 */
export const SeasonPackageItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "season_package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "season_package_image_asset", kind: "message", T: Asset },
    { no: 7, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "stat", kind: "message", T: SeasonPackageItemStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageItemStat
 */
export const SeasonPackageItemStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageItemStat",
  () => [
    { no: 1, name: "season_package_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_types", kind: "message", T: TicketType, repeated: true },
    { no: 3, name: "show_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "ticket_print_status", kind: "enum", T: proto3.getEnumType(TicketPrintStatus) },
    { no: 5, name: "subtotal", kind: "message", T: Money },
    { no: 6, name: "discount", kind: "message", T: Money },
    { no: 7, name: "fee", kind: "message", T: Money },
    { no: 8, name: "refund", kind: "message", T: Money },
    { no: 9, name: "net", kind: "message", T: Money },
    { no: 10, name: "total", kind: "message", T: Money },
    { no: 11, name: "ticket_subtotal", kind: "message", T: Money },
    { no: 12, name: "total_ticket_revenue", kind: "message", T: Money },
    { no: 13, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 14, name: "earned_subtotal", kind: "message", T: Money },
    { no: 15, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 16, name: "applied_discounts", kind: "message", T: AppliedDiscount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipItem
 */
export const MembershipItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 5, name: "membership", kind: "message", T: Membership },
    { no: 6, name: "membership_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "contributed_revenue_attributes", kind: "message", T: ContributedRevenueAttributes },
    { no: 8, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 9, name: "subtotal", kind: "message", T: Money },
    { no: 10, name: "discount", kind: "message", T: Money },
    { no: 11, name: "fee", kind: "message", T: Money },
    { no: 12, name: "refund", kind: "message", T: Money },
    { no: 13, name: "net", kind: "message", T: Money },
    { no: 14, name: "total", kind: "message", T: Money },
    { no: 15, name: "ticket_subtotal", kind: "message", T: Money },
    { no: 16, name: "total_ticket_revenue", kind: "message", T: Money },
    { no: 17, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 18, name: "earned_subtotal", kind: "message", T: Money },
    { no: 19, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MerchandiseItem
 */
export const MerchandiseItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MerchandiseItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "merchandise_sku_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "merchandise", kind: "message", T: Merchandise },
    { no: 6, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 7, name: "fulfillment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "fulfillment_status", kind: "enum", T: proto3.getEnumType(FulfillmentStatus) },
    { no: 9, name: "subtotal", kind: "message", T: Money },
    { no: 10, name: "discount", kind: "message", T: Money },
    { no: 11, name: "fee", kind: "message", T: Money },
    { no: 12, name: "refund", kind: "message", T: Money },
    { no: 13, name: "net", kind: "message", T: Money },
    { no: 14, name: "total", kind: "message", T: Money },
    { no: 15, name: "ticket_subtotal", kind: "message", T: Money },
    { no: 16, name: "total_ticket_revenue", kind: "message", T: Money },
    { no: 17, name: "contributed_subtotal", kind: "message", T: Money },
    { no: 18, name: "earned_subtotal", kind: "message", T: Money },
    { no: 19, name: "applied_discounts", kind: "message", T: AppliedDiscount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.BillingInfo
 */
export const BillingInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.BillingInfo",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone_number", kind: "message", T: PhoneNumber },
    { no: 4, name: "address", kind: "message", T: Address },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ShippingInfo
 */
export const ShippingInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShippingInfo",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone_number", kind: "message", T: PhoneNumber },
    { no: 4, name: "address", kind: "message", T: Address },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PaymentInfo
 */
export const PaymentInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PaymentInfo",
  () => [
    { no: 1, name: "payment_method_type", kind: "enum", T: proto3.getEnumType(PaymentMethodType) },
    { no: 2, name: "tilled_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "finix_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "finix_fraud_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "finix_device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "finix_billing_postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "check_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "in_kind_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "bank_transfer_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "other_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Donation
 */
export const Donation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Donation",
  () => [
    { no: 1, name: "donation_item", kind: "message", T: DonationItem },
    { no: 2, name: "order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetOrderItemRequest
 */
export const GetOrderItemRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetOrderItemRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetOrderItemResponse
 */
export const GetOrderItemResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetOrderItemResponse",
  () => [
    { no: 1, name: "order_item", kind: "message", T: OrderItem },
    { no: 2, name: "order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveOrderItemRequest
 */
export const SaveOrderItemRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveOrderItemRequest",
  () => [
    { no: 1, name: "order_item", kind: "message", T: OrderItem },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveOrderItemResponse
 */
export const SaveOrderItemResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveOrderItemResponse",
  () => [
    { no: 1, name: "order_item", kind: "message", T: OrderItem },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDonationRequest
 */
export const CreateDonationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDonationRequest",
  () => [
    { no: 1, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "donation_at", kind: "message", T: Timestamp },
    { no: 3, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "tribute", kind: "message", T: Tribute },
    { no: 7, name: "is_anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "match_company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
    { no: 10, name: "acknowledged_at", kind: "message", T: Timestamp },
    { no: 11, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 13, name: "payment_method", kind: "message", T: PaymentInfo },
    { no: 14, name: "billing_address", kind: "message", T: Address },
    { no: 15, name: "total", kind: "message", T: Money },
    { no: 16, name: "subtotal", kind: "message", T: Money },
    { no: 17, name: "external_donation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "pledge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "pledge_installment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "subscription_first_payment_at", kind: "message", T: Timestamp },
    { no: 21, name: "acknowledgment_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "soft_credits", kind: "message", T: SoftCreditParams, repeated: true },
    { no: 23, name: "should_skip_confirmation_email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDonationResponse
 */
export const CreateDonationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDonationResponse",
  () => [
    { no: 1, name: "donation_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "donation_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetOrderRequest
 */
export const GetOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetOrderRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetOrderResponse
 */
export const GetOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetOrderResponse",
  () => [
    { no: 1, name: "order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveOrderRequest
 */
export const SaveOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveOrderRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "shipping_info", kind: "message", T: ShippingInfo },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveOrderResponse
 */
export const SaveOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveOrderResponse",
  () => [
    { no: 1, name: "order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadOrderTicketsRequest
 */
export const DownloadOrderTicketsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadOrderTicketsRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "ticket_design_template_generation_mode", kind: "enum", T: proto3.getEnumType(TicketDesignTemplateGenerationMode) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadOrderTicketsResponse
 */
export const DownloadOrderTicketsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadOrderTicketsResponse",
  () => [
    { no: 1, name: "ticket_pdf", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ResendOrderConfirmationEmailRequest
 */
export const ResendOrderConfirmationEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ResendOrderConfirmationEmailRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ResendOrderConfirmationEmailResponse
 */
export const ResendOrderConfirmationEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ResendOrderConfirmationEmailResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.Card
 */
export const Card = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Card",
  () => [
    { no: 1, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expiration_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "expiration_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "holder_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetPaymentMethodDetailsRequest
 */
export const GetPaymentMethodDetailsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetPaymentMethodDetailsRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetPaymentMethodDetailsResponse
 */
export const GetPaymentMethodDetailsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetPaymentMethodDetailsResponse",
  () => [
    { no: 1, name: "card", kind: "message", T: Card },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadShowtimeOrderTicketsRequest
 */
export const DownloadShowtimeOrderTicketsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadShowtimeOrderTicketsRequest",
  () => [
    { no: 1, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
    { no: 3, name: "ticket_design_template_generation_mode", kind: "enum", T: proto3.getEnumType(TicketDesignTemplateGenerationMode) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadShowtimeOrderTicketsResponse
 */
export const DownloadShowtimeOrderTicketsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadShowtimeOrderTicketsResponse",
  () => [
    { no: 1, name: "ticket_pdf", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "ticket_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckInTableRow
 */
export const CheckInTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckInTableRow",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_created_at", kind: "message", T: Timestamp },
    { no: 3, name: "order_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "consumer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "consumer_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "consumer_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "lifetime_donation_amount", kind: "message", T: Money },
    { no: 10, name: "tickets", kind: "message", T: TicketItem, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCheckInTableRowsRequest
 */
export const ListCheckInTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCheckInTableRowsRequest",
  () => [
    { no: 1, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCheckInTableRowsResponse
 */
export const ListCheckInTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCheckInTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: CheckInTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckInShowtimeOrderTicketsRequest
 */
export const CheckInShowtimeOrderTicketsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckInShowtimeOrderTicketsRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ticket_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckInShowtimeOrderTicketsResponse
 */
export const CheckInShowtimeOrderTicketsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckInShowtimeOrderTicketsResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckInTicketRequest
 */
export const CheckInTicketRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckInTicketRequest",
  () => [
    { no: 1, name: "ticket_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckInTicketResponse
 */
export const CheckInTicketResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckInTicketResponse",
  () => [
    { no: 1, name: "ticket", kind: "message", T: TicketItem },
    { no: 2, name: "consumer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.BookedSeat
 */
export const BookedSeat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.BookedSeat",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "consumer_profile_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "seatsio_bookable_object_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBookedSeatsRequest
 */
export const ListBookedSeatsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBookedSeatsRequest",
  () => [
    { no: 1, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBookedSeatsResponse
 */
export const ListBookedSeatsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBookedSeatsResponse",
  () => [
    { no: 1, name: "booked_seats", kind: "message", T: BookedSeat, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Fulfillment
 */
export const Fulfillment = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Fulfillment",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fulfilled_at", kind: "message", T: Timestamp },
    { no: 3, name: "order_item_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "tracking_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFulfillmentRequest
 */
export const CreateFulfillmentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFulfillmentRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_item_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "tracking_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "should_send_fulfillment_email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "fulfillment_custom_email_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFulfillmentResponse
 */
export const CreateFulfillmentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFulfillmentResponse",
  () => [
    { no: 1, name: "fulfillment", kind: "message", T: Fulfillment },
  ],
);

