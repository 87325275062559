// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/box_office.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Showtime } from "./showtime_pb.js";
import { PriceListView } from "./price_list_view_pb.js";
import { ListShowsFilter } from "../../../common/v1/common_pb.js";
import { ShowDetail } from "./show_pb.js";
import { Discount } from "./discount_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.BoxOfficeShowtime
 */
export const BoxOfficeShowtime = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.BoxOfficeShowtime",
  () => [
    { no: 1, name: "showtime", kind: "message", T: Showtime },
    { no: 2, name: "price_list_view", kind: "message", T: PriceListView },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBoxOfficeShowtimesRequest
 */
export const ListBoxOfficeShowtimesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBoxOfficeShowtimesRequest",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBoxOfficeShowtimesResponse
 */
export const ListBoxOfficeShowtimesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBoxOfficeShowtimesResponse",
  () => [
    { no: 1, name: "box_office_showtimes", kind: "message", T: BoxOfficeShowtime, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBoxOfficeShowsRequest
 */
export const ListBoxOfficeShowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBoxOfficeShowsRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListShowsFilter) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBoxOfficeShowsResponse
 */
export const ListBoxOfficeShowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBoxOfficeShowsResponse",
  () => [
    { no: 1, name: "show_details", kind: "message", T: ShowDetail, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBoxOfficeDiscountsRequest
 */
export const ListBoxOfficeDiscountsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBoxOfficeDiscountsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListBoxOfficeDiscountsResponse
 */
export const ListBoxOfficeDiscountsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListBoxOfficeDiscountsResponse",
  () => [
    { no: 1, name: "discounts", kind: "message", T: Discount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetBoxOfficeShowtimeRequest
 */
export const GetBoxOfficeShowtimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetBoxOfficeShowtimeRequest",
  () => [
    { no: 1, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetBoxOfficeShowtimeResponse
 */
export const GetBoxOfficeShowtimeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetBoxOfficeShowtimeResponse",
  () => [
    { no: 1, name: "box_office_showtime", kind: "message", T: BoxOfficeShowtime },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeatsioSecretKeyRequest
 */
export const GetSeatsioSecretKeyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeatsioSecretKeyRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.GetSeatsioSecretKeyResponse
 */
export const GetSeatsioSecretKeyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetSeatsioSecretKeyResponse",
  () => [
    { no: 1, name: "seatsio_secret_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

