// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/communication.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum cuebox.services.staff.v1.CommunicationTemplateType
 */
export const CommunicationTemplateType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.CommunicationTemplateType",
  [
    {no: 0, name: "COMMUNICATION_TEMPLATE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMMUNICATION_TEMPLATE_TYPE_DONATION_ACKNOWLEDGMENT", localName: "DONATION_ACKNOWLEDGMENT"},
    {no: 2, name: "COMMUNICATION_TEMPLATE_TYPE_DONATION_SUBSCRIPTION_ACKNOWLEDGMENT", localName: "DONATION_SUBSCRIPTION_ACKNOWLEDGMENT"},
  ],
);

/**
 * @generated from enum cuebox.services.staff.v1.CommunicationFileFormat
 */
export const CommunicationFileFormat = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.CommunicationFileFormat",
  [
    {no: 0, name: "COMMUNICATION_FILE_FORMAT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMMUNICATION_FILE_FORMAT_PDF", localName: "PDF"},
    {no: 2, name: "COMMUNICATION_FILE_FORMAT_DOCX", localName: "DOCX"},
  ],
);

/**
 * @generated from enum cuebox.services.staff.v1.CommunicationTemplateContentType
 */
export const CommunicationTemplateContentType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.CommunicationTemplateContentType",
  [
    {no: 0, name: "COMMUNICATION_TEMPLATE_CONTENT_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMMUNICATION_TEMPLATE_CONTENT_TYPE_HTML", localName: "HTML"},
    {no: 2, name: "COMMUNICATION_TEMPLATE_CONTENT_TYPE_JSON", localName: "JSON"},
  ],
);

/**
 * @generated from enum cuebox.services.staff.v1.MergeField
 */
export const MergeField = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.MergeField",
  [
    {no: 0, name: "MERGE_FIELD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_FIRST_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONOR_FIRST_NAME"},
    {no: 2, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_LAST_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONOR_LAST_NAME"},
    {no: 3, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_FULL_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONOR_FULL_NAME"},
    {no: 5, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_TITLE", localName: "DONATION_ACKNOWLEDGMENT_TITLE"},
    {no: 6, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_EMAIL", localName: "DONATION_ACKNOWLEDGMENT_EMAIL"},
    {no: 7, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_PHONE", localName: "DONATION_ACKNOWLEDGMENT_PHONE"},
    {no: 8, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_ADDRESS_1", localName: "DONATION_ACKNOWLEDGMENT_BILLING_ADDRESS_1"},
    {no: 9, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_ADDRESS_2", localName: "DONATION_ACKNOWLEDGMENT_BILLING_ADDRESS_2"},
    {no: 10, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_STREET_ADDRESS", localName: "DONATION_ACKNOWLEDGMENT_BILLING_STREET_ADDRESS"},
    {no: 11, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_CITY", localName: "DONATION_ACKNOWLEDGMENT_BILLING_CITY"},
    {no: 12, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_STATE", localName: "DONATION_ACKNOWLEDGMENT_BILLING_STATE"},
    {no: 13, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_ZIP_CODE", localName: "DONATION_ACKNOWLEDGMENT_BILLING_ZIP_CODE"},
    {no: 14, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_CITY_STATE_ZIP", localName: "DONATION_ACKNOWLEDGMENT_BILLING_CITY_STATE_ZIP"},
    {no: 15, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_BILLING_COUNTRY", localName: "DONATION_ACKNOWLEDGMENT_BILLING_COUNTRY"},
    {no: 16, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_FULL_BILLING_ADDRESS", localName: "DONATION_ACKNOWLEDGMENT_FULL_BILLING_ADDRESS"},
    {no: 17, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_NET_DONATION_AMOUNT", localName: "DONATION_ACKNOWLEDGMENT_NET_DONATION_AMOUNT"},
    {no: 18, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONATION_DATE", localName: "DONATION_ACKNOWLEDGMENT_DONATION_DATE"},
    {no: 19, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_PAYMENT_METHOD_TYPE", localName: "DONATION_ACKNOWLEDGMENT_PAYMENT_METHOD_TYPE"},
    {no: 20, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_FUND", localName: "DONATION_ACKNOWLEDGMENT_FUND"},
    {no: 21, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_CAMPAIGN", localName: "DONATION_ACKNOWLEDGMENT_CAMPAIGN"},
    {no: 22, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_APPEAL", localName: "DONATION_ACKNOWLEDGMENT_APPEAL"},
    {no: 23, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_TODAYS_DATE", localName: "DONATION_ACKNOWLEDGMENT_TODAYS_DATE"},
    {no: 24, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_CHECK_NUMBER", localName: "DONATION_ACKNOWLEDGMENT_CHECK_NUMBER"},
    {no: 25, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_IN_KIND_DESCRIPTION", localName: "DONATION_ACKNOWLEDGMENT_IN_KIND_DESCRIPTION"},
    {no: 26, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONATION_TRIBUTE_TYPE", localName: "DONATION_ACKNOWLEDGMENT_DONATION_TRIBUTE_TYPE"},
    {no: 27, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONATION_TRIBUTE_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONATION_TRIBUTE_NAME"},
    {no: 28, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONATION_NOTES", localName: "DONATION_ACKNOWLEDGMENT_DONATION_NOTES"},
    {no: 29, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_SOFT_CREDIT_AMOUNT", localName: "DONATION_ACKNOWLEDGMENT_SOFT_CREDIT_AMOUNT"},
    {no: 30, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_SOFT_CREDIT_RECIPIENT", localName: "DONATION_ACKNOWLEDGMENT_SOFT_CREDIT_RECIPIENT"},
    {no: 31, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_MATCH_COMPANY_NAME", localName: "DONATION_ACKNOWLEDGMENT_MATCH_COMPANY_NAME"},
    {no: 32, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_ORDER_ID", localName: "DONATION_ACKNOWLEDGMENT_ORDER_ID"},
    {no: 33, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_HOUSEHOLD_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONOR_HOUSEHOLD_NAME"},
    {no: 34, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_FORMAL_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONOR_FORMAL_NAME"},
    {no: 35, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_PAYMENT_FREQUENCY", localName: "DONATION_ACKNOWLEDGMENT_PAYMENT_FREQUENCY"},
    {no: 36, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_INFORMAL_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONOR_INFORMAL_NAME"},
    {no: 37, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_RECOGNITION_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONOR_RECOGNITION_NAME"},
    {no: 38, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_FORMAL_ADDRESSEE", localName: "DONATION_ACKNOWLEDGMENT_DONOR_FORMAL_ADDRESSEE"},
    {no: 39, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_INFORMAL_ADDRESSEE", localName: "DONATION_ACKNOWLEDGMENT_DONOR_INFORMAL_ADDRESSEE"},
    {no: 40, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONATION_ID", localName: "DONATION_ACKNOWLEDGMENT_DONATION_ID"},
    {no: 41, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONOR_TITLE", localName: "DONATION_ACKNOWLEDGMENT_DONOR_TITLE"},
    {no: 42, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_OTHER_PAYMENT_DESCRIPTION", localName: "DONATION_ACKNOWLEDGMENT_OTHER_PAYMENT_DESCRIPTION"},
    {no: 43, name: "MERGE_FIELD_DONATION_ACKNOWLEDGMENT_DONATION_RECOGNITION_NAME", localName: "DONATION_ACKNOWLEDGMENT_DONATION_RECOGNITION_NAME"},
  ],
);

/**
 * @generated from enum cuebox.services.staff.v1.CommunicationDataType
 */
export const CommunicationDataType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.CommunicationDataType",
  [
    {no: 0, name: "COMMUNICATION_DATA_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMMUNICATION_DATA_TYPE_DONATION", localName: "DONATION"},
  ],
);

/**
 * @generated from enum cuebox.services.staff.v1.CommunicationCampaignStatus
 */
export const CommunicationCampaignStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.CommunicationCampaignStatus",
  [
    {no: 0, name: "COMMUNICATION_CAMPAIGN_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMMUNICATION_CAMPAIGN_STATUS_DRAFT", localName: "DRAFT"},
    {no: 2, name: "COMMUNICATION_CAMPAIGN_STATUS_PRINTED", localName: "PRINTED"},
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CommunicationTemplate
 */
export const CommunicationTemplate = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CommunicationTemplate",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "deleted_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "type", kind: "enum", T: proto3.getEnumType(CommunicationTemplateType) },
    { no: 9, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCommunicationTemplateRequest
 */
export const CreateCommunicationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCommunicationTemplateRequest",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(CommunicationTemplateType) },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCommunicationTemplateResponse
 */
export const CreateCommunicationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCommunicationTemplateResponse",
  () => [
    { no: 1, name: "template", kind: "message", T: CommunicationTemplate },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDonationAcknowledgmentLetterRequest
 */
export const CreateDonationAcknowledgmentLetterRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDonationAcknowledgmentLetterRequest",
  () => [
    { no: 1, name: "communication_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDonationAcknowledgmentLetterResponse
 */
export const CreateDonationAcknowledgmentLetterResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDonationAcknowledgmentLetterResponse",
  () => [
    { no: 1, name: "letter", kind: "message", T: CommunicationTemplateLetter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCommunicationTemplateLetterRequest
 */
export const SaveCommunicationTemplateLetterRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCommunicationTemplateLetterRequest",
  () => [
    { no: 1, name: "letter", kind: "message", T: CommunicationTemplateLetter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCommunicationTemplateLetterResponse
 */
export const SaveCommunicationTemplateLetterResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCommunicationTemplateLetterResponse",
  () => [
    { no: 1, name: "letter", kind: "message", T: CommunicationTemplateLetter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DocumentMargins
 */
export const DocumentMargins = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DocumentMargins",
  () => [
    { no: 1, name: "top", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "bottom", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "left", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "right", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadCommunicationTemplateLetterRequest
 */
export const DownloadCommunicationTemplateLetterRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadCommunicationTemplateLetterRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "html_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "margins", kind: "message", T: DocumentMargins },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadCommunicationTemplateLetterResponse
 */
export const DownloadCommunicationTemplateLetterResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadCommunicationTemplateLetterResponse",
  () => [
    { no: 1, name: "file", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCommunicationTemplatesRequest
 */
export const ListCommunicationTemplatesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCommunicationTemplatesRequest",
  () => [
    { no: 1, name: "types", kind: "enum", T: proto3.getEnumType(CommunicationTemplateType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCommunicationTemplatesResponse
 */
export const ListCommunicationTemplatesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCommunicationTemplatesResponse",
  () => [
    { no: 1, name: "templates", kind: "message", T: CommunicationTemplate, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCommunicationTemplateRequest
 */
export const GetCommunicationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCommunicationTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCommunicationTemplateResponse
 */
export const GetCommunicationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCommunicationTemplateResponse",
  () => [
    { no: 1, name: "template", kind: "message", T: CommunicationTemplate },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCommunicationTemplateRequest
 */
export const SaveCommunicationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCommunicationTemplateRequest",
  () => [
    { no: 1, name: "template", kind: "message", T: CommunicationTemplate },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCommunicationTemplateResponse
 */
export const SaveCommunicationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCommunicationTemplateResponse",
  () => [
    { no: 1, name: "template", kind: "message", T: CommunicationTemplate },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCommunicationTemplateRequest
 */
export const ArchiveCommunicationTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCommunicationTemplateRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCommunicationTemplateResponse
 */
export const ArchiveCommunicationTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCommunicationTemplateResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveDonationAcknowledgmentLetterRequest
 */
export const ArchiveDonationAcknowledgmentLetterRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveDonationAcknowledgmentLetterRequest",
  () => [
    { no: 1, name: "order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveDonationAcknowledgmentLetterResponse
 */
export const ArchiveDonationAcknowledgmentLetterResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveDonationAcknowledgmentLetterResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTemplateMergeTagsRequest
 */
export const ListTemplateMergeTagsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTemplateMergeTagsRequest",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(CommunicationTemplateType) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TemplateMergeTag
 */
export const TemplateMergeTag = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TemplateMergeTag",
  () => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "field", kind: "enum", T: proto3.getEnumType(MergeField) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTemplateMergeTagsResponse
 */
export const ListTemplateMergeTagsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTemplateMergeTagsResponse",
  () => [
    { no: 1, name: "merge_tags", kind: "message", T: TemplateMergeTag, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CommunicationTemplateLetter
 */
export const CommunicationTemplateLetter = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CommunicationTemplateLetter",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "downloaded_at", kind: "message", T: Timestamp },
    { no: 5, name: "communication_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "communication_template_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CommunicationCampaign
 */
export const CommunicationCampaign = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CommunicationCampaign",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "downloaded_at", kind: "message", T: Timestamp },
    { no: 5, name: "recipients_downloaded_at", kind: "message", T: Timestamp },
    { no: 6, name: "acknowledged_at", kind: "message", T: Timestamp },
    { no: 7, name: "data_type", kind: "enum", T: proto3.getEnumType(CommunicationDataType) },
    { no: 8, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "communication_template", kind: "message", T: CommunicationTemplate },
    { no: 11, name: "entity_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "status", kind: "enum", T: proto3.getEnumType(CommunicationCampaignStatus) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCommunicationCampaignRequest
 */
export const CreateCommunicationCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCommunicationCampaignRequest",
  () => [
    { no: 1, name: "data_type", kind: "enum", T: proto3.getEnumType(CommunicationDataType) },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCommunicationCampaignResponse
 */
export const CreateCommunicationCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCommunicationCampaignResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: CommunicationCampaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCommunicationCampaignRequest
 */
export const SaveCommunicationCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCommunicationCampaignRequest",
  () => [
    { no: 1, name: "campaign", kind: "message", T: CommunicationCampaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCommunicationCampaignResponse
 */
export const SaveCommunicationCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCommunicationCampaignResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: CommunicationCampaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCommunicationCampaignsRequest
 */
export const ListCommunicationCampaignsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCommunicationCampaignsRequest",
  () => [
    { no: 1, name: "data_types", kind: "enum", T: proto3.getEnumType(CommunicationDataType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListCommunicationCampaignsResponse
 */
export const ListCommunicationCampaignsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListCommunicationCampaignsResponse",
  () => [
    { no: 1, name: "campaigns", kind: "message", T: CommunicationCampaign, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCommunicationCampaignRequest
 */
export const ArchiveCommunicationCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCommunicationCampaignRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCommunicationCampaignResponse
 */
export const ArchiveCommunicationCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCommunicationCampaignResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.GenerateCommunicationCampaignLettersRequest
 */
export const GenerateCommunicationCampaignLettersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GenerateCommunicationCampaignLettersRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GenerateCommunicationCampaignLettersResponse
 */
export const GenerateCommunicationCampaignLettersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GenerateCommunicationCampaignLettersResponse",
  () => [
    { no: 1, name: "letters", kind: "message", T: CommunicationTemplateLetter, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CommunicationLetterParams
 */
export const CommunicationLetterParams = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CommunicationLetterParams",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "html_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadCommunicationLettersRequest
 */
export const DownloadCommunicationLettersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadCommunicationLettersRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "letters", kind: "message", T: CommunicationLetterParams, repeated: true },
    { no: 3, name: "margins", kind: "message", T: DocumentMargins },
    { no: 4, name: "should_acknowledge_campaign", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DownloadCommunicationLettersResponse
 */
export const DownloadCommunicationLettersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DownloadCommunicationLettersResponse",
  () => [
    { no: 1, name: "file", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AcknowledgeCommunicationCampaignRequest
 */
export const AcknowledgeCommunicationCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AcknowledgeCommunicationCampaignRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AcknowledgeCommunicationCampaignResponse
 */
export const AcknowledgeCommunicationCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AcknowledgeCommunicationCampaignResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: CommunicationCampaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GenerateCommunicationCampaignLetterPreviewRequest
 */
export const GenerateCommunicationCampaignLetterPreviewRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GenerateCommunicationCampaignLetterPreviewRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GenerateCommunicationCampaignLetterPreviewResponse
 */
export const GenerateCommunicationCampaignLetterPreviewResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GenerateCommunicationCampaignLetterPreviewResponse",
  () => [
    { no: 1, name: "letter", kind: "message", T: CommunicationTemplateLetter },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateCommunicationCampaignRecipientsDownloadedAtRequest
 */
export const UpdateCommunicationCampaignRecipientsDownloadedAtRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateCommunicationCampaignRecipientsDownloadedAtRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateCommunicationCampaignRecipientsDownloadedAtResponse
 */
export const UpdateCommunicationCampaignRecipientsDownloadedAtResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateCommunicationCampaignRecipientsDownloadedAtResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: CommunicationCampaign },
  ],
);

