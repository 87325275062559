// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/venue.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Address, Asset, SeatingModel } from "../../../common/v1/common_pb.js";
import { PriceLevel } from "./price_level_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Venue
 */
export const Venue = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Venue",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "address", kind: "message", T: Address },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.VenueLayout
 */
export const VenueLayout = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.VenueLayout",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "venue", kind: "message", T: Venue },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 8, name: "image", kind: "message", T: Asset },
    { no: 9, name: "max_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "seatsio_dummy_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "price_levels", kind: "message", T: PriceLevel, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListVenuesRequest
 */
export const ListVenuesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListVenuesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListVenuesResponse
 */
export const ListVenuesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListVenuesResponse",
  () => [
    { no: 1, name: "venues", kind: "message", T: Venue, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetVenueRequest
 */
export const GetVenueRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetVenueRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetVenueResponse
 */
export const GetVenueResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetVenueResponse",
  () => [
    { no: 1, name: "venue", kind: "message", T: Venue },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveVenueRequest
 */
export const SaveVenueRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveVenueRequest",
  () => [
    { no: 1, name: "venue", kind: "message", T: Venue },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveVenueResponse
 */
export const SaveVenueResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveVenueResponse",
  () => [
    { no: 1, name: "venue", kind: "message", T: Venue },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateVenueRequest
 */
export const CreateVenueRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateVenueRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address", kind: "message", T: Address },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateVenueResponse
 */
export const CreateVenueResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateVenueResponse",
  () => [
    { no: 1, name: "venue", kind: "message", T: Venue },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveVenueRequest
 */
export const ArchiveVenueRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveVenueRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveVenueResponse
 */
export const ArchiveVenueResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveVenueResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListVenueLayoutsRequest
 */
export const ListVenueLayoutsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListVenueLayoutsRequest",
  () => [
    { no: 1, name: "venue_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListVenueLayoutsResponse
 */
export const ListVenueLayoutsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListVenueLayoutsResponse",
  () => [
    { no: 1, name: "venue_layouts", kind: "message", T: VenueLayout, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetVenueLayoutRequest
 */
export const GetVenueLayoutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetVenueLayoutRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetVenueLayoutResponse
 */
export const GetVenueLayoutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetVenueLayoutResponse",
  () => [
    { no: 1, name: "venue_layout", kind: "message", T: VenueLayout },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveVenueLayoutRequest
 */
export const SaveVenueLayoutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveVenueLayoutRequest",
  () => [
    { no: 1, name: "venue_layout", kind: "message", T: VenueLayout },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveVenueLayoutResponse
 */
export const SaveVenueLayoutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveVenueLayoutResponse",
  () => [
    { no: 1, name: "venue_layout", kind: "message", T: VenueLayout },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateVenueLayoutRequest
 */
export const CreateVenueLayoutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateVenueLayoutRequest",
  () => [
    { no: 1, name: "venue_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 4, name: "image_asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "max_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "seatsio_seating_chart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateVenueLayoutResponse
 */
export const CreateVenueLayoutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateVenueLayoutResponse",
  () => [
    { no: 1, name: "venue_layout", kind: "message", T: VenueLayout },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveVenueLayoutRequest
 */
export const ArchiveVenueLayoutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveVenueLayoutRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveVenueLayoutResponse
 */
export const ArchiveVenueLayoutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveVenueLayoutResponse",
  [],
);

