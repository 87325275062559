// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/contributed_revenue_attributes.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Campaign } from "./campaign_pb.js";
import { Fund } from "./fund_pb.js";
import { Appeal } from "./appeal_pb.js";
import { AcknowledgmentStatus, Address, EmailMarketingSubscriptionStatus, Money, OrderItemPaymentStatus, PaymentMethod, PaymentMethodType, PhoneNumber, SalesChannel, SubscriptionFrequency, TimeRange, Tribute } from "../../../common/v1/common_pb.js";
import { CommunicationTemplateLetter } from "./communication_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.ContributedRevenueAttributes
 */
export const ContributedRevenueAttributes = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ContributedRevenueAttributes",
  () => [
    { no: 1, name: "campaign", kind: "message", T: Campaign },
    { no: 2, name: "fund", kind: "message", T: Fund },
    { no: 3, name: "appeal", kind: "message", T: Appeal },
    { no: 4, name: "tribute", kind: "message", T: Tribute },
    { no: 5, name: "is_anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
    { no: 7, name: "acknowledged_at", kind: "message", T: Timestamp },
    { no: 8, name: "acknowledgment_letter", kind: "message", T: CommunicationTemplateLetter },
    { no: 9, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ContributedRevenueOrderItemsTableRow
 */
export const ContributedRevenueOrderItemsTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ContributedRevenueOrderItemsTableRow",
  () => [
    { no: 1, name: "contributed_revenue_order_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 4, name: "order_at", kind: "message", T: Timestamp },
    { no: 5, name: "contributed_at", kind: "message", T: Timestamp },
    { no: 6, name: "item_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 9, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "consumer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "consumer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "billing_address", kind: "message", T: Address },
    { no: 13, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 15, name: "payment_method_type", kind: "enum", T: proto3.getEnumType(PaymentMethodType) },
    { no: 16, name: "total_amount", kind: "message", T: Money },
    { no: 17, name: "net_amount", kind: "message", T: Money },
    { no: 18, name: "acknowledgment_status", kind: "enum", T: proto3.getEnumType(AcknowledgmentStatus) },
    { no: 19, name: "is_anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "tribute", kind: "message", T: Tribute },
    { no: 21, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "fund_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "appeal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "pledge_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "match_company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "soft_credit_amount", kind: "message", T: Money },
    { no: 31, name: "soft_credit_recipient_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 32, name: "household_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "household_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "donor_formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "donor_informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 39, name: "general_ledger_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "donor_recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 41, name: "donor_formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 42, name: "donor_informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 43, name: "donor_phone_number", kind: "message", T: PhoneNumber },
    { no: 44, name: "payment_method", kind: "message", T: PaymentMethod },
    { no: 45, name: "consumer_email_marketing_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 46, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ContributedRevenueTableFilters
 */
export const ContributedRevenueTableFilters = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ContributedRevenueTableFilters",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsRequest
 */
export const ListContributedRevenueOrderItemsTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsRequest",
  () => [
    { no: 1, name: "filters", kind: "message", T: ContributedRevenueTableFilters },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsResponse
 */
export const ListContributedRevenueOrderItemsTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: ContributedRevenueOrderItemsTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsByIDsRequest
 */
export const ListContributedRevenueOrderItemsTableRowsByIDsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsByIDsRequest",
  () => [
    { no: 1, name: "order_item_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsByIDsResponse
 */
export const ListContributedRevenueOrderItemsTableRowsByIDsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListContributedRevenueOrderItemsTableRowsByIDsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: ContributedRevenueOrderItemsTableRow, repeated: true },
  ],
);

