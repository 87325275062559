import type { PropsWithChildren } from 'react';
import Script from 'next/script';
import { withGoogleMapsScript } from './useGoogleMapsScript';

/**
 * This must be mounted outside of `NextPage` (to stay mounted on navigate),
 * and inside `ErrorTrackingProvider` (to access trackError).
 */
export const GoogleMapsScriptProvider = withGoogleMapsScript(
  ({ children }: PropsWithChildren) => (
    <>
      <Script id="init-map">{`window.initMap = function() {}`}</Script>
      {children}
    </>
  ),
);
