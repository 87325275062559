import type * as ProtobufTypes from '@bufbuild/protobuf';
import type * as CommonTypes from '@pb/cuebox/common/v1/common_pb';

export { TimeRangePreset } from '@pb/cuebox/common/v1/common_pb';

export type Timestamp = ProtobufTypes.PlainMessage<ProtobufTypes.Timestamp>;

export type Duration = ProtobufTypes.PlainMessage<ProtobufTypes.Duration>;

export type TimeRange = ProtobufTypes.PlainMessage<CommonTypes.TimeRange>;
