import {
  type ComponentType,
  type MutableRefObject,
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import Script from 'next/script';
import { useErrorTracking } from './errorTracking';

export const enum LoadedStatus {
  ERROR = 'ERROR',
  LOADED = 'LOADED',
  PENDING = 'PENDING',
}

export type ScriptLoaderContextApi = {
  hasLoaded: boolean;
  status: LoadedStatus;
  ref: MutableRefObject<HTMLScriptElement | null>;
};

const getScriptElement = (id: string) =>
  document.querySelector<HTMLScriptElement>(`#${id}`);

interface ScriptLoaderProps {
  src: string;
  id: string;
}

// This helper is a "script loader factory" which creates a context provider and consumer hook.
// Mount the Provider in a page route or `_app.ts` to manage loading the third-party script.
// Compose `useScript` in your own hooks to perform actions with third-party JS libraries when the script loads.
export const createScriptLoader = (props: ScriptLoaderProps) => {
  const { src, id } = props;

  const ScriptLoaderContext = createContext<ScriptLoaderContextApi>({
    hasLoaded: false,
    status: LoadedStatus.PENDING,
    ref: { current: null },
  });

  const Provider = (providerProps: PropsWithChildren) => {
    const { children } = providerProps;

    const ref: MutableRefObject<HTMLScriptElement | null> = useRef(
      getScriptElement(id),
    );

    const [status, setStatus] = useState<LoadedStatus>(() => {
      if (ref.current) {
        return ref.current.dataset.error
          ? LoadedStatus.ERROR
          : LoadedStatus.LOADED;
      }
      return LoadedStatus.PENDING;
    });

    const handleLoad = useCallback(() => {
      const scriptEl = getScriptElement(id);
      if (scriptEl) {
        ref.current = scriptEl;
        setStatus(
          scriptEl.dataset.error ? LoadedStatus.ERROR : LoadedStatus.LOADED,
        );
      }
    }, []);

    const { trackError } = useErrorTracking();

    const handleError = useCallback(
      (err: Error) => {
        const scriptEl = getScriptElement(id);

        if (scriptEl) {
          ref.current = scriptEl;
          scriptEl.setAttribute('data-error', 'true');
        }

        setStatus(LoadedStatus.ERROR);

        // We've seen in DD that trackError is sometimes undefined ("TypeError: v is not a function" following Clerk errors).
        // This may be due to an unrecoverable error preventing React from fully rendering providers, so throwing here will (hopefully) hit the global DD tracker and provide more useful meta.
        if (trackError) {
          trackError(
            err,
            { src, id },
            'Failed to load script in `scriptLoader`',
          );
        } else {
          throw err;
        }
      },
      [trackError],
    );

    const contextValue = useMemo(
      () => ({
        hasLoaded: status === LoadedStatus.LOADED,
        ref,
        status,
      }),
      [status],
    );

    return (
      <>
        <ScriptLoaderContext.Provider value={contextValue}>
          {children}
        </ScriptLoaderContext.Provider>
        <Script id={id} src={src} onReady={handleLoad} onError={handleError} />
      </>
    );
  };

  const withScriptLoader = <P extends Record<string, unknown>>(
    Comp: ComponentType<P>,
  ) => {
    const WithScriptLoader = (scriptLoaderProps: P & PropsWithChildren) => {
      // If we have no window then we're in SSR so can't load scripts.
      if (typeof window === 'undefined') {
        return <Comp {...(scriptLoaderProps as P)} />;
      }

      return (
        <Provider>
          <Comp {...(scriptLoaderProps as P)} />
        </Provider>
      );
    };

    return WithScriptLoader;
  };

  const useScriptLoader = () => useContext(ScriptLoaderContext);

  return {
    Provider,
    withScriptLoader,
    useScriptLoader,
  };
};
