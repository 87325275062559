// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/staff.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.HelloRequest
 */
export const HelloRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.HelloRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.HelloResponse
 */
export const HelloResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.HelloResponse",
  () => [
    { no: 1, name: "greeting", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

