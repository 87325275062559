// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/consumer.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetOrganizationRequest, GetOrganizationResponse } from "./organization_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { GetCampaignStatRequest, GetCampaignStatResponse, GetDonationFormRequest, GetDonationFormResponse, ListFundsRequest, ListFundsResponse } from "./donation_form_pb.js";
import { GetShowRequest, GetShowResponse, GetShowtimeRequest, GetShowtimeResponse, ListShowsRequest, ListShowsResponse, ListShowtimesRequest, ListShowtimesResponse } from "./show_pb.js";
import { GetSeasonPackageRequest, GetSeasonPackageResponse, ListSeasonPackagesRequest, ListSeasonPackagesResponse } from "./season_package_pb.js";
import { GetMembershipRequest, GetMembershipResponse, GetMembershipSubscriptionRequest, GetMembershipSubscriptionResponse, ListMembershipsRequest, ListMembershipsResponse, ListMembershipSubscriptionsRequest, ListMembershipSubscriptionsResponse } from "./membership_pb.js";
import { AddDiscountRequest, AddDiscountResponse, AddSeasonPackageGroupRequest, AddSeasonPackageGroupResponse, CreateCartRequest, CreateCartResponse, GetCartOptionsRequest, GetCartOptionsResponse, GetCartRequest, GetCartResponse, ListAddOnProductsForCartRequest, ListAddOnProductsForCartResponse, ListConsumerSeatHoldsRequest, ListConsumerSeatHoldsResponse, RemoveDiscountRequest, RemoveDiscountResponse, ResetCartRequest, ResetCartResponse, SaveCartFormSubmissionRequest, SaveCartFormSubmissionResponse, SaveCheckoutEmailRequest, SaveCheckoutEmailResponse, SaveItemsRequest, SaveItemsResponse, SaveSeasonPackageGroupShowtimesRequest, SaveSeasonPackageGroupShowtimesResponse, SaveSelectedCartOptionsRequest, SaveSelectedCartOptionsResponse } from "./cart_pb.js";
import { CheckoutCartRequest, CheckoutCartResponse } from "./checkout_pb.js";
import { ListTicketTypeUsageLimitsRequest, ListTicketTypeUsageLimitsResponse } from "./usage_limit_pb.js";
import { GetIndividualProfileRequest, GetIndividualProfileResponse, SaveIndividualProfileRequest, SaveIndividualProfileResponse } from "./profile_pb.js";
import { GetMerchandiseRequest, GetMerchandiseResponse, ListMerchandisesRequest, ListMerchandisesResponse } from "./merchandise_pb.js";
import { ListCampaignsRequest, ListCampaignsResponse } from "./campaign_pb.js";
import { GenerateAppleWalletPassesRequest, GenerateAppleWalletPassesResponse, GenerateGoogleWalletPassesRequest, GenerateGoogleWalletPassesResponse } from "./wallet_pb.js";
import { DownloadOrderTicketsRequest, DownloadOrderTicketsResponse, GetOrderRequest, GetOrderResponse } from "./order_pb.js";

/**
 * @generated from service cuebox.services.consumer.v1.CueboxConsumerService
 */
export const CueboxConsumerService = {
  typeName: "cuebox.services.consumer.v1.CueboxConsumerService",
  methods: {
    /**
     * Organization
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetOrganization
     */
    getOrganization: {
      name: "GetOrganization",
      I: GetOrganizationRequest,
      O: GetOrganizationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Donation Form
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetDonationForm
     */
    getDonationForm: {
      name: "GetDonationForm",
      I: GetDonationFormRequest,
      O: GetDonationFormResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListFunds
     */
    listFunds: {
      name: "ListFunds",
      I: ListFundsRequest,
      O: ListFundsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetCampaignStat
     */
    getCampaignStat: {
      name: "GetCampaignStat",
      I: GetCampaignStatRequest,
      O: GetCampaignStatResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Show and Showtime
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListShows
     */
    listShows: {
      name: "ListShows",
      I: ListShowsRequest,
      O: ListShowsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetShow
     */
    getShow: {
      name: "GetShow",
      I: GetShowRequest,
      O: GetShowResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListShowtimes
     */
    listShowtimes: {
      name: "ListShowtimes",
      I: ListShowtimesRequest,
      O: ListShowtimesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetShowtime
     */
    getShowtime: {
      name: "GetShowtime",
      I: GetShowtimeRequest,
      O: GetShowtimeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Season Package
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListSeasonPackages
     */
    listSeasonPackages: {
      name: "ListSeasonPackages",
      I: ListSeasonPackagesRequest,
      O: ListSeasonPackagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetSeasonPackage
     */
    getSeasonPackage: {
      name: "GetSeasonPackage",
      I: GetSeasonPackageRequest,
      O: GetSeasonPackageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Memberships
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListMemberships
     */
    listMemberships: {
      name: "ListMemberships",
      I: ListMembershipsRequest,
      O: ListMembershipsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetMembership
     */
    getMembership: {
      name: "GetMembership",
      I: GetMembershipRequest,
      O: GetMembershipResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListMembershipSubscriptions
     */
    listMembershipSubscriptions: {
      name: "ListMembershipSubscriptions",
      I: ListMembershipSubscriptionsRequest,
      O: ListMembershipSubscriptionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetMembershipSubscription
     */
    getMembershipSubscription: {
      name: "GetMembershipSubscription",
      I: GetMembershipSubscriptionRequest,
      O: GetMembershipSubscriptionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Cart
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.CreateCart
     */
    createCart: {
      name: "CreateCart",
      I: CreateCartRequest,
      O: CreateCartResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetCart
     */
    getCart: {
      name: "GetCart",
      I: GetCartRequest,
      O: GetCartResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ResetCart
     */
    resetCart: {
      name: "ResetCart",
      I: ResetCartRequest,
      O: ResetCartResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.SaveItems
     */
    saveItems: {
      name: "SaveItems",
      I: SaveItemsRequest,
      O: SaveItemsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.RemoveDiscount
     */
    removeDiscount: {
      name: "RemoveDiscount",
      I: RemoveDiscountRequest,
      O: RemoveDiscountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.AddSeasonPackageGroup
     */
    addSeasonPackageGroup: {
      name: "AddSeasonPackageGroup",
      I: AddSeasonPackageGroupRequest,
      O: AddSeasonPackageGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.SaveSeasonPackageGroupShowtimes
     */
    saveSeasonPackageGroupShowtimes: {
      name: "SaveSeasonPackageGroupShowtimes",
      I: SaveSeasonPackageGroupShowtimesRequest,
      O: SaveSeasonPackageGroupShowtimesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetCartOptions
     */
    getCartOptions: {
      name: "GetCartOptions",
      I: GetCartOptionsRequest,
      O: GetCartOptionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.SaveSelectedCartOptions
     */
    saveSelectedCartOptions: {
      name: "SaveSelectedCartOptions",
      I: SaveSelectedCartOptionsRequest,
      O: SaveSelectedCartOptionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.CheckoutCart
     */
    checkoutCart: {
      name: "CheckoutCart",
      I: CheckoutCartRequest,
      O: CheckoutCartResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.AddDiscount
     */
    addDiscount: {
      name: "AddDiscount",
      I: AddDiscountRequest,
      O: AddDiscountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.SaveCheckoutEmail
     */
    saveCheckoutEmail: {
      name: "SaveCheckoutEmail",
      I: SaveCheckoutEmailRequest,
      O: SaveCheckoutEmailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListConsumerSeatHolds
     */
    listConsumerSeatHolds: {
      name: "ListConsumerSeatHolds",
      I: ListConsumerSeatHoldsRequest,
      O: ListConsumerSeatHoldsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListAddOnProductsForCart
     */
    listAddOnProductsForCart: {
      name: "ListAddOnProductsForCart",
      I: ListAddOnProductsForCartRequest,
      O: ListAddOnProductsForCartResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.SaveCartFormSubmission
     */
    saveCartFormSubmission: {
      name: "SaveCartFormSubmission",
      I: SaveCartFormSubmissionRequest,
      O: SaveCartFormSubmissionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Usage Limits
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListTicketTypeUsageLimits
     */
    listTicketTypeUsageLimits: {
      name: "ListTicketTypeUsageLimits",
      I: ListTicketTypeUsageLimitsRequest,
      O: ListTicketTypeUsageLimitsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Auth and Profile
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetIndividualProfile
     */
    getIndividualProfile: {
      name: "GetIndividualProfile",
      I: GetIndividualProfileRequest,
      O: GetIndividualProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.SaveIndividualProfile
     */
    saveIndividualProfile: {
      name: "SaveIndividualProfile",
      I: SaveIndividualProfileRequest,
      O: SaveIndividualProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Merchandise
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListMerchandises
     */
    listMerchandises: {
      name: "ListMerchandises",
      I: ListMerchandisesRequest,
      O: ListMerchandisesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetMerchandise
     */
    getMerchandise: {
      name: "GetMerchandise",
      I: GetMerchandiseRequest,
      O: GetMerchandiseResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Campaign
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.ListCampaigns
     */
    listCampaigns: {
      name: "ListCampaigns",
      I: ListCampaignsRequest,
      O: ListCampaignsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Wallet
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GenerateAppleWalletPasses
     */
    generateAppleWalletPasses: {
      name: "GenerateAppleWalletPasses",
      I: GenerateAppleWalletPassesRequest,
      O: GenerateAppleWalletPassesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GenerateGoogleWalletPasses
     */
    generateGoogleWalletPasses: {
      name: "GenerateGoogleWalletPasses",
      I: GenerateGoogleWalletPassesRequest,
      O: GenerateGoogleWalletPassesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Order
     *
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.GetOrder
     */
    getOrder: {
      name: "GetOrder",
      I: GetOrderRequest,
      O: GetOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc cuebox.services.consumer.v1.CueboxConsumerService.DownloadOrderTickets
     */
    downloadOrderTickets: {
      name: "DownloadOrderTickets",
      I: DownloadOrderTicketsRequest,
      O: DownloadOrderTicketsResponse,
      kind: MethodKind.Unary,
    },
  }
};

