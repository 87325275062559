// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/price_list_view.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { TicketType } from "./ticket_type_pb.js";
import { PriceLevel } from "./price_level_pb.js";
import { Money, SeatingModel } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.PriceCellView
 */
export const PriceCellView = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceCellView",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_type", kind: "message", T: TicketType },
    { no: 3, name: "price_level", kind: "message", T: PriceLevel },
    { no: 4, name: "amount", kind: "message", T: Money },
    { no: 5, name: "fee_amount", kind: "message", T: Money },
    { no: 6, name: "all_in_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PriceListView
 */
export const PriceListView = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceListView",
  () => [
    { no: 1, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 2, name: "ticket_types", kind: "message", T: TicketType, repeated: true },
    { no: 3, name: "price_levels", kind: "message", T: PriceLevel, repeated: true },
    { no: 4, name: "price_cells", kind: "message", T: PriceCellView, repeated: true },
    { no: 5, name: "ticket_type_availabilities", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: TicketTypeAvailability} },
    { no: 6, name: "stat", kind: "message", T: PriceListViewStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketTypeAvailability
 */
export const TicketTypeAvailability = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketTypeAvailability",
  () => [
    { no: 1, name: "available_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PriceListViewStat
 */
export const PriceListViewStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceListViewStat",
  () => [
    { no: 1, name: "min_ticket_price", kind: "message", T: Money },
    { no: 2, name: "max_ticket_price", kind: "message", T: Money },
  ],
);

