import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  type ToastProps,
} from '@chakra-ui/react';

export const ToastAlert = (props: ToastProps) => {
  const { status, title, description, isClosable, onClose } = props;

  return (
    <Alert
      bg="white"
      borderRadius={8}
      boxShadow="cuebox"
      status={status}
      variant="left-accent"
    >
      <AlertIcon />
      <AlertTitle flex={1}>{title}</AlertTitle>
      {description && <AlertDescription>{description}</AlertDescription>}
      {isClosable && (
        <CloseButton alignSelf="center" onClick={onClose} size="sm" />
      )}
    </Alert>
  );
};

export default ToastAlert;
