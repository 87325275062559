import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const inputSizeOverrides = {
  sm: definePartsStyle({
    field: {
      rounded: 'md',
    },
  }),
  md: definePartsStyle({
    field: {
      px: 3,
    },
  }),
};

export const inputVariantCuebox = definePartsStyle({
  field: {
    bg: 'inherit',
    borderWidth: '1px',
    borderStyle: 'solid',
    _hover: {
      borderColor: 'primary.darker',
    },
    _invalid: {
      borderColor: 'error',
      boxShadow: '0 0 0 1px var(--chakra-colors-error)',
    },
    _focusVisible: {
      zIndex: 1,
      borderColor: 'primary.darker',
      boxShadow: '0 0 0 1px var(--chakra-colors-primary-darker)',
    },
    _disabled: {
      opacity: 0.7,
    },
  },
  addon: {
    border: '1px solid',
    borderColor: 'inherit',
    bg: 'backgrounds.base',
  },
});

export const inputTheme = defineMultiStyleConfig({
  sizes: inputSizeOverrides,
  variants: {
    cuebox: inputVariantCuebox,
  },
  defaultProps: {
    variant: 'cuebox',
  },
});
