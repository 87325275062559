import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const overlayStyle = defineStyle({
  bg: 'rgba(37, 41, 46, 0.4)',
});

const dialogStyle = defineStyle({
  borderRadius: 12,
});

const headerStyle = defineStyle({
  fontSize: 'lg',
  borderBottomWidth: 1,
});

const bodyStyle = defineStyle({
  py: 5,
});

const footerStyle = defineStyle({
  borderTopWidth: 1,
  gap: 2,
  py: 3,
});

const closeButtonStyle = defineStyle({
  top: 3,
});

const baseStyle = definePartsStyle({
  body: bodyStyle,
  dialog: dialogStyle,
  footer: footerStyle,
  header: headerStyle,
  overlay: overlayStyle,
  closeButton: closeButtonStyle,
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
