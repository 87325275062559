// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/price_level.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { SeatingModel } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.PriceLevel
 */
export const PriceLevel = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceLevel",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "seatsio_price_category_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color_hex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPriceLevelsRequest
 */
export const ListPriceLevelsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPriceLevelsRequest",
  () => [
    { no: 1, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPriceLevelsResponse
 */
export const ListPriceLevelsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPriceLevelsResponse",
  () => [
    { no: 1, name: "price_levels", kind: "message", T: PriceLevel, repeated: true },
  ],
);

