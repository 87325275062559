import {
  Component,
  type ComponentType,
  type ErrorInfo,
  type PropsWithChildren,
} from 'react';

export type ErrorPayload = { error: Error; info: ErrorInfo };

interface ErrorBoundaryProps extends PropsWithChildren {
  fallback: ComponentType<Record<string, unknown>>;
  onError: (payload: ErrorPayload) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  /**
   * This approach was copied from DataDog's React Error Boundary implementation.
   * @see {@link https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation}
   */
  componentDidCatch(err: Error, info: ErrorInfo) {
    const renderingError = new Error(err.message);
    renderingError.name = 'ReactRenderingError';
    if (info.componentStack) {
      renderingError.stack = info.componentStack;
    }
    renderingError.cause = err;
    this.props.onError({
      error: renderingError,
      info,
    });
  }

  render() {
    const { children, fallback: FallbackState } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <FallbackState />;
    }

    return children;
  }
}

export default ErrorBoundary;
