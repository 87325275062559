// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/campaign.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.consumer.v1.Campaign
 */
export const Campaign = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Campaign",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_donation_recognition_name_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListCampaignsRequest
 */
export const ListCampaignsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListCampaignsRequest",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListCampaignsResponse
 */
export const ListCampaignsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListCampaignsResponse",
  () => [
    { no: 1, name: "campaigns", kind: "message", T: Campaign, repeated: true },
  ],
);

