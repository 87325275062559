import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Portal, chakra } from '@chakra-ui/react';
import { useNProgress } from '@tanem/react-nprogress';

const AppProgressBar = () => {
  const router = useRouter();

  const [isRouteChanging, setRouteChanging] = useState<boolean>(false);

  useEffect(() => {
    const handleRouteChangeStart = (_: string, opts: { shallow?: boolean }) => {
      if (!opts.shallow) {
        setRouteChanging(true);
      }
    };

    const handleRouteChangeEnd = () => {
      setRouteChanging(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  }, [router.events]);

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  });

  return (
    <Portal>
      <chakra.aside
        pointerEvents="none"
        opacity={isFinished ? 0 : 1}
        transition={`opacity ${animationDuration}ms linear`}
      >
        <chakra.div
          position="fixed"
          top={0}
          left={0}
          h="2px"
          w="100%"
          bg="blue.500"
          ml={isFinished ? '-100%' : `${(-1 + progress) * 100}%`}
          transition={`margin-left ${animationDuration}ms linear`}
          zIndex={9999}
        >
          <chakra.div
            shadow="0 0 10px #29d, 0 0 5px #29d"
            height="100%"
            position="absolute"
            right={0}
            transform="rotate(3deg) translate(0px, -4px)"
            width="100px"
          />
        </chakra.div>
      </chakra.aside>
    </Portal>
  );
};

export default AppProgressBar;
