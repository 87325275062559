// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/appeal.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Money } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Appeal
 */
export const Appeal = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Appeal",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AppealTableRow
 */
export const AppealTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AppealTableRow",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_raised_amount", kind: "message", T: Money },
    { no: 4, name: "outstanding_pledge_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateAppealRequest
 */
export const CreateAppealRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateAppealRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateAppealResponse
 */
export const CreateAppealResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateAppealResponse",
  () => [
    { no: 1, name: "appeal", kind: "message", T: Appeal },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListAppealsRequest
 */
export const ListAppealsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListAppealsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListAppealsResponse
 */
export const ListAppealsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListAppealsResponse",
  () => [
    { no: 1, name: "appeals", kind: "message", T: Appeal, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListAppealTableRowsRequest
 */
export const ListAppealTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListAppealTableRowsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListAppealTableRowsResponse
 */
export const ListAppealTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListAppealTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: AppealTableRow, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetAppealRequest
 */
export const GetAppealRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetAppealRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetAppealResponse
 */
export const GetAppealResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetAppealResponse",
  () => [
    { no: 1, name: "appeal", kind: "message", T: Appeal },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveAppealRequest
 */
export const SaveAppealRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveAppealRequest",
  () => [
    { no: 1, name: "appeal", kind: "message", T: Appeal },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveAppealResponse
 */
export const SaveAppealResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveAppealResponse",
  () => [
    { no: 1, name: "appeal", kind: "message", T: Appeal },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveAppealRequest
 */
export const ArchiveAppealRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveAppealRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveAppealResponse
 */
export const ArchiveAppealResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveAppealResponse",
  [],
);

