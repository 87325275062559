import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleText = defineStyle({
  marginTop: 1,
});

const baseStyle = definePartsStyle({
  text: baseStyleText,
});

export const formErrorTheme = defineMultiStyleConfig({
  baseStyle,
});
