import type { Message } from '@bufbuild/protobuf';
import { CueboxConsumerService } from '@pb/cuebox/services/consumer/v1/consumer_connect';
import type { ConsumerServiceContextApi } from '../hooks/useConsumerService';
import { ConsumerServiceContext } from '../providers/ConsumerServiceProvider';
import createConnectFetcher, {
  type ConnectFetcherArgs,
} from '../wrappers/createConnectFetcher';
import createConnectTrigger, {
  type ConnectTriggerArgs,
} from '../wrappers/createConnectTrigger';

export const createConsumerFetcher = <
  Params extends Message<Params>,
  FRes extends Message<FRes>,
>(
  args: ConnectFetcherArgs<typeof CueboxConsumerService, Params, FRes>,
) =>
  createConnectFetcher<
    typeof CueboxConsumerService,
    ConsumerServiceContextApi,
    Params,
    FRes
  >(args, {
    context: ConsumerServiceContext,
    service: CueboxConsumerService,
  });

export const createConsumerTrigger = <
  Params extends Message<Params>,
  FRes extends Message<FRes>,
>(
  args: ConnectTriggerArgs<typeof CueboxConsumerService, Params, FRes>,
) =>
  createConnectTrigger<
    typeof CueboxConsumerService,
    ConsumerServiceContextApi,
    Params,
    FRes
  >(args, {
    context: ConsumerServiceContext,
    service: CueboxConsumerService,
  });
