// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/fund.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Money } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.FundStat
 */
export const FundStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FundStat",
  () => [
    { no: 1, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_raised_amount", kind: "message", T: Money },
    { no: 3, name: "outstanding_pledge_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Fund
 */
export const Fund = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Fund",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "can_be_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFundRequest
 */
export const CreateFundRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFundRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateFundResponse
 */
export const CreateFundResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateFundResponse",
  () => [
    { no: 1, name: "fund", kind: "message", T: Fund },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFundsRequest
 */
export const ListFundsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFundsRequest",
  () => [
    { no: 1, name: "should_include_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFundsResponse
 */
export const ListFundsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFundsResponse",
  () => [
    { no: 1, name: "funds", kind: "message", T: Fund, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFundRequest
 */
export const GetFundRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFundRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFundResponse
 */
export const GetFundResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFundResponse",
  () => [
    { no: 1, name: "fund", kind: "message", T: Fund },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFundsRequest
 */
export const GetFundsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFundsRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetFundsResponse
 */
export const GetFundsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetFundsResponse",
  () => [
    { no: 1, name: "funds", kind: "message", T: Fund, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveFundRequest
 */
export const SaveFundRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveFundRequest",
  () => [
    { no: 1, name: "fund", kind: "message", T: Fund },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveFundResponse
 */
export const SaveFundResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveFundResponse",
  () => [
    { no: 1, name: "fund", kind: "message", T: Fund },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveFundRequest
 */
export const ArchiveFundRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveFundRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveFundResponse
 */
export const ArchiveFundResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveFundResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFundStatsRequest
 */
export const ListFundStatsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFundStatsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListFundStatsResponse
 */
export const ListFundStatsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListFundStatsResponse",
  () => [
    { no: 1, name: "stats", kind: "message", T: FundStat, repeated: true },
  ],
);

