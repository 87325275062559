import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyleList = defineStyle({
  width: 'fit-content',
});

const baseStyleItem = defineStyle({
  color: 'text.base',
  fontSize: 'md',
  fontWeight: 400,
  justifyContent: 'flex-start',
  px: 4,
  _disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
});

const baseStyle = definePartsStyle({
  list: baseStyleList,
  item: baseStyleItem,
});

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
});
