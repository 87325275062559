// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/ticket_type.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ProductType } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.TicketType
 */
export const TicketType = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketType",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_available_for_online_sales", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_pay_what_you_want", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "product_types", kind: "enum", T: proto3.getEnumType(ProductType), repeated: true },
    { no: 10, name: "is_for_membership", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "stat", kind: "message", T: TicketTypeStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.TicketTypeStat
 */
export const TicketTypeStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.TicketTypeStat",
  () => [
    { no: 1, name: "membership_benefit_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateTicketTypeRequest
 */
export const CreateTicketTypeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateTicketTypeRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_available_for_online_sales", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_pay_what_you_want", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "product_types", kind: "enum", T: proto3.getEnumType(ProductType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateTicketTypeResponse
 */
export const CreateTicketTypeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateTicketTypeResponse",
  () => [
    { no: 1, name: "ticket_type", kind: "message", T: TicketType },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTicketTypeRequest
 */
export const GetTicketTypeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketTypeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetTicketTypeResponse
 */
export const GetTicketTypeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetTicketTypeResponse",
  () => [
    { no: 1, name: "ticket_type", kind: "message", T: TicketType },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketTypesRequest
 */
export const ListTicketTypesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketTypesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTicketTypesResponse
 */
export const ListTicketTypesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTicketTypesResponse",
  () => [
    { no: 1, name: "ticket_types", kind: "message", T: TicketType, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveTicketTypeRequest
 */
export const SaveTicketTypeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveTicketTypeRequest",
  () => [
    { no: 1, name: "ticket_type", kind: "message", T: TicketType },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveTicketTypeResponse
 */
export const SaveTicketTypeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveTicketTypeResponse",
  () => [
    { no: 1, name: "ticket_type", kind: "message", T: TicketType },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveTicketTypeRequest
 */
export const ArchiveTicketTypeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveTicketTypeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveTicketTypeResponse
 */
export const ArchiveTicketTypeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveTicketTypeResponse",
  [],
);

