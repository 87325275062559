import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { inputVariantCuebox } from './input';

const textareaSizeOverrides = {
  sm: defineStyle({
    rounded: 'md',
  }),
  md: defineStyle({
    px: 3,
  }),
};

export const textareaTheme = defineStyleConfig({
  variants: {
    cuebox: inputVariantCuebox.field,
  },
  sizes: textareaSizeOverrides,
  defaultProps: {
    variant: 'cuebox',
  },
});
