// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/consumer_profile.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Address, ConstituentType, ConsumerProfileType, ContactMethod, EmailMarketingSubscriptionStatus, Money, PhoneNumber, TimeRange, Title } from "../../../common/v1/common_pb.js";
import { Tag } from "./tag_pb.js";
import { RecentSubscriptionItem } from "./recent_subscription_pb.js";
import { StaffMember } from "./staff_member_pb.js";
import { SeatHold } from "./seat_pb.js";
import { ShowIDAndName } from "./show_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.ConsumerProfile
 */
export const ConsumerProfile = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConsumerProfile",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "individual_profile", kind: "message", T: IndividualProfile, oneof: "profile" },
    { no: 102, name: "household_profile", kind: "message", T: HouseholdProfile, oneof: "profile" },
    { no: 103, name: "company_profile", kind: "message", T: CompanyProfile, oneof: "profile" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.IndividualProfile
 */
export const IndividualProfile = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.IndividualProfile",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "phone_number", kind: "message", T: PhoneNumber },
    { no: 6, name: "email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 7, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "is_primary_household_contact", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "account_since", kind: "message", T: Timestamp },
    { no: 10, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 11, name: "relationships", kind: "message", T: Relationship, repeated: true },
    { no: 12, name: "key_interests", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "date_of_birth", kind: "message", T: Timestamp },
    { no: 14, name: "title", kind: "enum", T: proto3.getEnumType(Title) },
    { no: 15, name: "preferred_contact_methods", kind: "enum", T: proto3.getEnumType(ContactMethod), repeated: true },
    { no: 16, name: "moves_management_stage", kind: "message", T: MovesManagementStage },
    { no: 17, name: "address", kind: "message", T: Address },
    { no: 18, name: "salutation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "background_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "phone_number_2", kind: "message", T: PhoneNumber },
    { no: 21, name: "employer_company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "secondary_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "secondary_email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 24, name: "recent_subscription_items", kind: "message", T: RecentSubscriptionItem, repeated: true },
    { no: 25, name: "has_user_account", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "address_2", kind: "message", T: Address },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.HouseholdProfile
 */
export const HouseholdProfile = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.HouseholdProfile",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "address_2", kind: "message", T: Address },
    { no: 5, name: "current_fiscal_year_donation_goal_amount", kind: "message", T: Money },
    { no: 6, name: "owner", kind: "message", T: StaffMember },
    { no: 7, name: "important_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "primary_contact_individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateIndividualProfileParams
 */
export const CreateIndividualProfileParams = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateIndividualProfileParams",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "message", T: PhoneNumber },
    { no: 5, name: "email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 6, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "account_since", kind: "message", T: Timestamp },
    { no: 9, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 10, name: "relationships", kind: "message", T: Relationship, repeated: true },
    { no: 11, name: "key_interests", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "date_of_birth", kind: "message", T: Timestamp },
    { no: 13, name: "title", kind: "enum", T: proto3.getEnumType(Title) },
    { no: 14, name: "preferred_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "preferred_contact_methods", kind: "enum", T: proto3.getEnumType(ContactMethod), repeated: true },
    { no: 16, name: "moves_management_stage", kind: "message", T: MovesManagementStage },
    { no: 17, name: "salutation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "background_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "phone_number_2", kind: "message", T: PhoneNumber },
    { no: 20, name: "employer_company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "secondary_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "secondary_email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateHouseholdProfileParams
 */
export const CreateHouseholdProfileParams = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateHouseholdProfileParams",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "address_2", kind: "message", T: Address },
    { no: 5, name: "current_fiscal_year_donation_goal_amount", kind: "message", T: Money },
    { no: 6, name: "owner", kind: "message", T: StaffMember },
    { no: 7, name: "important_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateIndividualProfileRequest
 */
export const CreateIndividualProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateIndividualProfileRequest",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: CreateIndividualProfileParams },
    { no: 2, name: "household_profile", kind: "message", T: CreateHouseholdProfileParams },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateIndividualProfileResponse
 */
export const CreateIndividualProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateIndividualProfileResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
    { no: 2, name: "household_profile", kind: "message", T: HouseholdProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetIndividualProfileRequest
 */
export const GetIndividualProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetIndividualProfileRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetIndividualProfileResponse
 */
export const GetIndividualProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetIndividualProfileResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetIndividualProfileByEmailRequest
 */
export const GetIndividualProfileByEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetIndividualProfileByEmailRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetIndividualProfileByEmailResponse
 */
export const GetIndividualProfileByEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetIndividualProfileByEmailResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveIndividualProfileRequest
 */
export const SaveIndividualProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveIndividualProfileRequest",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
    { no: 2, name: "household_profile", kind: "message", T: HouseholdProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveIndividualProfileResponse
 */
export const SaveIndividualProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveIndividualProfileResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
    { no: 2, name: "household_profile", kind: "message", T: HouseholdProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetHouseholdProfileRequest
 */
export const GetHouseholdProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetHouseholdProfileRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetHouseholdProfileResponse
 */
export const GetHouseholdProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetHouseholdProfileResponse",
  () => [
    { no: 1, name: "household_profile", kind: "message", T: HouseholdProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveHouseholdProfileRequest
 */
export const SaveHouseholdProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveHouseholdProfileRequest",
  () => [
    { no: 1, name: "household_profile", kind: "message", T: HouseholdProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveHouseholdProfileResponse
 */
export const SaveHouseholdProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveHouseholdProfileResponse",
  () => [
    { no: 1, name: "household_profile", kind: "message", T: HouseholdProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateHouseholdProfileImportantInformationRequest
 */
export const UpdateHouseholdProfileImportantInformationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateHouseholdProfileImportantInformationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "important_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateHouseholdProfileImportantInformationResponse
 */
export const UpdateHouseholdProfileImportantInformationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateHouseholdProfileImportantInformationResponse",
  () => [
    { no: 1, name: "household_profile", kind: "message", T: HouseholdProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListHouseholdMembersRequest
 */
export const ListHouseholdMembersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListHouseholdMembersRequest",
  () => [
    { no: 1, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListHouseholdMembersResponse
 */
export const ListHouseholdMembersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListHouseholdMembersResponse",
  () => [
    { no: 1, name: "individual_profiles", kind: "message", T: IndividualProfile, repeated: true },
    { no: 2, name: "seat_holds", kind: "message", T: SeatHold, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddIndividualToHouseholdRequest
 */
export const AddIndividualToHouseholdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddIndividualToHouseholdRequest",
  () => [
    { no: 1, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddIndividualToHouseholdResponse
 */
export const AddIndividualToHouseholdResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddIndividualToHouseholdResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveIndividualFromHouseholdRequest
 */
export const RemoveIndividualFromHouseholdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveIndividualFromHouseholdRequest",
  () => [
    { no: 1, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveIndividualFromHouseholdResponse
 */
export const RemoveIndividualFromHouseholdResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveIndividualFromHouseholdResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SearchConsumerProfilesRequest
 */
export const SearchConsumerProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SearchConsumerProfilesRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "consumer_profile_types", kind: "enum", T: proto3.getEnumType(ConsumerProfileType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SearchConsumerProfilesResponse
 */
export const SearchConsumerProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SearchConsumerProfilesResponse",
  () => [
    { no: 1, name: "consumer_profiles", kind: "message", T: ConsumerProfile, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetConsumerProfileRequest
 */
export const GetConsumerProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetConsumerProfileRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetConsumerProfileResponse
 */
export const GetConsumerProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetConsumerProfileResponse",
  () => [
    { no: 1, name: "consumer_profile", kind: "message", T: ConsumerProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetHouseholdProfileStatRequest
 */
export const GetHouseholdProfileStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetHouseholdProfileStatRequest",
  () => [
    { no: 1, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetHouseholdProfileStatResponse
 */
export const GetHouseholdProfileStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetHouseholdProfileStatResponse",
  () => [
    { no: 1, name: "consumer_profile_donation_stat", kind: "message", T: ConsumerProfileDonationStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.IndividualProfileTicketStat
 */
export const IndividualProfileTicketStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.IndividualProfileTicketStat",
  () => [
    { no: 1, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_show_purchase_date", kind: "message", T: Timestamp },
    { no: 3, name: "last_show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tickets_total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "tickets_total_amount", kind: "message", T: Money },
    { no: 6, name: "average_ticket_price", kind: "message", T: Money },
    { no: 7, name: "ticket_orders_total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.HouseholdProfileTicketStat
 */
export const HouseholdProfileTicketStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.HouseholdProfileTicketStat",
  () => [
    { no: 1, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_show_purchase_date", kind: "message", T: Timestamp },
    { no: 3, name: "last_show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tickets_total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "tickets_total_amount", kind: "message", T: Money },
    { no: 6, name: "average_ticket_price", kind: "message", T: Money },
    { no: 7, name: "ticket_orders_total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.IndividualProfileStat
 */
export const IndividualProfileStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.IndividualProfileStat",
  () => [
    { no: 1, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_donation_date", kind: "message", T: Timestamp },
    { no: 3, name: "last_donation_date", kind: "message", T: Timestamp },
    { no: 4, name: "last_donation_amount", kind: "message", T: Money },
    { no: 5, name: "largest_donation_amount", kind: "message", T: Money },
    { no: 6, name: "current_fiscal_year_donation_total_amount", kind: "message", T: Money },
    { no: 7, name: "lifetime_donation_total_amount", kind: "message", T: Money },
    { no: 8, name: "previous_fiscal_year_donation_total_amount", kind: "message", T: Money },
    { no: 9, name: "current_year_minus_two_fiscal_year_donation_total_amount", kind: "message", T: Money },
    { no: 10, name: "total_number_of_donations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SetIndividualAsPrimaryContactRequest
 */
export const SetIndividualAsPrimaryContactRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SetIndividualAsPrimaryContactRequest",
  () => [
    { no: 1, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SetIndividualAsPrimaryContactResponse
 */
export const SetIndividualAsPrimaryContactResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SetIndividualAsPrimaryContactResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Relationship
 */
export const Relationship = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Relationship",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "individual_profile_id_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "individual_profile_id_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "individual_profile_1_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "individual_profile_1_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "individual_profile_2_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "individual_profile_2_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MovesManagementStage
 */
export const MovesManagementStage = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MovesManagementStage",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMovesManagementStagesRequest
 */
export const ListMovesManagementStagesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMovesManagementStagesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMovesManagementStagesResponse
 */
export const ListMovesManagementStagesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMovesManagementStagesResponse",
  () => [
    { no: 1, name: "moves_management_stages", kind: "message", T: MovesManagementStage, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CompanyProfile
 */
export const CompanyProfile = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CompanyProfile",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "account_since", kind: "message", T: Timestamp },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "phone_number", kind: "message", T: PhoneNumber },
    { no: 10, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "address", kind: "message", T: Address },
    { no: 17, name: "primary_contact_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 19, name: "important_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "fiscal_year_donation_goal_amount", kind: "message", T: Money },
    { no: 21, name: "owner", kind: "message", T: StaffMember },
    { no: 22, name: "email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 33, name: "recent_subscription_items", kind: "message", T: RecentSubscriptionItem, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCompanyProfileRequest
 */
export const CreateCompanyProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCompanyProfileRequest",
  () => [
    { no: 1, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_since", kind: "message", T: Timestamp },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "address", kind: "message", T: Address },
    { no: 13, name: "primary_contact_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 15, name: "important_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "fiscal_year_donation_goal_amount", kind: "message", T: Money },
    { no: 17, name: "owner_staff_member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 19, name: "phone_number", kind: "message", T: PhoneNumber },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateCompanyProfileResponse
 */
export const CreateCompanyProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateCompanyProfileResponse",
  () => [
    { no: 1, name: "company_profile", kind: "message", T: CompanyProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ConsumerProfileDonationStat
 */
export const ConsumerProfileDonationStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConsumerProfileDonationStat",
  () => [
    { no: 1, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_donation_date", kind: "message", T: Timestamp },
    { no: 3, name: "last_donation_date", kind: "message", T: Timestamp },
    { no: 4, name: "last_donation_amount", kind: "message", T: Money },
    { no: 5, name: "largest_donation_amount", kind: "message", T: Money },
    { no: 6, name: "current_fiscal_year_donation_total_amount", kind: "message", T: Money },
    { no: 7, name: "lifetime_donation_total_amount", kind: "message", T: Money },
    { no: 8, name: "previous_fiscal_year_donation_total_amount", kind: "message", T: Money },
    { no: 9, name: "total_number_of_donations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "total_pledge_outstanding_amount", kind: "message", T: Money },
    { no: 11, name: "total_pledge_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCompanyProfileRequest
 */
export const GetCompanyProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCompanyProfileRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCompanyProfileResponse
 */
export const GetCompanyProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCompanyProfileResponse",
  () => [
    { no: 1, name: "company_profile", kind: "message", T: CompanyProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCompanyProfileRequest
 */
export const SaveCompanyProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCompanyProfileRequest",
  () => [
    { no: 1, name: "company_profile", kind: "message", T: CompanyProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveCompanyProfileResponse
 */
export const SaveCompanyProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveCompanyProfileResponse",
  () => [
    { no: 1, name: "company_profile", kind: "message", T: CompanyProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddIndividualToCompanyRequest
 */
export const AddIndividualToCompanyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddIndividualToCompanyRequest",
  () => [
    { no: 1, name: "company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddIndividualToCompanyResponse
 */
export const AddIndividualToCompanyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddIndividualToCompanyResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveIndividualFromCompanyRequest
 */
export const RemoveIndividualFromCompanyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveIndividualFromCompanyRequest",
  () => [
    { no: 1, name: "company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveIndividualFromCompanyResponse
 */
export const RemoveIndividualFromCompanyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveIndividualFromCompanyResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SetIndividualAsCompanyPrimaryContactRequest
 */
export const SetIndividualAsCompanyPrimaryContactRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SetIndividualAsCompanyPrimaryContactRequest",
  () => [
    { no: 1, name: "company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SetIndividualAsCompanyPrimaryContactResponse
 */
export const SetIndividualAsCompanyPrimaryContactResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SetIndividualAsCompanyPrimaryContactResponse",
  () => [
    { no: 1, name: "company_profile", kind: "message", T: CompanyProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListIndividualsInCompanyRequest
 */
export const ListIndividualsInCompanyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListIndividualsInCompanyRequest",
  () => [
    { no: 1, name: "company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListIndividualsInCompanyResponse
 */
export const ListIndividualsInCompanyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListIndividualsInCompanyResponse",
  () => [
    { no: 1, name: "individual_profiles", kind: "message", T: IndividualProfile, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCompanyProfileDonationStatRequest
 */
export const GetCompanyProfileDonationStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCompanyProfileDonationStatRequest",
  () => [
    { no: 1, name: "company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCompanyProfileDonationStatResponse
 */
export const GetCompanyProfileDonationStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCompanyProfileDonationStatResponse",
  () => [
    { no: 1, name: "donation_stat", kind: "message", T: ConsumerProfileDonationStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCompanyProfileRequest
 */
export const ArchiveCompanyProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCompanyProfileRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveCompanyProfileResponse
 */
export const ArchiveCompanyProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveCompanyProfileResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ConstituentTableDonationMetrics
 */
export const ConstituentTableDonationMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConstituentTableDonationMetrics",
  () => [
    { no: 1, name: "first_donation_date", kind: "message", T: Timestamp },
    { no: 2, name: "last_donation_date", kind: "message", T: Timestamp },
    { no: 3, name: "last_donation_amount", kind: "message", T: Money },
    { no: 4, name: "lifetime_donation_amount", kind: "message", T: Money },
    { no: 5, name: "largest_donation_amount", kind: "message", T: Money },
    { no: 6, name: "lifetime_donation_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "current_fiscal_year_donation_amount", kind: "message", T: Money },
    { no: 9, name: "outstanding_pledge_amount", kind: "message", T: Money },
    { no: 10, name: "lifetime_pledge_amount", kind: "message", T: Money },
    { no: 11, name: "net_donation_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ConstituentTableTicketingMetrics
 */
export const ConstituentTableTicketingMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConstituentTableTicketingMetrics",
  () => [
    { no: 1, name: "first_show_purchase_date", kind: "message", T: Timestamp },
    { no: 2, name: "last_show_purchase_date", kind: "message", T: Timestamp },
    { no: 3, name: "last_show_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "lifetime_tickets_amount", kind: "message", T: Money },
    { no: 5, name: "average_ticket_price", kind: "message", T: Money },
    { no: 6, name: "lifetime_ticket_orders_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "all_show_names", kind: "message", T: ShowIDAndName, repeated: true },
    { no: 8, name: "all_show_tags", kind: "message", T: Tag, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ConstituentTableSeasonPackageMetrics
 */
export const ConstituentTableSeasonPackageMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConstituentTableSeasonPackageMetrics",
  () => [
    { no: 1, name: "first_season_package_purchase_date", kind: "message", T: Timestamp },
    { no: 2, name: "last_season_package_purchase_date", kind: "message", T: Timestamp },
    { no: 3, name: "lifetime_season_packages_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "all_season_package_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ConstituentTableMembershipMetrics
 */
export const ConstituentTableMembershipMetrics = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConstituentTableMembershipMetrics",
  () => [
    { no: 1, name: "all_active_membership_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * ConstituentTableRow is a row in the constituent table.
 * Each row is either an individual or a company.
 *
 * @generated from message cuebox.services.staff.v1.ConstituentTableRow
 */
export const ConstituentTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConstituentTableRow",
  () => [
    { no: 1, name: "constituent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ConstituentType) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "household_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "household_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "is_primary_contact", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 11, name: "secondary_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "secondary_email_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 13, name: "phone_number", kind: "message", T: PhoneNumber },
    { no: 14, name: "address", kind: "message", T: Address },
    { no: 15, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 16, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "account_since", kind: "message", T: Timestamp },
    { no: 20, name: "owner_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "fiscal_year_donation_goal_amount", kind: "message", T: Money },
    { no: 22, name: "preferred_contact_methods", kind: "enum", T: proto3.getEnumType(ContactMethod), repeated: true },
    { no: 23, name: "employer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "important_information", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "donation_metrics", kind: "message", T: ConstituentTableDonationMetrics },
    { no: 26, name: "ticketing_metrics", kind: "message", T: ConstituentTableTicketingMetrics },
    { no: 27, name: "household_donation_metrics", kind: "message", T: ConstituentTableDonationMetrics },
    { no: 28, name: "household_ticketing_metrics", kind: "message", T: ConstituentTableTicketingMetrics },
    { no: 29, name: "phone_number_2", kind: "message", T: PhoneNumber },
    { no: 30, name: "season_package_metrics", kind: "message", T: ConstituentTableSeasonPackageMetrics },
    { no: 31, name: "household_season_package_metrics", kind: "message", T: ConstituentTableSeasonPackageMetrics },
    { no: 32, name: "membership_metrics", kind: "message", T: ConstituentTableMembershipMetrics },
    { no: 33, name: "address_2", kind: "message", T: Address },
    { no: 34, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "date_of_birth", kind: "message", T: Timestamp },
    { no: 36, name: "informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ConstituentTableFilters
 */
export const ConstituentTableFilters = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConstituentTableFilters",
  () => [
    { no: 1, name: "donation_time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListConstituentTableRowsRequest
 */
export const ListConstituentTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListConstituentTableRowsRequest",
  () => [
    { no: 1, name: "filters", kind: "message", T: ConstituentTableFilters },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListConstituentTableRowsResponse
 */
export const ListConstituentTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListConstituentTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: ConstituentTableRow, repeated: true },
    { no: 2, name: "current_fiscal_year_start_date", kind: "message", T: Timestamp },
    { no: 3, name: "current_fiscal_year_end_date", kind: "message", T: Timestamp },
    { no: 4, name: "previous_fiscal_year_start_date", kind: "message", T: Timestamp },
    { no: 5, name: "previous_fiscal_year_end_date", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MergeIndividualProfilesRequest
 */
export const MergeIndividualProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MergeIndividualProfilesRequest",
  () => [
    { no: 1, name: "from_individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "to_individual_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MergeIndividualProfilesResponse
 */
export const MergeIndividualProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MergeIndividualProfilesResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MergeCompanyProfilesRequest
 */
export const MergeCompanyProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MergeCompanyProfilesRequest",
  () => [
    { no: 1, name: "from_company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "to_company_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MergeCompanyProfilesResponse
 */
export const MergeCompanyProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MergeCompanyProfilesResponse",
  () => [
    { no: 1, name: "company_profile", kind: "message", T: CompanyProfile },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveConstituentRequest
 */
export const ArchiveConstituentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveConstituentRequest",
  () => [
    { no: 2, name: "constituent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveConstituentResponse
 */
export const ArchiveConstituentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveConstituentResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.AddTagsToConsumerProfilesRequest
 */
export const AddTagsToConsumerProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddTagsToConsumerProfilesRequest",
  () => [
    { no: 1, name: "tag_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "consumer_profile_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddTagsToConsumerProfilesResponse
 */
export const AddTagsToConsumerProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddTagsToConsumerProfilesResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveTagsFromConsumerProfilesRequest
 */
export const RemoveTagsFromConsumerProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveTagsFromConsumerProfilesRequest",
  () => [
    { no: 1, name: "tag_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "consumer_profile_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveTagsFromConsumerProfilesResponse
 */
export const RemoveTagsFromConsumerProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveTagsFromConsumerProfilesResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.MatchResults
 */
export const MatchResults = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MatchResults",
  () => [
    { no: 1, name: "name_match", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "email_match", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "phone_match", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "address_match", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DuplicateGroupMember
 */
export const DuplicateGroupMember = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DuplicateGroupMember",
  () => [
    { no: 1, name: "consumer_profile", kind: "message", T: ConsumerProfile },
    { no: 2, name: "match_results", kind: "message", T: MatchResults },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ConsumerProfileGroup
 */
export const ConsumerProfileGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ConsumerProfileGroup",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "profiles", kind: "message", T: DuplicateGroupMember, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDuplicateConsumerProfilesRequest
 */
export const ListDuplicateConsumerProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDuplicateConsumerProfilesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListDuplicateConsumerProfilesResponse
 */
export const ListDuplicateConsumerProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListDuplicateConsumerProfilesResponse",
  () => [
    { no: 1, name: "groups", kind: "message", T: ConsumerProfileGroup, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MergeDuplicateGroupRequest
 */
export const MergeDuplicateGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MergeDuplicateGroupRequest",
  () => [
    { no: 1, name: "consumer_profile_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "primary_consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "excluded_consumer_profile_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MergeDuplicateGroupResponse
 */
export const MergeDuplicateGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MergeDuplicateGroupResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.DoNotMergeDuplicateGroupRequest
 */
export const DoNotMergeDuplicateGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DoNotMergeDuplicateGroupRequest",
  () => [
    { no: 1, name: "consumer_profile_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DoNotMergeDuplicateGroupResponse
 */
export const DoNotMergeDuplicateGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DoNotMergeDuplicateGroupResponse",
  [],
);

