import { useContext, useMemo } from 'react';
import type { Key } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { Message, ServiceType } from '@bufbuild/protobuf';
import { useToast } from '@chakra-ui/react';
import { useErrorTracking } from '@utils/errorTracking';
import { getErrorMessage } from '@utils/errors';
import useGenerateKey from '../hooks/useGenerateKey';
import type {
  BaseContextApi,
  FetcherOrMutatorCallback,
  GetMethod,
  Headers,
  MessageProps,
  RelevantMutationConfigProps,
  ServiceArgs,
} from '../types';

export interface ConnectTriggerArgs<
  Service extends ServiceType,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
> {
  method: GetMethod<Service>;
  mutator: FetcherOrMutatorCallback<Service, Params, FRes>;
}

export type UseServiceProps<Params extends Message<Params>, FRes> = {
  headers?: Headers;
  params?: MessageProps<Params>;
  shouldFetch?: boolean;
  useDefaultErrorHandling?: boolean;
} & RelevantMutationConfigProps<FRes>;

const createConnectTrigger = <
  Service extends ServiceType,
  ContextApi extends BaseContextApi<Service>,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
>(
  args: ConnectTriggerArgs<Service, Params, FRes>,
  serviceArgs: ServiceArgs<Service, ContextApi>,
) => {
  const { context: serviceContext, service } = serviceArgs;
  const { mutator: mutatorFn, method: getMethod } = args;

  const useTrigger = (props?: UseServiceProps<Params, FRes | undefined>) => {
    const {
      headers = {},
      onError,
      onSuccess,
      shouldFetch = true,
      useDefaultErrorHandling = true,
    } = props || {};

    const { client } = useContext(serviceContext);
    const { trackError } = useErrorTracking();
    const toast = useToast();

    const { key, deserializeKey } = useGenerateKey<Service, Params, FRes>({
      client,
      headers,
      method: getMethod(service),
      service,
      shouldFetch,
    });

    const mutator = useMemo(
      () =>
        async (stringKey: string, { arg }: { arg: MessageProps<Params> }) => {
          try {
            return await mutatorFn({
              ...deserializeKey(stringKey),
              params: arg,
            });
          } catch (err) {
            trackError(err, JSON.parse(stringKey), 'connectTrigger error');

            if (useDefaultErrorHandling) {
              toast({
                title: getErrorMessage(
                  err,
                  'Your request could not be completed. Please try again.',
                ),
                status: 'error',
              });
            } else {
              throw err;
            }
          }
        },
      [deserializeKey, useDefaultErrorHandling, trackError, toast],
    );

    return useSWRMutation<FRes | undefined, Error, Key, MessageProps<Params>>(
      key,
      mutator,
      {
        onError,
        onSuccess,
        populateCache: true,
        revalidate: true,
      },
    );
  };

  return { useTrigger };
};

export default createConnectTrigger;
