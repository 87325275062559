import { type PropsWithChildren, createContext } from 'react';
import type { StaffServiceContextApi } from '../hooks/useStaffService';
import useStaffService from '../hooks/useStaffService';

export const StaffServiceContext = createContext<StaffServiceContextApi>(
  {} as StaffServiceContextApi,
);

const StaffServiceProvider = ({ children }: PropsWithChildren) => {
  const contextApi = useStaffService();

  return (
    <StaffServiceContext.Provider value={contextApi}>
      {children}
    </StaffServiceContext.Provider>
  );
};

export default StaffServiceProvider;
