import { useMemo } from 'react';
import { useAuth } from '@clerk/nextjs';
import { CUEBOX_API_URL } from '@config/env';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import useUrlQuery from '@hooks/useUrlQuery';
import { CueboxConsumerService } from '@pb/cuebox/services/consumer/v1/consumer_connect';
import auth from '../interceptors/auth';
import { setRequiredHeaders } from '../interceptors/consumer';
import { type BaseContextApi } from '../types';

export type ConsumerServiceContextApi = BaseContextApi<
  typeof CueboxConsumerService
>;

const useConsumerService = (): ConsumerServiceContextApi => {
  const { getToken } = useAuth();

  const { orgId } = useUrlQuery<{ orgId: string }>();

  const transport = useMemo(
    () =>
      createConnectTransport({
        baseUrl: CUEBOX_API_URL,
        jsonOptions: {
          ignoreUnknownFields: true,
        },
        interceptors: [setRequiredHeaders({ orgId }), auth({ getToken })],
      }),
    [getToken, orgId],
  );

  const client = useMemo(
    () => createPromiseClient(CueboxConsumerService, transport),
    [transport],
  );

  return {
    transport,
    client,
  };
};

export default useConsumerService;
