const consumer = {
  50: '#ebeffd',
  100: '#c7d2f9',
  500: '#365dea',
  600: '#335bed',
};

const staff = {
  50: '#e5f1fd',
  100: '#c6dff6',
  400: '#44a1f8',
  500: '#3693ea',
  600: '#2780d4',
};

const light = {
  100: '#f4f5f6', // Container backgrounds and active button states
  200: '#edeff1',
  300: '#f9fafb',
  400: '#fafafa', // Consumer sidebar background
  500: '#d2d9f7', // Consumer accent/border color
};

const dark = {
  100: '#25292e', // Default text color
  200: '#6e757c', // Lighter text color (e.g. description text)
  300: '#9ca3ab',
  400: '#e5e7ea', // Borders
};

const green = {
  50: '#eaf6ec',
  500: '#2ea140',
};

const mud = {
  50: '#fef8f0',
  100: '#f4dfc1',
  200: '#EDD3AD',
  300: '#E5C18B',
  400: '#DEAF6A',
  500: '#D69D49',
  600: '#C98A2D',
  700: '#9B6B23',
  800: '#6D4B18',
  900: '#3F2C0E',
  950: '#291C09',
};

export const colors = {
  consumer,
  staff,
  dark,
  green,
  light,
  mud,
};
