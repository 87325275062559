// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/asset.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Asset } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.UploadAssetRequest
 */
export const UploadAssetRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UploadAssetRequest",
  () => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "asset_object", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UploadAssetResponse
 */
export const UploadAssetResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UploadAssetResponse",
  () => [
    { no: 1, name: "asset", kind: "message", T: Asset },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetAssetRequest
 */
export const GetAssetRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetAssetRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetAssetResponse
 */
export const GetAssetResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetAssetResponse",
  () => [
    { no: 1, name: "asset", kind: "message", T: Asset },
  ],
);

