// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/donation_form.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, Money } from "../../../common/v1/common_pb.js";

/**
 * @generated from enum cuebox.services.consumer.v1.DonationFormStatus
 */
export const DonationFormStatus = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.consumer.v1.DonationFormStatus",
  [
    {no: 0, name: "DONATION_FORM_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DONATION_FORM_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "DONATION_FORM_STATUS_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.DonationForm
 */
export const DonationForm = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.DonationForm",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "background_image", kind: "message", T: Asset },
    { no: 4, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fund_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "suggested_amounts", kind: "message", T: Money, repeated: true },
    { no: 7, name: "is_phone_number_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_tribute_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_donate_anonymously_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "is_progress_bar_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "status", kind: "enum", T: proto3.getEnumType(DonationFormStatus) },
    { no: 12, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 13, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetDonationFormRequest
 */
export const GetDonationFormRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetDonationFormRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetDonationFormResponse
 */
export const GetDonationFormResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetDonationFormResponse",
  () => [
    { no: 1, name: "donation_form", kind: "message", T: DonationForm },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.Fund
 */
export const Fund = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Fund",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListFundsRequest
 */
export const ListFundsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListFundsRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListFundsResponse
 */
export const ListFundsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListFundsResponse",
  () => [
    { no: 1, name: "funds", kind: "message", T: Fund, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CampaignStat
 */
export const CampaignStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CampaignStat",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_raised_amount", kind: "message", T: Money },
    { no: 3, name: "goal_amount", kind: "message", T: Money },
    { no: 4, name: "donation_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetCampaignStatRequest
 */
export const GetCampaignStatRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetCampaignStatRequest",
  () => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetCampaignStatResponse
 */
export const GetCampaignStatResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetCampaignStatResponse",
  () => [
    { no: 1, name: "stat", kind: "message", T: CampaignStat },
  ],
);

