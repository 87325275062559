// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/order_table.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Address, FulfillmentStatus, Money, OrderItemType, OrderPaymentStatus, PaymentMethod, PaymentMethodType, PhoneNumber, SalesChannel, TicketDeliveryMethod, TicketPrintStatus, TimeRange } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.OrderTableRow
 */
export const OrderTableRow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.OrderTableRow",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_at", kind: "message", T: Timestamp },
    { no: 3, name: "customer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "customer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "customer_phone", kind: "message", T: PhoneNumber },
    { no: 7, name: "shipping_address", kind: "message", T: Address },
    { no: 8, name: "billing_address", kind: "message", T: Address },
    { no: 9, name: "total_order_amount", kind: "message", T: Money },
    { no: 10, name: "net_order_amount", kind: "message", T: Money },
    { no: 11, name: "total_donation_amount", kind: "message", T: Money },
    { no: 12, name: "net_donation_amount", kind: "message", T: Money },
    { no: 13, name: "subtotal_amount", kind: "message", T: Money },
    { no: 14, name: "fee_amount", kind: "message", T: Money },
    { no: 15, name: "discount_amount", kind: "message", T: Money },
    { no: 16, name: "refund_total_amount", kind: "message", T: Money },
    { no: 17, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderPaymentStatus) },
    { no: 18, name: "payment_method_type", kind: "enum", T: proto3.getEnumType(PaymentMethodType) },
    { no: 19, name: "order_item_types", kind: "enum", T: proto3.getEnumType(OrderItemType), repeated: true },
    { no: 20, name: "items_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "tickets_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 22, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
    { no: 23, name: "ticket_print_status", kind: "enum", T: proto3.getEnumType(TicketPrintStatus) },
    { no: 24, name: "item_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 25, name: "discount_codes_applied", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 26, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 27, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "fulfillment_status", kind: "enum", T: proto3.getEnumType(FulfillmentStatus) },
    { no: 29, name: "constituent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "household_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "formal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "informal_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "formal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "informal_addressee", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "payment_method", kind: "message", T: PaymentMethod },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.OrderTableFilters
 */
export const OrderTableFilters = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.OrderTableFilters",
  () => [
    { no: 1, name: "time_range", kind: "message", T: TimeRange },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListOrderTableRowsRequest
 */
export const ListOrderTableRowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListOrderTableRowsRequest",
  () => [
    { no: 1, name: "filters", kind: "message", T: OrderTableFilters },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListOrderTableRowsResponse
 */
export const ListOrderTableRowsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListOrderTableRowsResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: OrderTableRow, repeated: true },
  ],
);

