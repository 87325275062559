import { IS_DD_ENABLED } from '@config/env';
import { datadogLogs } from '@datadog/browser-logs';
import { APP_LOGGER_NAME } from './constants';

export const logError = (
  message: string,
  messageContext?: object,
  error?: Error,
) => {
  if (IS_DD_ENABLED) {
    const logger = datadogLogs.getLogger(APP_LOGGER_NAME);
    logger?.error(message, messageContext, error);
  } else {
    console.error(message, messageContext, error);
  }
};

export const logWarn = (
  message: string,
  messageContext?: object,
  error?: Error,
) => {
  if (IS_DD_ENABLED) {
    const logger = datadogLogs.getLogger(APP_LOGGER_NAME);
    logger?.warn(message, messageContext, error);
  } else {
    console.warn(message, messageContext, error);
  }
};

export const logInfo = (message: string, messageContext?: object) => {
  if (IS_DD_ENABLED) {
    const logger = datadogLogs.getLogger(APP_LOGGER_NAME);
    logger?.info(message, messageContext);
  } else {
    console.info(message, messageContext);
  }
};
