// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/common/v1/error.proto (package cuebox.common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.common.v1.BadRequest
 */
export const BadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.BadRequest",
  () => [
    { no: 1, name: "violations", kind: "message", T: BadRequest_Violation, repeated: true },
  ],
);

/**
 * A message type used to describe a single bad request field
 *
 * @generated from message cuebox.common.v1.BadRequest.Violation
 */
export const BadRequest_Violation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.common.v1.BadRequest.Violation",
  () => [
    { no: 1, name: "field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "BadRequest_Violation"},
);

