import { merge } from 'lodash-es';
import {
  type SystemStyleObject,
  useMultiStyleConfig,
  useStyleConfig,
} from '@chakra-ui/react';
import type { Theme as ClerkTheme } from '@clerk/types';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@constants/strings';
import type { ThemeObject } from '@cuebox-types/chakra';
import { BLUE_BUTTON_BACKGROUND, DANGER, SUCCESS } from './brand';

export const useClerkTheme = (
  overrides: SystemStyleObject = {},
): SystemStyleObject => {
  const inputStyles: Record<string, ThemeObject> = useMultiStyleConfig('Input');
  const labelStyles = useStyleConfig('FormLabel');
  const outlineButtonStyles = useStyleConfig('Button', {
    size: 'md',
    variant: 'outline',
  });
  const solidButtonStyles = useStyleConfig('Button', {
    size: 'sm',
    variant: 'cuebox',
  });

  const clerkStyles: SystemStyleObject = {
    '.cl-card': {
      borderBottomRadius: 0,
    },
    '.cl-button': {
      boxShadow: 'none',
      _focus: {
        boxShadow: 'none',
      },
    },
    '.cl-formFieldInput': {
      ...inputStyles.field,
      boxShadow: 'none',
      _hover: {
        ...inputStyles.field._hover,
        boxShadow: 'none',
      },
    },
    '.cl-otpCodeFieldInput': {
      ...inputStyles.field,
      boxShadow: 'none',
      px: 0,
      width: '2.5rem',
      _hover: {
        ...inputStyles.field._hover,
        boxShadow: 'none',
      },
      _focusVisible: {
        ...inputStyles.field._focusVisible,
        _hover: {
          ...inputStyles.field._focusVisible,
        },
      },
      _focusWithin: inputStyles.field._focusVisible,
    },
    '.cl-formFieldLabel': {
      ...labelStyles,
      margin: 0,
      color: 'text.base',
    },
    '.cl-socialButtonsBlockButton': {
      ...outlineButtonStyles,
      py: 0,
      shadow: 'none',
    },
    '.cl-formButtonPrimary': {
      ...solidButtonStyles,
      fontWeight: 'medium',
      shadow: 'none',
      _after: { display: 'none' },
    },
  };

  return merge({}, clerkStyles, overrides);
};

export const appearance: ClerkTheme = {
  elements: {
    footerAction__signIn: {
      display: 'none',
    },
    footerAction__signUp: {
      display: 'none',
    },
  },
  layout: {
    logoImageUrl: '/cuebox_logo.png',
    privacyPageUrl: PRIVACY_POLICY_URL,
    termsPageUrl: TERMS_OF_SERVICE_URL,
  },
  variables: {
    colorPrimary: BLUE_BUTTON_BACKGROUND,
    colorSuccess: SUCCESS,
    colorDanger: DANGER,
  },
};
