import { useCallback, useMemo } from 'react';
import type { Message, MethodInfo, ServiceType } from '@bufbuild/protobuf';
import { useUser } from '@clerk/nextjs';
import type { PromiseClient } from '@connectrpc/connect';
import type {
  DeserializedKey,
  Headers,
  KeyPayload,
  MessageProps,
} from '../types';

interface UseGenerateKeyProps<
  Service extends ServiceType,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
> {
  client: PromiseClient<Service>;
  headers: Headers;
  method: MethodInfo;
  params?: MessageProps<Params>;
  service: Service;
  shouldFetch: boolean;
}

const useGenerateKey = <
  Service extends ServiceType,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
>(
  props: UseGenerateKeyProps<Service, Params, FRes>,
) => {
  const { user, isLoaded: isUserStateLoaded } = useUser();

  const { client, shouldFetch, service, headers, params, method } = props;

  const key = useMemo(() => {
    // Returning null here will prevent SWR from calling the fetcher, allowing us to conditionally pause requests.
    if (!shouldFetch || !isUserStateLoaded) {
      return null;
    }

    const payload: KeyPayload<Params> = {
      userId: user?.id || '',
      headers,
      method,
      service: service.typeName,
      ...(params ? { params } : {}),
    };

    return JSON.stringify(payload);
  }, [shouldFetch, service, headers, params, method, isUserStateLoaded, user]);

  const deserializeKey = useCallback(
    (stringKey: string): DeserializedKey<Service, Params, FRes> => {
      return {
        ...JSON.parse(stringKey),
        client,
        service,
      };
    },
    [client, service],
  );

  return { key, deserializeKey };
};

export default useGenerateKey;
