// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/wallet.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { File } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.GenerateAppleWalletPassesRequest
 */
export const GenerateAppleWalletPassesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GenerateAppleWalletPassesRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GenerateAppleWalletPassesResponse
 */
export const GenerateAppleWalletPassesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GenerateAppleWalletPassesResponse",
  () => [
    { no: 1, name: "passes_file", kind: "message", T: File },
    { no: 2, name: "passes_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GenerateGoogleWalletPassesRequest
 */
export const GenerateGoogleWalletPassesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GenerateGoogleWalletPassesRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GenerateGoogleWalletPassesResponse
 */
export const GenerateGoogleWalletPassesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GenerateGoogleWalletPassesResponse",
  () => [
    { no: 1, name: "passes_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "passes_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

