// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/organization.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AmountModifier, Asset, Money, PaymentProcessor, SubscriptionFrequency, TicketDesignTemplateGenerationMode, WebAnalyticsPlatform } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.WebAnalyticsTag
 */
export const WebAnalyticsTag = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.WebAnalyticsTag",
  () => [
    { no: 1, name: "platform", kind: "enum", T: proto3.getEnumType(WebAnalyticsPlatform) },
    { no: 2, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Organization
 */
export const Organization = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Organization",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "slug", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "logo", kind: "message", T: Asset },
    { no: 5, name: "terms_of_service_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "privacy_policy_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "website_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "fiscal_year_start_date", kind: "message", T: Timestamp },
    { no: 11, name: "upsell_donation_form_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "fiscal_year_donation_goal_amount", kind: "message", T: Money },
    { no: 13, name: "ticket_design_template_filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "ticket_design_template_generation_modes", kind: "enum", T: proto3.getEnumType(TicketDesignTemplateGenerationMode), repeated: true },
    { no: 15, name: "outbound_email_sender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "outbound_email_should_use_cuebox_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "donation_alert_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "subscription_payment_failure_alert_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "days_before_subscription_renewal_to_send_reminder_email", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "enabled_subscription_frequencies_for_renewal_reminder_email", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency), repeated: true },
    { no: 21, name: "mailchimp_list_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "is_mailchimp_sync_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "is_mailchimp_custom_event_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "web_analytics_tags", kind: "message", T: WebAnalyticsTag, repeated: true },
    { no: 25, name: "cf_turnstile_site_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "ticket_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 27, name: "is_prevent_orphan_seats_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "should_display_all_in_pricing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 29, name: "seat_tooltip_copy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "consumer_ticket_fee", kind: "message", T: AmountModifier },
    { no: 31, name: "box_office_ticket_fee", kind: "message", T: AmountModifier },
    { no: 32, name: "fee_tooltip_copy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "is_abandoned_cart_email_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 34, name: "is_season_packages_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "is_memberships_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 36, name: "is_merchandise_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 37, name: "merchandise_page_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "shows_page_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 39, name: "payment_processor", kind: "enum", T: proto3.getEnumType(PaymentProcessor) },
    { no: 40, name: "tilled_merchant_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 41, name: "finix_merchant_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 42, name: "is_card_present_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 43, name: "household_formal_name_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 44, name: "household_informal_name_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 45, name: "household_recognition_name_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 46, name: "household_formal_addressee_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 47, name: "household_informal_addressee_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 48, name: "company_formal_name_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 49, name: "company_informal_name_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "company_recognition_name_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 51, name: "company_formal_addressee_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 52, name: "company_informal_addressee_rule_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 53, name: "should_update_names_when_household_membership_changes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 54, name: "is_household_recognition_name_overwrite_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListOrganizationsRequest
 */
export const ListOrganizationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListOrganizationsRequest",
  () => [
    { no: 1, name: "cuebox_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListOrganizationsResponse
 */
export const ListOrganizationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListOrganizationsResponse",
  () => [
    { no: 1, name: "organizations", kind: "message", T: Organization, repeated: true },
  ],
);

