import type { Interceptor } from '@connectrpc/connect';
import { ApiHeaderKeys } from '../types';

export const setRequiredHeaders =
  ({ orgId }: { orgId: string }): Interceptor =>
  (next) =>
  (req) => {
    req.header.set(ApiHeaderKeys.ORG_ID, orgId);
    return next(req);
  };
