// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/price_list.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { TicketType } from "./ticket_type_pb.js";
import { PriceLevel } from "./price_level_pb.js";
import { AmountModifier, Money, SalesChannel, SeatingModel } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.PriceCell
 */
export const PriceCell = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceCell",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "price_list_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ticket_type", kind: "message", T: TicketType },
    { no: 4, name: "price_level", kind: "message", T: PriceLevel },
    { no: 5, name: "amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.FeeCell
 */
export const FeeCell = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.FeeCell",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "price_list_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ticket_type", kind: "message", T: TicketType },
    { no: 4, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 5, name: "fee", kind: "message", T: AmountModifier },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PriceMatrix
 */
export const PriceMatrix = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceMatrix",
  () => [
    { no: 1, name: "cells", kind: "message", T: PriceCell, repeated: true },
    { no: 2, name: "ticket_types", kind: "message", T: TicketType, repeated: true },
    { no: 3, name: "price_levels", kind: "message", T: PriceLevel, repeated: true },
    { no: 4, name: "fee_cells", kind: "message", T: FeeCell, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PriceList
 */
export const PriceList = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceList",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 7, name: "is_template", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "price_matrix", kind: "message", T: PriceMatrix },
    { no: 9, name: "stat", kind: "message", T: PriceListStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.PriceListStat
 */
export const PriceListStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceListStat",
  () => [
    { no: 1, name: "price_list_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "min_ticket_price", kind: "message", T: Money },
    { no: 3, name: "max_ticket_price", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPriceListsRequest
 */
export const ListPriceListsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPriceListsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPriceListsResponse
 */
export const ListPriceListsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPriceListsResponse",
  () => [
    { no: 1, name: "price_lists", kind: "message", T: PriceList, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreatePriceListRequest
 */
export const CreatePriceListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreatePriceListRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 3, name: "is_template", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "price_matrix", kind: "message", T: PriceMatrix },
    { no: 5, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "venue_layout_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreatePriceListResponse
 */
export const CreatePriceListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreatePriceListResponse",
  () => [
    { no: 1, name: "price_list", kind: "message", T: PriceList },
    { no: 2, name: "did_not_create_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetPriceListRequest
 */
export const GetPriceListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetPriceListRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetPriceListResponse
 */
export const GetPriceListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetPriceListResponse",
  () => [
    { no: 1, name: "price_list", kind: "message", T: PriceList },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavePriceListRequest
 */
export const SavePriceListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavePriceListRequest",
  () => [
    { no: 1, name: "price_list", kind: "message", T: PriceList },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SavePriceListResponse
 */
export const SavePriceListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SavePriceListResponse",
  () => [
    { no: 1, name: "price_list", kind: "message", T: PriceList },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchivePriceListRequest
 */
export const ArchivePriceListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchivePriceListRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchivePriceListResponse
 */
export const ArchivePriceListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchivePriceListResponse",
  [],
);

