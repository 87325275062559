import { GOOGLE_PLACES_API_KEY } from '@config/env';
import { createScriptLoader } from '@utils/scriptLoader';

const {
  withScriptLoader: withGoogleMapsScript,
  useScriptLoader: useGoogleMapsScript,
} = createScriptLoader({
  id: 'google-places',
  src: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places&callback=initMap&loading=async`,
});

export { withGoogleMapsScript, useGoogleMapsScript };
