import { defineStyleConfig } from '@chakra-ui/react';
import { inputTheme } from './input';

export const selectTheme = defineStyleConfig({
  variants: {
    ...inputTheme.variants,
  },
  sizes: {
    sm: {
      field: {
        rounded: 'md',
      },
    },
  },
  defaultProps: {
    variant: 'cuebox',
  },
});
