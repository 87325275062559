import {
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import useLocalStorageState, {
  type LocalStorageOptions,
} from 'use-local-storage-state';
import { SECONDS_IN_HOUR, expiresTimestamp } from '@utils/dates';

type StorageItem<Data = unknown> = {
  data: Data;
  expires: number;
};

const hasExpired = (expires: number) => Date.now() > expires;

type StorageOptionsProps<Data = unknown> = Pick<
  LocalStorageOptions<Data>,
  'storageSync'
>;

interface UseLocalStorageProps<Data = unknown>
  extends StorageOptionsProps<Data> {
  initialData: Data;
  storageKey: string;
  /**
   * Defaults to one hour expiration
   * @defaultValue 3600
   */
  ttlSeconds?: number;
  /**
   * Whether or not to refresh the expiration on load
   */
  shouldRefresh?: boolean;
}

const useLocalStorage = <Data = unknown>(props: UseLocalStorageProps<Data>) => {
  const {
    initialData,
    storageKey,
    ttlSeconds = SECONDS_IN_HOUR,
    shouldRefresh = false,
    ...storageOptionsProps
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [storage, setStorage, { removeItem }] = useLocalStorageState<
    StorageItem<Data>
  >(storageKey, {
    ...storageOptionsProps,
    defaultValue: () => ({
      data: initialData,
      expires: expiresTimestamp(ttlSeconds),
    }),
  });

  const data = useMemo(() => {
    if (!isLoaded || isEqual(storage.data, initialData)) {
      return initialData;
    }

    if (hasExpired(storage.expires)) {
      return initialData;
    }

    return storage.data;
  }, [storage, initialData, isLoaded]);

  const hasCheckedExpiration = useRef(false);

  // Note: this has to run in an effect because Next.js will also execute this during SSR and not have localStorage
  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }

    if (isLoaded && !hasCheckedExpiration.current) {
      if (hasExpired(storage.expires)) {
        // Remove the item if it has expired
        removeItem();
      } else if (shouldRefresh) {
        // If the `shouldRefresh` option is passed, refresh the expiration whenever the data is accessed.
        // This must occur after we have already checked for expiration, otherwise we're just refreshing the expiry every time.
        setStorage((prevState) => ({
          ...prevState,
          expires: expiresTimestamp(ttlSeconds),
        }));
      }

      hasCheckedExpiration.current = true;
    }
  }, [
    isLoaded,
    removeItem,
    setStorage,
    shouldRefresh,
    storage.expires,
    ttlSeconds,
  ]);

  const setItem = useCallback(
    (stateOrSetter: SetStateAction<Data>) => {
      setStorage((prevState) => {
        const newData =
          stateOrSetter instanceof Function
            ? stateOrSetter(prevState.data)
            : stateOrSetter;

        const updates: StorageItem<Data> = {
          ...prevState,
          data: newData,
          expires: expiresTimestamp(ttlSeconds),
        };

        return updates;
      });
    },
    [setStorage, ttlSeconds],
  );

  return {
    data,
    isLoaded,
    setItem,
  };
};

export default useLocalStorage;
