// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/membership.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Int32Value, proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, BenefitPeriodType, ListMembershipFilter, MembershipStatus, Money, RevenueType, SalesChannel, SubscriptionFrequency } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Membership
 */
export const Membership = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Membership",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "image_asset", kind: "message", T: Asset },
    { no: 8, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 9, name: "benefit_period_type", kind: "enum", T: proto3.getEnumType(BenefitPeriodType) },
    { no: 10, name: "benefit_period_duration_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "benefit_period_expires_at", kind: "message", T: Timestamp },
    { no: 12, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 13, name: "total_amount", kind: "message", T: Money },
    { no: 14, name: "tax_deductible_amount", kind: "message", T: Money },
    { no: 15, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "sales_channels", kind: "enum", T: proto3.getEnumType(SalesChannel), repeated: true },
    { no: 19, name: "sales_starts_at", kind: "message", T: Timestamp },
    { no: 20, name: "sales_ends_at", kind: "message", T: Timestamp },
    { no: 21, name: "is_order_confirmation_email_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "order_confirmation_email_content_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "order_confirmation_email_content_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "is_membership_renewal_email_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "membership_renewal_email_content_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "membership_renewal_email_content_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "sort_order", kind: "message", T: Int32Value },
    { no: 28, name: "max_successful_payments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 29, name: "stat", kind: "message", T: MembershipStat },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipStat
 */
export const MembershipStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipStat",
  () => [
    { no: 1, name: "membership_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(MembershipStatus) },
    { no: 3, name: "is_visible_online", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "active_members_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "net_amount", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMembershipsRequest
 */
export const ListMembershipsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMembershipsRequest",
  () => [
    { no: 1, name: "filter", kind: "enum", T: proto3.getEnumType(ListMembershipFilter) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMembershipsResponse
 */
export const ListMembershipsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMembershipsResponse",
  () => [
    { no: 1, name: "memberships", kind: "message", T: Membership, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateMembershipRequest
 */
export const CreateMembershipRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateMembershipRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateMembershipResponse
 */
export const CreateMembershipResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateMembershipResponse",
  () => [
    { no: 1, name: "membership", kind: "message", T: Membership },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMembershipRequest
 */
export const GetMembershipRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMembershipRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetMembershipResponse
 */
export const GetMembershipResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetMembershipResponse",
  () => [
    { no: 1, name: "membership", kind: "message", T: Membership },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveMembershipRequest
 */
export const SaveMembershipRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveMembershipRequest",
  () => [
    { no: 1, name: "membership", kind: "message", T: Membership },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveMembershipResponse
 */
export const SaveMembershipResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveMembershipResponse",
  () => [
    { no: 1, name: "membership", kind: "message", T: Membership },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveMembershipRequest
 */
export const ArchiveMembershipRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveMembershipRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveMembershipResponse
 */
export const ArchiveMembershipResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveMembershipResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.MembershipIDAndName
 */
export const MembershipIDAndName = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MembershipIDAndName",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMembershipIDsAndNamesRequest
 */
export const ListMembershipIDsAndNamesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMembershipIDsAndNamesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMembershipIDsAndNamesResponse
 */
export const ListMembershipIDsAndNamesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMembershipIDsAndNamesResponse",
  () => [
    { no: 1, name: "membership_ids_and_names", kind: "message", T: MembershipIDAndName, repeated: true },
  ],
);

