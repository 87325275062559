// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/activity_timeline.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { StaffMember } from "./staff_member_pb.js";
import { Order } from "./order_pb.js";
import { Pledge } from "./pledge_pb.js";
import { SoftCredit } from "./soft_credit_pb.js";

/**
 * @generated from enum cuebox.services.staff.v1.ActivityTimelineItemType
 */
export const ActivityTimelineItemType = /*@__PURE__*/ proto3.makeEnum(
  "cuebox.services.staff.v1.ActivityTimelineItemType",
  [
    {no: 0, name: "ACTIVITY_TIMELINE_ITEM_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ACTIVITY_TIMELINE_ITEM_TYPE_CUSTOM_NOTE", localName: "CUSTOM_NOTE"},
    {no: 2, name: "ACTIVITY_TIMELINE_ITEM_TYPE_DONATION", localName: "DONATION"},
    {no: 3, name: "ACTIVITY_TIMELINE_ITEM_TYPE_TICKET", localName: "TICKET"},
    {no: 4, name: "ACTIVITY_TIMELINE_ITEM_TYPE_PLEDGE", localName: "PLEDGE"},
    {no: 5, name: "ACTIVITY_TIMELINE_ITEM_TYPE_SOFT_CREDIT", localName: "SOFT_CREDIT"},
    {no: 6, name: "ACTIVITY_TIMELINE_ITEM_TYPE_SEASON_PACKAGE", localName: "SEASON_PACKAGE"},
    {no: 7, name: "ACTIVITY_TIMELINE_ITEM_TYPE_MEMBERSHIP", localName: "MEMBERSHIP"},
    {no: 8, name: "ACTIVITY_TIMELINE_ITEM_TYPE_MAILCHIMP_OPEN", localName: "MAILCHIMP_OPEN"},
    {no: 9, name: "ACTIVITY_TIMELINE_ITEM_TYPE_MERCHANDISE", localName: "MERCHANDISE"},
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActivityTimelineItem
 */
export const ActivityTimelineItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActivityTimelineItem",
  () => [
    { no: 1, name: "activity_at", kind: "message", T: Timestamp },
    { no: 2, name: "activity_types", kind: "enum", T: proto3.getEnumType(ActivityTimelineItemType), repeated: true },
    { no: 101, name: "custom_note", kind: "message", T: ActivityTimelineCustomNote, oneof: "item" },
    { no: 102, name: "transaction", kind: "message", T: ActivityTimelineTransaction, oneof: "item" },
    { no: 103, name: "soft_credit", kind: "message", T: AcitivityTimelineSoftCredit, oneof: "item" },
    { no: 104, name: "pledge", kind: "message", T: ActivityTimelinePledge, oneof: "item" },
    { no: 105, name: "mailchimp_open", kind: "message", T: ActivityTimelineMailchimpOpen, oneof: "item" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActivityTimelineCustomNote
 */
export const ActivityTimelineCustomNote = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActivityTimelineCustomNote",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "posted_at", kind: "message", T: Timestamp },
    { no: 7, name: "author", kind: "message", T: StaffMember },
    { no: 8, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActivityTimelineTransaction
 */
export const ActivityTimelineTransaction = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActivityTimelineTransaction",
  () => [
    { no: 1, name: "order", kind: "message", T: Order },
    { no: 2, name: "transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActivityTimelinePledge
 */
export const ActivityTimelinePledge = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActivityTimelinePledge",
  () => [
    { no: 1, name: "pledge", kind: "message", T: Pledge },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AcitivityTimelineSoftCredit
 */
export const AcitivityTimelineSoftCredit = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AcitivityTimelineSoftCredit",
  () => [
    { no: 1, name: "soft_credit", kind: "message", T: SoftCredit },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ActivityTimelineMailchimpOpen
 */
export const ActivityTimelineMailchimpOpen = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ActivityTimelineMailchimpOpen",
  () => [
    { no: 1, name: "constituent_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "campaign_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateActivityTimelineCustomNoteRequest
 */
export const CreateActivityTimelineCustomNoteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateActivityTimelineCustomNoteRequest",
  () => [
    { no: 1, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateActivityTimelineCustomNoteResponse
 */
export const CreateActivityTimelineCustomNoteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateActivityTimelineCustomNoteResponse",
  () => [
    { no: 1, name: "custom_note", kind: "message", T: ActivityTimelineCustomNote },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListActivityTimelineItemsRequest
 */
export const ListActivityTimelineItemsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListActivityTimelineItemsRequest",
  () => [
    { no: 1, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "activity_types", kind: "enum", T: proto3.getEnumType(ActivityTimelineItemType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListActivityTimelineItemsResponse
 */
export const ListActivityTimelineItemsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListActivityTimelineItemsResponse",
  () => [
    { no: 1, name: "items", kind: "message", T: ActivityTimelineItem, repeated: true },
  ],
);

