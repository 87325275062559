import { useMemo } from 'react';
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import qs from 'querystring';

const OPENING_DELIMITER = '[';
const CLOSING_DELIMITER = ']';

const containsRouteParam = (part: string) =>
  part.includes(OPENING_DELIMITER) && part.includes(CLOSING_DELIMITER);

const getParamKey = (part: string) =>
  part.replace(OPENING_DELIMITER, '').replace(CLOSING_DELIMITER, '');

// Next.js very helpfully provides an empty/undefined route query on first render due to... reasons.
// But we need access to various ID's and such right away in order to bootstrap data.
// This function simply replicates what router.query produces so we don't fall into this trap.
export const parseRouteParamsFromUrl = (
  route: string,
  pathname: string,
): ParsedUrlQuery | undefined => {
  const routeParts = route.split('/');
  const pathParts = pathname.split('/');

  if (routeParts.length !== pathParts.length) {
    return undefined;
  }

  return routeParts.reduce((res, part, index) => {
    if (containsRouteParam(part)) {
      const key = getParamKey(part);
      const value = pathParts[index];

      return {
        ...res,
        [key]: value,
      };
    }

    return res;
  }, {});
};

const useUrlQuery = <QueryType extends ParsedUrlQuery>() => {
  const router = useRouter();

  const params = useMemo(() => {
    if (typeof window === 'undefined') {
      return router.query;
    }

    const queryPart = router.asPath.split('?')[1];
    const queryParams = queryPart ? qs.parse(queryPart) : undefined;

    const parsedQuery = parseRouteParamsFromUrl(
      router.route,
      window.location.pathname,
    );

    if (parsedQuery || queryParams) {
      return {
        ...router.query,
        ...parsedQuery,
        ...queryParams,
      };
    }

    return router.query;
  }, [router]);

  return params as QueryType;
};

export default useUrlQuery;
