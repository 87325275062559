// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/showtime.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { TicketType } from "./ticket_type_pb.js";
import { VenueLayout } from "./venue_pb.js";
import { ListShowsFilter, ShowLocationType, ShowtimeLabel, ShowtimeStatus, TimeRange } from "../../../common/v1/common_pb.js";
import { PriceList } from "./price_list_pb.js";
import { TicketSalesStat } from "./ticket_sales_stat_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.CapacityCell
 */
export const CapacityCell = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CapacityCell",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ticket_type", kind: "message", T: TicketType },
    { no: 4, name: "capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.InPersonLocation
 */
export const InPersonLocation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.InPersonLocation",
  () => [
    { no: 1, name: "venue_layout", kind: "message", T: VenueLayout },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.OnlineLocation
 */
export const OnlineLocation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.OnlineLocation",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ShowtimeLocation
 */
export const ShowtimeLocation = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShowtimeLocation",
  () => [
    { no: 1, name: "location_type", kind: "enum", T: proto3.getEnumType(ShowLocationType) },
    { no: 101, name: "in_person_location", kind: "message", T: InPersonLocation, oneof: "location" },
    { no: 102, name: "online_location", kind: "message", T: OnlineLocation, oneof: "location" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Showtime
 */
export const Showtime = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Showtime",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "starts_at", kind: "message", T: Timestamp },
    { no: 7, name: "ends_at", kind: "message", T: Timestamp },
    { no: 8, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "seatsio_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "price_list", kind: "message", T: PriceList },
    { no: 11, name: "max_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "capacity_cells", kind: "message", T: CapacityCell, repeated: true },
    { no: 14, name: "stat", kind: "message", T: ShowtimeStat },
    { no: 15, name: "location", kind: "message", T: ShowtimeLocation },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ShowtimeStat
 */
export const ShowtimeStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShowtimeStat",
  () => [
    { no: 1, name: "showtime_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_ticket_sales_stat", kind: "message", T: TicketSalesStat },
    { no: 3, name: "ticket_sales_stat_by_ticket_type", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: TicketSalesStat} },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(ShowtimeStatus) },
    { no: 5, name: "is_visible_for_ticket_sales", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "labels", kind: "enum", T: proto3.getEnumType(ShowtimeLabel), repeated: true },
    { no: 7, name: "show_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "sales_starts_at", kind: "message", T: Timestamp },
    { no: 9, name: "sales_ends_at", kind: "message", T: Timestamp },
    { no: 10, name: "box_office_status", kind: "enum", T: proto3.getEnumType(ShowtimeStatus) },
    { no: 11, name: "is_visible_for_box_office_ticket_sales", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "box_office_sales_starts_at", kind: "message", T: Timestamp },
    { no: 13, name: "box_office_sales_ends_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowtimesRequest
 */
export const ListShowtimesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowtimesRequest",
  () => [
    { no: 1, name: "show_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "price_list_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filter", kind: "enum", T: proto3.getEnumType(ListShowsFilter) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowtimesResponse
 */
export const ListShowtimesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowtimesResponse",
  () => [
    { no: 1, name: "showtimes", kind: "message", T: Showtime, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateShowtimeRequest
 */
export const CreateShowtimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateShowtimeRequest",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "starts_at", kind: "message", T: Timestamp },
    { no: 3, name: "ends_at", kind: "message", T: Timestamp },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "price_list_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "max_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "capacity_cells", kind: "message", T: CapacityCell, repeated: true },
    { no: 9, name: "location", kind: "message", T: ShowtimeLocation },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateShowtimeResponse
 */
export const CreateShowtimeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateShowtimeResponse",
  () => [
    { no: 1, name: "showtime", kind: "message", T: Showtime },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateShowtimesRequest
 */
export const CreateShowtimesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateShowtimesRequest",
  () => [
    { no: 1, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "time_ranges", kind: "message", T: TimeRange, repeated: true },
    { no: 3, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "price_list_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "max_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "capacity_cells", kind: "message", T: CapacityCell, repeated: true },
    { no: 7, name: "location", kind: "message", T: ShowtimeLocation },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateShowtimesResponse
 */
export const CreateShowtimesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateShowtimesResponse",
  () => [
    { no: 1, name: "showtimes", kind: "message", T: Showtime, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetShowtimeRequest
 */
export const GetShowtimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetShowtimeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetShowtimeResponse
 */
export const GetShowtimeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetShowtimeResponse",
  () => [
    { no: 1, name: "showtime", kind: "message", T: Showtime },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveShowtimeRequest
 */
export const SaveShowtimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveShowtimeRequest",
  () => [
    { no: 1, name: "showtime", kind: "message", T: Showtime },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveShowtimeResponse
 */
export const SaveShowtimeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveShowtimeResponse",
  () => [
    { no: 1, name: "showtime", kind: "message", T: Showtime },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveShowtimeRequest
 */
export const ArchiveShowtimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveShowtimeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ArchiveShowtimeResponse
 */
export const ArchiveShowtimeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ArchiveShowtimeResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelShowtimeRequest
 */
export const CancelShowtimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelShowtimeRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CancelShowtimeResponse
 */
export const CancelShowtimeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CancelShowtimeResponse",
  () => [
    { no: 1, name: "did_cancel", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "did_not_cancel_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

