import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle((props) => {
  const { colorScheme: c, colorMode } = props;

  const isLightMode = colorMode === 'light';

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 12,
    width: 12,
    padding: 3.5,
    borderRadius: 8,
    borderWidth: 1,
    shadow: 'sm',
    color: isLightMode ? `${c}.500` : `${c}.300`,
    borderColor: isLightMode ? `${c}.200` : `${c}.800`,
    bg: isLightMode ? `${c}.50` : `${c}.900`,
  };
});

export const iconWrapperTheme = defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'gray',
  },
});
