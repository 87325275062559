import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DVC_CLIENT_SDK_KEY } from '@config/env';
import {
  type DVCVariableTypes,
  useVariable,
  useVariableValue,
} from '@cuebox-types/devcycle';
import {
  DevCycleProvider,
  useDevCycleClient,
  useIsDevCycleInitialized,
} from '@devcycle/react-client-sdk';

export { useVariable, useVariableValue };

const ANON_USER_ID = 'anon';

const useFeatureFlagsContext = (props: { isSSR?: boolean }) => {
  const { isSSR } = props;
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  const isDVCInitialized = useIsDevCycleInitialized();
  const dvcClient = useDevCycleClient<DVCVariableTypes>();
  const dcvUserId = dvcClient.user?.user_id;
  const isReady = isDVCInitialized && isUserInitialized;

  const initFlagsContext = useCallback(
    async (ctx: {
      userId?: string;
      email?: string;
      name?: string;
      orgId: string;
    }) => {
      const { userId, email, name, orgId } = ctx;

      await dvcClient.identifyUser({
        // If a user ID is not supplied, the session is considered "anonymous" and is scoped to a temporary user.
        // Any string can be used here, but passing a standardized one might help future targeting.
        user_id: userId || ANON_USER_ID,
        name,
        email,
        customData: {
          // orgId is a custom property set up in the Devcycle dashboard, the property name _must_ match the object key here.
          // This enables us to target features to specific orgs if needed, for instance as a selective beta launch.
          orgId,
        },
      });
      await dvcClient.onClientInitialized();

      setIsUserInitialized(true);
    },
    [dvcClient],
  );

  const getVariableValue = useCallback(
    <K extends string & keyof DVCVariableTypes, T extends DVCVariableTypes[K]>(
      variable: K,
      defaultValue: T,
    ) => {
      if (isSSR) {
        return defaultValue;
      }

      const varConfig = dvcClient.variable(variable, defaultValue);

      if (varConfig) {
        return varConfig.value;
      }

      return defaultValue;
    },
    [dvcClient, isSSR],
  );

  const flagsContextValue = useMemo(
    () => ({
      client: dvcClient,
      getVariableValue,
      initFlagsContext,
      isReady,
      userId: dcvUserId,
    }),
    [dcvUserId, dvcClient, getVariableValue, initFlagsContext, isReady],
  );

  return flagsContextValue;
};

export type FeatureFlagsContextApi = ReturnType<typeof useFeatureFlagsContext>;

export const FeatureFlagsContext = createContext<FeatureFlagsContextApi>(
  {} as FeatureFlagsContextApi,
);

const FlagsContextInit = (props: PropsWithChildren & { isSSR: boolean }) => {
  const { children, isSSR } = props;
  const flagsContextValue = useFeatureFlagsContext({ isSSR });

  return (
    <FeatureFlagsContext.Provider value={flagsContextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const FeatureFlagProvider = (
  props: PropsWithChildren & { isSSR: boolean },
) => {
  const { children, isSSR } = props;

  return (
    <DevCycleProvider config={{ sdkKey: DVC_CLIENT_SDK_KEY }}>
      <FlagsContextInit isSSR={isSSR}>{children}</FlagsContextInit>
    </DevCycleProvider>
  );
};

const useFeatureFlags = () => useContext(FeatureFlagsContext);

export default useFeatureFlags;
