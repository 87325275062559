// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/profile.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Address, EmailMarketingSubscriptionStatus, PhoneNumber } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.IndividualProfile
 */
export const IndividualProfile = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.IndividualProfile",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "account_since", kind: "message", T: Timestamp },
    { no: 6, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "email_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "email_1_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 10, name: "email_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "email_2_marketing_subscription_status", kind: "enum", T: proto3.getEnumType(EmailMarketingSubscriptionStatus) },
    { no: 12, name: "phone_1", kind: "message", T: PhoneNumber },
    { no: 13, name: "phone_2", kind: "message", T: PhoneNumber },
    { no: 14, name: "household_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "is_primary_household_contact", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "household_address", kind: "message", T: Address },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetIndividualProfileRequest
 */
export const GetIndividualProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetIndividualProfileRequest",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetIndividualProfileResponse
 */
export const GetIndividualProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetIndividualProfileResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveIndividualProfileRequest
 */
export const SaveIndividualProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveIndividualProfileRequest",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SaveIndividualProfileResponse
 */
export const SaveIndividualProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SaveIndividualProfileResponse",
  () => [
    { no: 1, name: "individual_profile", kind: "message", T: IndividualProfile },
  ],
);

