// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/tag.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { TagType } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.Tag
 */
export const Tag = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Tag",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(TagType) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateTagRequest
 */
export const CreateTagRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateTagRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(TagType) },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateTagResponse
 */
export const CreateTagResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateTagResponse",
  () => [
    { no: 1, name: "tag", kind: "message", T: Tag },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTagsRequest
 */
export const ListTagsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTagsRequest",
  () => [
    { no: 1, name: "types", kind: "enum", T: proto3.getEnumType(TagType), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListTagsResponse
 */
export const ListTagsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListTagsResponse",
  () => [
    { no: 1, name: "tags", kind: "message", T: Tag, repeated: true },
  ],
);

