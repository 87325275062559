// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/draft_order.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AmountModifier, Asset, DraftOrderIntent, DraftOrderItemAction, DraftOrderStatus, Money, PaymentMethodType, RevenueType, SeatingModel, SubscriptionFrequency, TicketDeliveryMethod, Tribute } from "../../../common/v1/common_pb.js";
import { Discount } from "./discount_pb.js";
import { DonationForm } from "./donation_form_pb.js";
import { TicketType } from "./ticket_type_pb.js";
import { PriceLevel } from "./price_level_pb.js";
import { Seat } from "./seat_pb.js";
import { SeasonPackage } from "./season_package_pb.js";
import { Membership } from "./membership_pb.js";
import { Merchandise } from "./merchandise_pb.js";
import { Showtime } from "./showtime_pb.js";
import { VenueLayout } from "./venue_pb.js";
import { PriceListView } from "./price_list_view_pb.js";
import { ConsumerProfile } from "./consumer_profile_pb.js";
import { BillingInfo, PaymentInfo, ShippingInfo } from "./order_pb.js";
import { CheckoutForm, CheckoutFormSubmission } from "./form_question_pb.js";

/**
 * @generated from message cuebox.services.staff.v1.PriceInfo
 */
export const PriceInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.PriceInfo",
  () => [
    { no: 1, name: "subtotal", kind: "message", T: Money },
    { no: 2, name: "fee", kind: "message", T: Money },
    { no: 3, name: "discount", kind: "message", T: Money },
    { no: 4, name: "total", kind: "message", T: Money },
    { no: 5, name: "refund_subtotal", kind: "message", T: Money },
    { no: 6, name: "refund_fee", kind: "message", T: Money },
    { no: 7, name: "refund_discount", kind: "message", T: Money },
    { no: 8, name: "refund_total", kind: "message", T: Money },
    { no: 9, name: "net_subtotal", kind: "message", T: Money },
    { no: 10, name: "net_fee", kind: "message", T: Money },
    { no: 11, name: "net_discount", kind: "message", T: Money },
    { no: 12, name: "net", kind: "message", T: Money },
    { no: 13, name: "discounts", kind: "message", T: Discount, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftTicketShow
 */
export const DraftTicketShow = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftTicketShow",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "image", kind: "message", T: Asset },
    { no: 7, name: "ticket_delivery_methods", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod), repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftTicketShowtime
 */
export const DraftTicketShowtime = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftTicketShowtime",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "show_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "starts_at", kind: "message", T: Timestamp },
    { no: 4, name: "ends_at", kind: "message", T: Timestamp },
    { no: 5, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftOrderOptions
 */
export const DraftOrderOptions = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftOrderOptions",
  () => [
    { no: 2, name: "donation_form", kind: "message", T: DonationForm },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftOrderItem
 */
export const DraftOrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftOrderItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "action", kind: "enum", T: proto3.getEnumType(DraftOrderItemAction) },
    { no: 100, name: "donation", kind: "message", T: DraftDonationItem, oneof: "item" },
    { no: 101, name: "ticket", kind: "message", T: DraftTicketItem, oneof: "item" },
    { no: 102, name: "season_package", kind: "message", T: DraftSeasonPackageItem, oneof: "item" },
    { no: 103, name: "membership", kind: "message", T: DraftMembershipItem, oneof: "item" },
    { no: 104, name: "merchandise", kind: "message", T: DraftMerchandiseItem, oneof: "item" },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftDonationItem
 */
export const DraftDonationItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftDonationItem",
  () => [
    { no: 1, name: "donation_form_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fund_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "campaign_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 5, name: "tribute", kind: "message", T: Tribute },
    { no: 6, name: "is_anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "price", kind: "message", T: PriceInfo },
    { no: 8, name: "source_donation_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "appeal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftTicketItem
 */
export const DraftTicketItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftTicketItem",
  () => [
    { no: 1, name: "show", kind: "message", T: DraftTicketShow },
    { no: 2, name: "showtime", kind: "message", T: DraftTicketShowtime },
    { no: 3, name: "ticket_type", kind: "message", T: TicketType },
    { no: 4, name: "price_level", kind: "message", T: PriceLevel },
    { no: 5, name: "price", kind: "message", T: PriceInfo },
    { no: 6, name: "seat", kind: "message", T: Seat },
    { no: 7, name: "source_ticket_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "ticket_delivery_method", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod) },
    { no: 9, name: "season_package_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftSeasonPackageItem
 */
export const DraftSeasonPackageItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftSeasonPackageItem",
  () => [
    { no: 1, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "price", kind: "message", T: PriceInfo },
    { no: 3, name: "source_season_package_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SeasonPackageGroup
 */
export const SeasonPackageGroup = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageGroup",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package", kind: "message", T: SeasonPackage },
    { no: 3, name: "selected_showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "ticket_selection_steps", kind: "message", T: SeasonPackageTicketSelectionStep, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftMembershipItem
 */
export const DraftMembershipItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftMembershipItem",
  () => [
    { no: 1, name: "membership", kind: "message", T: Membership },
    { no: 2, name: "source_membership_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 4, name: "price", kind: "message", T: PriceInfo },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftMerchandiseItem
 */
export const DraftMerchandiseItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftMerchandiseItem",
  () => [
    { no: 1, name: "merchandise_sku_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source_merchandise_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "merchandise", kind: "message", T: Merchandise },
    { no: 4, name: "price", kind: "message", T: PriceInfo },
  ],
);

/**
 * Read-only information used to inform the ticket selection UI.
 *
 * @generated from message cuebox.services.staff.v1.SeasonPackageTicketSelectionStep
 */
export const SeasonPackageTicketSelectionStep = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SeasonPackageTicketSelectionStep",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showtimes", kind: "message", T: Showtime, repeated: true },
    { no: 3, name: "seating_model", kind: "enum", T: proto3.getEnumType(SeatingModel) },
    { no: 4, name: "venue_layout", kind: "message", T: VenueLayout },
    { no: 5, name: "aggregate_price_list", kind: "message", T: PriceListView },
    { no: 6, name: "is_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_updated_relative_to_source_order", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.DraftOrder
 */
export const DraftOrder = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.DraftOrder",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "consumer_profile", kind: "message", T: ConsumerProfile },
    { no: 6, name: "billing_info", kind: "message", T: BillingInfo },
    { no: 7, name: "shipping_info", kind: "message", T: ShippingInfo },
    { no: 8, name: "status", kind: "enum", T: proto3.getEnumType(DraftOrderStatus) },
    { no: 9, name: "items", kind: "message", T: DraftOrderItem, repeated: true },
    { no: 10, name: "season_package_groups", kind: "message", T: SeasonPackageGroup, repeated: true },
    { no: 11, name: "price_summary", kind: "message", T: PriceInfo },
    { no: 12, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "payment_method_type", kind: "enum", T: proto3.getEnumType(PaymentMethodType) },
    { no: 15, name: "candidate_items", kind: "message", T: DraftOrderItem, repeated: true },
    { no: 16, name: "intent", kind: "enum", T: proto3.getEnumType(DraftOrderIntent) },
    { no: 17, name: "ticket_fee", kind: "message", T: AmountModifier },
    { no: 18, name: "warning_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "form", kind: "message", T: CheckoutForm },
    { no: 20, name: "form_submission", kind: "message", T: CheckoutFormSubmission },
    { no: 21, name: "is_form_submission_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDraftOrderRequest
 */
export const CreateDraftOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDraftOrderRequest",
  () => [
    { no: 1, name: "intent", kind: "enum", T: proto3.getEnumType(DraftOrderIntent) },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateDraftOrderResponse
 */
export const CreateDraftOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateDraftOrderResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDraftOrderRequest
 */
export const GetDraftOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDraftOrderRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDraftOrderResponse
 */
export const GetDraftOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDraftOrderResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * GetCurrentDraftOrderRequest gets the logged in user from the context,
 * and returns the active draft order if one exists.
 *
 * @generated from message cuebox.services.staff.v1.GetCurrentDraftOrderRequest
 */
export const GetCurrentDraftOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCurrentDraftOrderRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.GetCurrentDraftOrderResponse
 */
export const GetCurrentDraftOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetCurrentDraftOrderResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveItemsRequest
 */
export const SaveItemsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveItemsRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "items", kind: "message", T: DraftOrderItem, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveItemsResponse
 */
export const SaveItemsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveItemsResponse",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.AddSeasonPackageGroupRequest
 */
export const AddSeasonPackageGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddSeasonPackageGroupRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddSeasonPackageGroupResponse
 */
export const AddSeasonPackageGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddSeasonPackageGroupResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveDiscountRequest
 */
export const RemoveDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveDiscountRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "discount_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveDiscountResponse
 */
export const RemoveDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveDiscountResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDraftOrderOptionsRequest
 */
export const GetDraftOrderOptionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDraftOrderOptionsRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetDraftOrderOptionsResponse
 */
export const GetDraftOrderOptionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetDraftOrderOptionsResponse",
  () => [
    { no: 1, name: "options", kind: "message", T: DraftOrderOptions },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddConsumerRequest
 */
export const AddConsumerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddConsumerRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "consumer_profile_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddConsumerResponse
 */
export const AddConsumerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddConsumerResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveConsumerRequest
 */
export const RemoveConsumerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveConsumerRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.RemoveConsumerResponse
 */
export const RemoveConsumerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.RemoveConsumerResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveNotesRequest
 */
export const SaveNotesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveNotesRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveNotesResponse
 */
export const SaveNotesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveNotesResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveShippingInfoRequest
 */
export const SaveShippingInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveShippingInfoRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "shipping_info", kind: "message", T: ShippingInfo },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveShippingInfoResponse
 */
export const SaveShippingInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveShippingInfoResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveBillingInfoRequest
 */
export const SaveBillingInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveBillingInfoRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "billing_info", kind: "message", T: BillingInfo },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveBillingInfoResponse
 */
export const SaveBillingInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveBillingInfoResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDraftOrderTicketFeeRequest
 */
export const SaveDraftOrderTicketFeeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDraftOrderTicketFeeRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_fee", kind: "message", T: AmountModifier },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDraftOrderTicketFeeResponse
 */
export const SaveDraftOrderTicketFeeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDraftOrderTicketFeeResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckoutDraftOrderRequest
 */
export const CheckoutDraftOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckoutDraftOrderRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payment_method", kind: "message", T: PaymentInfo },
    { no: 3, name: "net", kind: "message", T: Money },
    { no: 4, name: "should_skip_confirmation_email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CheckoutDraftOrderResponse
 */
export const CheckoutDraftOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CheckoutDraftOrderResponse",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSeasonPackageGroupShowtimesRequest
 */
export const SaveSeasonPackageGroupShowtimesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSeasonPackageGroupShowtimesRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "season_package_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "showtime_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveSeasonPackageGroupShowtimesResponse
 */
export const SaveSeasonPackageGroupShowtimesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveSeasonPackageGroupShowtimesResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddDiscountRequest
 */
export const AddDiscountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddDiscountRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "discount_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.AddDiscountResponse
 */
export const AddDiscountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.AddDiscountResponse",
  () => [
    { no: 1, name: "draft_order", kind: "message", T: DraftOrder },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDraftOrderFormSubmissionRequest
 */
export const SaveDraftOrderFormSubmissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDraftOrderFormSubmissionRequest",
  () => [
    { no: 1, name: "draft_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "form_submission", kind: "message", T: CheckoutFormSubmission },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.SaveDraftOrderFormSubmissionResponse
 */
export const SaveDraftOrderFormSubmissionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.SaveDraftOrderFormSubmissionResponse",
  [],
);

